/* eslint-env browser */
import merge from 'lodash/merge'
import get from 'lodash/get'
import find from 'lodash/find'
import map from 'lodash/map'
import remove from 'lodash/remove'
import forEach from 'lodash/forEach'
import { ActionType } from 'redux-promise-middleware'
import {
  ADD_TO_CART,
  CART_TYPE,
  DECREMENT_CART_LINE_ITEM_QUANTITY,
  INCREMENT_CART_LINE_ITEM_QUANTITY,
  REHYDRATED,
  REMOVE_FROM_CART,
  clearCart,
  ADD_BUNDLE_TO_CART
} from '../actions'
import { getCartId, getCartLineItems, getComplimentaryProductsByHandle, getProductDefaultsByHandle, isCartCompleted } from '../selectors'
import {
  fetchCart,
  sendCreateCart,
  sendUpdateCartLineItems
} from '../api'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import uniqBy from 'lodash/uniqBy'

const cartStorageKey = 'qeengarnetCart1'

function getLocalStorageObject (key) {
  const raw = localStorage.getItem(key)
  return raw ? JSON.parse(raw) : undefined
}

function getLocalStorageCart (getState) {
  return getLocalStorageObject(cartStorageKey)
}

function updateLocalStorageCart (getState) {
  const id = getCartId(getState())
  if (!id) {
    return
  }
  const cart = merge(getLocalStorageCart(getState) || {}, { id })
  localStorage.setItem(cartStorageKey, JSON.stringify(cart))
}

function removeLocalStorageCart (getState) {
  localStorage.removeItem(cartStorageKey)
}

function alterCartLineItemsQuantity (dispatch, getState, items) {
  const allComplimentaryItems = getComplimentaryProductsByHandle(getState())
  var cartItems = [...getCartLineItems(getState())]

  const lineItems = map(cartItems, ({ variant, quantity }) => ({
    variantId: variant.id,
    quantity
  }))

  forEach(items, ({ variantId, numberToAdd }) => {
    const lineItem = find(lineItems, { variantId })
    if (lineItem) {
      lineItem.quantity += numberToAdd
      if (lineItem.quantity <= 0) {
        remove(lineItems, { variantId })
      }
    } else if (numberToAdd > 0) {
      lineItems.push({ variantId, quantity: numberToAdd })
    }
  })

  // Get the cart items but remove any that are going to be removed
  remove(cartItems, x => !find(lineItems, item => get(x, ['variant', 'id']) === item.variantId))
  var productDefaults = getProductDefaultsByHandle(getState())

  // Get the available complimentary items
  var availableCompItems = compact(flatten(cartItems.map(({ product: { slug } }) => get(productDefaults, [slug, 'complimentary_items']))))
  availableCompItems = uniqBy(availableCompItems, x => x.handle)

  // Remove any complimentary items that are no longer valid
  forEach([...lineItems], ({ variantId }) => {
    var isComplimentaryItem = !!find(allComplimentaryItems, x => get(x, ['product', 'variants', 0, 'id']) === variantId)
    if (isComplimentaryItem) {
      var isAvailable = !!find(availableCompItems, x => get(x, ['product', 'variants', 0, 'id']) === variantId)
      if (!isAvailable) {
        remove(lineItems, { variantId })
      }
    }
  })

  sendUpdateCartLineItems(dispatch, getState, lineItems)
}

export default store => next => action => {
  if (action.type === ADD_TO_CART) {
    const id = getCartId(store.getState())
    const { variantId, quantity } = action.payload
    if (id) {
      alterCartLineItemsQuantity(store.dispatch, store.getState, [{ variantId, numberToAdd: quantity || 1 }])
    } else {
      sendCreateCart(store.dispatch, store.getState, [{ variantId, quantity: quantity || 1 }])
    }
  }
  if (action.type === ADD_BUNDLE_TO_CART) {
    const id = getCartId(store.getState())
    if (id) {
      alterCartLineItemsQuantity(store.dispatch, store.getState, action.payload)
    } else {
      sendCreateCart(store.dispatch, store.getState,
        map(action.payload, item => ({ variantId: item.variantId, quantity: item.numberToAdd }))
      )
    }
  }
  if (action.type === INCREMENT_CART_LINE_ITEM_QUANTITY) {
    alterCartLineItemsQuantity(store.dispatch, store.getState, [{ variantId: action.payload, numberToAdd: 1 }])
  }
  if (action.type === DECREMENT_CART_LINE_ITEM_QUANTITY) {
    alterCartLineItemsQuantity(store.dispatch, store.getState, [{ variantId: action.payload, numberToAdd: -1 }])
  }
  if (action.type === REMOVE_FROM_CART) {
    const lineItems = getCartLineItems(store.getState())
    const quantity = get(find(lineItems, ({ variant }) => get(variant, ['id']) === action.payload), ['quantity'])
    if (quantity) {
      alterCartLineItemsQuantity(store.dispatch, store.getState, [{ variantId: action.payload, numberToAdd: -quantity }])
    }
  }

  const ret = next(action)

  if (action.type === REHYDRATED) {
    const cart = getLocalStorageCart(store.getState)
    const id = get(cart, ['id'])
    if (id) {
      fetchCart(store.dispatch, store.getState, id)
    }
  }

  if (action.type === `${CART_TYPE}_${ActionType.Fulfilled}`) {
    console.log('Fulfilled cart action')
    const cartId = getCartId(store.getState())
    if (cartId) {
      const completed = isCartCompleted(store.getState())
      if (!completed) {
        updateLocalStorageCart(store.getState)
      } else {
        removeLocalStorageCart(store.getState)
        store.dispatch(clearCart())
      }
    } else {
      // null returned from GET means the cart does not exist
      removeLocalStorageCart(store.getState)
    }
  }
  return ret
}
