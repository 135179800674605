export const SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST'
export const SUBSCRIBE_TYPE_FULFILLED = 'SUBSCRIBE_TYPE_FULFILLED'
export const SUBSCRIBE_TYPE = 'SUBSCRIBE_TYPE'

export const subscribeRequest = payload => ({
  type: SUBSCRIBE_REQUEST,
  payload
})

export const subscribeActionCreator = promise => ({
  type: SUBSCRIBE_TYPE,
  payload: promise
})
