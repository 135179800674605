import React, { useCallback, useState, useEffect, useRef } from 'react'
import { usePageVisibility } from 'react-page-visibility'
import { createUseStyles } from 'react-jss'
import theme from 'style/theme'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import maxBy from 'lodash/maxBy'
import RichText from 'components/RichText'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'
import cn from 'classnames'
import { quart, expo } from 'style/eases'
import { ReactComponent as Close } from 'images/close.svg'
import { getNotifications, isCookieAccepted, getNotificationDuration } from '../redux/selectors'
import gsap from 'gsap'
import width from 'dom-helpers/width'
import height from 'dom-helpers/height'
import useResizeObserver from 'hooks/useResizeObserver'

const NotificationBanner = () => {
  const classes = useStyles()
  const [open, setOpen] = useState()
  const [notificationIndex, setNotificationsIndex] = useState(0)
  const cookiesAccepted = useSelector(isCookieAccepted)
  const notifications = useSelector(getNotifications)
  const hasNotifications = !isEmpty(notifications)
  const duration = useSelector(getNotificationDuration)
  const contentRef = useRef()
  const containerRef = useRef()
  const isVisible = usePageVisibility()

  useEffect(() => {
    if (cookiesAccepted === undefined) return
    setOpen(cookiesAccepted && hasNotifications)
  }, [cookiesAccepted, hasNotifications])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const onResize = useCallback(() => {
    if (!isEmpty(notifications)) {
      const notificationElements = contentRef.current.children
      gsap.set(notificationElements, { position: 'static' })
      gsap.set(contentRef.current, { width: 'auto', height: 'auto' })
      const maxWidth = width(maxBy(notificationElements, width)) + 10
      const maxHeight = height(maxBy(notificationElements, height))
      gsap.set(contentRef.current, { width: maxWidth, height: maxHeight })
      gsap.set(notificationElements, { position: 'absolute', left: 0, top: '50%', y: '-50%' })
    }
  }, [notifications])

  useEffect(() => {
    onResize()
  }, [onResize])

  useResizeObserver(containerRef, onResize, [onResize])

  useEffect(() => {
    if (!isEmpty(notifications) && contentRef.current) {
      const notificationElements = contentRef.current.children
      gsap.to(notificationElements, { opacity: 0, duration: 1.2 })
      gsap.to(notificationElements[notificationIndex], { opacity: 1, duration: 1.6, delay: 0.8 })
    }
  }, [notifications, notificationIndex])

  useEffect(() => {
    if (isVisible && notifications && notifications.length > 1) {
      const intervalId = setInterval(() => {
        setNotificationsIndex(index => (index + 1) % notifications.length)
      }, duration * 1000)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [open, notifications, isVisible])

  return (
    <div className={cn(classes.wrapper, open && 'is-active')} ref={containerRef}>
      <div className={classes.content} ref={contentRef}>
        {!isEmpty(notifications) && (
          map(notifications, (notification, i) => (
            <RichText key={i} className={classes.text} content={notification.text} tag='span' />
          ))
        )}
      </div>
      <button className={classes.button} onClick={onClose}><Close /></button>
    </div>
  )
}

const useStyles = createUseStyles({
  wrapper: {
    position: 'fixed',
    textTransform: 'uppercase',
    fontFamily: theme.fonts.din,
    zIndex: theme.zIndex.nav,
    top: '100%',
    right: span(1),
    width: span(22),
    background: theme.colors.secondary,
    color: theme.colors.white,
    fontSize: vw(13),
    textAlign: 'center',
    borderRadius: vw(50),
    padding: [vw(10), vw(20)],
    visibility: 'hidden',
    transition: `visibility 1s, transform 1s ${quart.inOut}`,
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      right: span(0.5),
      width: 'auto',
      alignItems: 'center',
      fontSize: vw(13, 'desktop'),
      borderRadius: vw(25, 'desktop'),
      padding: [vw(20, 'desktop'), vw(30, 'desktop'), vw(18, 'desktop')]
    },
    '&.is-active': {
      visibility: 'inherit',
      'transition-timing-function': expo.out,
      'transition-delay': '0.45s',
      transform: 'translate3d(0,-116%,0)',
      [theme.breakpoints.up('md')]: {
        transform: 'translate3d(0,-160%,0)'
      }
    }
  },
  text: {
    opacity: 0,
    '& p': {
      display: 'inline'
    },
    '& em': {
      fontFamily: theme.fonts.austin,
      textTransform: 'none',
      fontStyle: 'normal',
      fontSize: '1.1em',
      letterSpacing: 1
    },
    '& a': {
      color: 'currentColor'
    }
  },
  link: {
    color: theme.colors.white,
    marginLeft: '0.5em',
    '&:hover, &:visited': {
      color: theme.colors.white
    }

  },
  button: {
    flexShrink: 0,
    userSelect: 'none',
    cursor: 'pointer',
    appearance: 'none',
    outline: 'none',
    border: 'none',
    background: 'none',
    color: theme.colors.white,
    textTransform: 'uppercase',
    fontFamily: theme.fonts.din,
    lineHeight: 1,
    textDecoration: 'underline',
    padding: ['0.5em'],
    fontSize: vw(14),
    [theme.breakpoints.up('md')]: {
      marginLeft: vw(50, 'desktop'),
      fontSize: vw(10, 'desktop')
    }
  },
  content: {
    flexGrow: 1,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  }
}, { name: 'NotificationBanner' })

export default NotificationBanner
