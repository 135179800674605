import React, { useEffect, useRef } from 'react'
import Link from './Link'

const isExternalLink = url => typeof url === 'object' ? false : (
  /^[a-z]+:/.test(url) || // e.g. http:// https:// mailto: tel:
  url.substr(0, 2) === '//'
)

const isNotURIScheme = url => {
  return /^(?!(?:javascript|data|chrome|mailto|tel|sms|callto|mms|skype):).+$/.test(url)
}

const TrackedLink = ({ className, link, event }) => {
  const shouldOpenInTab = link && link.url && isExternalLink(link.url) && isNotURIScheme(link.url)
  const pixelRef = useRef()

  useEffect(() => {
    pixelRef.current = require('react-facebook-pixel')
  }, [])

  const trackEvent = () => {
    if (pixelRef.current) {
      pixelRef.current.default.trackCustom(event, {})
    }
  }

  return (
    <Link
      className={className}
      link={link}
      onClick={trackEvent}
      shouldOpenInTab={shouldOpenInTab}
    />
  )
}

export default TrackedLink
