import get from 'lodash/get'
import find from 'lodash/find'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/orderBy'
import flatten from 'lodash/flatten'
import uniqBy from 'lodash/uniqBy'
import compact from 'lodash/compact'
import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import { getCurrentRoutePath, isPreviewRoute, getCurrentQueryString } from './location'
import { isCurrentBreakpointMobile } from './layout'

export const isContentBusy = state => !!(state.content.page.busy || state.content.global.busy)
export const getPageContent = state => get(state, ['content', 'page', 'content'])
export const getProductContent = state => get(state, ['content', 'page', 'content'])
export const isContentError = state => get(getPageContent(state), ['type']) === 'errorPage'
export const isContentError404 = state => get(getPageContent(state), ['id']) === 'error-page-404'

export const getGlobalContent = state => state.content.global.content
export const getFooter = state => get(getGlobalContent(state), ['footer'])
export const getFooterBanner = state => get(getFooter(state), ['banner'])
export const showFooterBanner = state => !isContentError(state)
export const isContentReadyOrError = state => !isContentBusy(state) && !!(
  (getPageContent(state) && getGlobalContent(state)) || isContentError(state)
)
export const getUnorderedStockists = state => get(getGlobalContent(state), ['stockists'])
export const getStockists = createSelector(
  getUnorderedStockists,
  (stockists) => orderBy(stockists, store => store.name)
)
export const getTags = state => get(getGlobalContent(state), ['tags'])
export const getBundlesUnordered = state => get(getGlobalContent(state), ['bundles'])
export const getBundles = createSelector(
  getBundlesUnordered,
  (bundles) => sortBy(bundles, b => -b.order)
)
export const getBundlesForSlider = createSelector(
  getBundles,
  (bundles) => filter(bundles, b => b.show_in_slider)
)

export const getHeaderContent = state => get(getGlobalContent(state), ['header'])

export const getCookiesBannerContent = state => get(getGlobalContent(state), ['cookies'])

export const getStockistsDialogContent = state => get(getGlobalContent(state), ['stockists_dialog'])
export const getStockistsDialogTitle = state => get(getStockistsDialogContent(state), ['title'])
export const getStockistsDialogDisclaimer = state => get(getStockistsDialogContent(state), ['disclaimer'])

export const getCartContent = state => get(getGlobalContent(state), ['cart'])
export const getCartTitle = state => get(getCartContent(state), ['title'])
export const getCartCheckoutLabel = state => get(getCartContent(state), ['checkout_label'])
export const getCartEmptyCopy = state => get(getCartContent(state), ['empty_cart_copy'])
export const getCartCouponCopy = state => get(getCartContent(state), ['coupon_copy'])

export const getCartShowBundles = state => get(getCartContent(state), ['show_bundles'])
export const getCartBundleCopy = state => get(getCartContent(state), ['bundle_copy'])
export const getCartBundleLink = state => get(getCartContent(state), ['bundle_link', 0])
export const getCartBundleImage = state => get(getCartContent(state), ['bundle_image'])

export const getCartBuyButtonLabels = createSelector(
  getCartContent,
  content => ({
    addToCartLabel: content.add_to_cart_label,
    addingToCartLabel: content.adding_to_cart_label,
    addedToCartLabel: content.added_to_cart_label,
    outOfStockLabel: content.out_of_stock_label || 'Out Of Stock'
  })
)

export const getPageId = state => get(getPageContent(state), ['id'])
export const getPageSlug = state => get(getPageContent(state), ['slug'])
export const getPageType = state => get(getPageContent(state), ['type'])
export const getPageTitle = state => get(getPageContent(state), ['title'])
export const getPageSubTitle = state => get(getPageContent(state), ['subtitle'])
export const getPageMetaData = createSelector(
  getGlobalContent,
  getPageContent,
  getCurrentRoutePath,
  (global, page, path) => {
    const url = get(page, ['seo', 'canonicalUrl']) || `${process.env.REACT_APP_CANONICAL_URL_BASE}${path}`
    return {
      ...get(page, ['seo'], { metaTitle: get(page, ['title']) }),
      siteName: get(global, ['title']),
      ogUrl: url,
      canonicalUrl: url
    }
  }
)
export const getPageHeroImage = state => get(getPageContent(state), ['hero'])

export const isHomepage = createSelector(
  getPageSlug,
  (slug) => slug === 'home'
)

export const getPageHeadTitle = createSelector(
  getGlobalContent,
  getPageMetaData,
  isHomepage,
  (global, meta, homepage) => {
    const metaTitle = get(meta, ['metaTitle'])
    const globalTitle = get(global, ['title'])
    if (!metaTitle) {
      return globalTitle
    }
    if (!globalTitle) {
      return metaTitle
    }
    return `${metaTitle} – ${globalTitle}`
  }
)

export const getPageSlices = createSelector(
  getPageContent,
  (page) => {
    return (page && page.slices) ? map(page.slices, (slice, index) => ({
      id: `${page.id}-${index}`,
      ...slice
    })) : undefined
  }
)

export const GetArticleListSlice = createSelector(
  getPageSlices,
  (slices) => find(slices, slice => slice.type === 'article_list_slice')
)

// We still need to create a selector factory here. 'useSelector' is expecting a function with
// one argument, the state
export const getSliceByIdSelector = createCachedSelector(
  (id) => id,
  (id) => {
    return createSelector(
      getPageSlices,
      slices => find(slices, slice => slice.id === id)
    )
  }
)(
  (id) => id
)

export const getPreviewMode = state => get(state, ['content', 'preview'])

export const isPreviewMode = createSelector(
  isPreviewRoute,
  getPreviewMode,
  (previewRoute, previewMode) => previewRoute || previewMode
)

const makeSliceContentSelector = (key, combiner) => createCachedSelector(
  (id) => id,
  (id) => {
    return createSelector(
      state => get(state, ['content', key]),
      (stateSlice) => combiner(get(stateSlice, [id]))
    )
  }
)(
  (id) => id
)

const isSliceContentBusy = state => get(state, ['busy'])
const getSliceContentError = state => get(state, ['error'])
const getSliceContent = state => get(state, ['content'])

export const getIsArticlesBusySelector = makeSliceContentSelector('articles', isSliceContentBusy)
export const getArticlesErrorSelector = makeSliceContentSelector('articles', getSliceContentError)
export const getArticlesSelector = makeSliceContentSelector('articles', getSliceContent)
export const getIsArticlesReadyOrErrorSelector = makeSliceContentSelector('articles', state => (
  !isSliceContentBusy(state) && !!(getSliceContent(state) || getSliceContentError(state))
))

export const getArticleTagFilter = createSelector(
  getCurrentQueryString,
  qs => get(qs, ['tag'], '').toLowerCase()
)

export const getProductPopupsByHandle = createSelector(
  getGlobalContent,
  global => keyBy(get(global, ['products', 'product_buy_popups'], []), x => x.handle_key)
)

export const getProductDefaultsByHandle = createSelector(
  getGlobalContent,
  global => keyBy(get(global, ['products', 'product_defaults'], []), x => x.handle_key)
)

export const getShopifySubscriptionsByHandle = createSelector(
  getGlobalContent,
  global => keyBy(get(global, ['products', 'product_subscriptions'], []), x => x.handle_key)
)

export const hasToolbarFilter = createSelector(
  getPageSlices,
  isCurrentBreakpointMobile,
  (slices, mobile) => mobile && !!find(slices, slice => slice.type === 'article_list_slice')
)

export const getShippingInfo = createSelector(
  getGlobalContent,
  (global) => {
    return get(global, ['products', 'shipping_copy'])
  }
)

export const hasProductToolbar = createSelector(
  getPageSlices,
  slices => !!find(slices, ({ type }) => type === 'product_toolbar')
)

export const getComplimentaryProductsByHandle = createSelector(
  getGlobalContent,
  global => {
    const productDefaults = get(global, ['products', 'product_defaults'], [])
    var items = uniqBy(compact(flatten(map(productDefaults, x => x.complimentary_items))), x => x.handle)
    return keyBy(items, x => x.handle)
  }
)
