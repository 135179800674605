import get from 'lodash/get'
import { combineReducers } from 'redux'
import {
  CONTENT_TYPE,
  ARTICLES_TYPE,
  GLOBAL_CONTENT_TYPE,
  REQUEST_PREVIEW_SESSION
} from '../actions'
import makeFetchReducer, { makeKeyedFetchReducer } from './makeFetchReducer'
import { ActionType } from 'redux-promise-middleware'

function makePageReducer () {
  const fetchReducer = makeFetchReducer(CONTENT_TYPE)
  return (state, action) => {
    if (action.type === `${CONTENT_TYPE}_${ActionType.Fulfilled}` && get(action, ['meta', 'arrivedTooLate'])) {
      // The race middleware is telling us to ignore this payload because another request is already in progress.
      // No state change - stay in busy state.
      return state
    }
    return fetchReducer(state, action)
  }
}

function makePreviewReducer () {
  return (state = false, action) => {
    switch (action.type) {
      case REQUEST_PREVIEW_SESSION:
        return true
      default:
        return state
    }
  }
}

export default combineReducers({
  page: makePageReducer(),
  global: makeFetchReducer(GLOBAL_CONTENT_TYPE),
  preview: makePreviewReducer(),
  articles: makeKeyedFetchReducer(ARTICLES_TYPE, 'sliceId', 'articles')
})
