import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import {
  toggleStockistDialog
} from 'redux/actions'
import ResponsiveImage from '../ResponsiveImage'
import theme from 'style/theme'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'
import RichText from 'components/RichText'
import Link from 'components/Link'
import cn from 'classnames'

const TabContent = ({ logos, copy, label, index, selected }) => {
  const classes = useStyles()

  const logoComponents = useMemo(() => {
    return logos ? logos.map((logo, i) => {
      const { image, url } = logo
      const Container = logo.url ? Link : ({ to, target, ...props }) => <span {...props} />
      return <Container key={i} to={url} target='_blank' className={classes.logoWrapper}><ResponsiveImage className={classes.logo} {...image} /></Container>
    }) : undefined
  }, [logos])

  return (
    <div className={cn(classes.tabContent, { selected })} style={{ transform: `translateX(-${100 * index}%)` }}>
      {copy && <RichText className={classes.copy} content={copy.text} />}
      <div className={classes.logos}>
        {label && <span className={classes.logoLabel}>{label}</span>}
        {logoComponents}
      </div>
    </div>
  )
}

export default forwardRef(({ slice, index }, ref) => {
  const dispatch = useDispatch()
  const { title, tabs } = slice
  const classes = useStyles({ first: index === 0 })
  const [selectedTab, setSelectedTab] = useState(0)

  const onTabClicked = useCallback((tabIndex) => { setSelectedTab(tabIndex) }, [])

  const handleOpenStockistsPanel = useCallback(() => {
    dispatch(toggleStockistDialog())
  }, [])

  return (
    <section className={classes.section}>
      <div className={classes.titleWrapper}>
        <h1 className={classes.title}>{title}</h1>
        <ul className={classes.tabs}>
          {tabs && tabs.map(({ title }, key) => (
            <li key={title}><button onClick={() => onTabClicked(key)} className={cn(classes.tabButton, { selected: selectedTab === key })}>{title}</button></li>
          ))}
          <li>
            <button className={cn(classes.tabButton)} onClick={handleOpenStockistsPanel} aria-label='Show list of stockists'>
              Find a Store
            </button>
          </li>
        </ul>
      </div>
      <div className={classes.tabContentWrapper}>
        {tabs && tabs.map((tab, key) => (
          <TabContent key={key} {...tab} index={key} selected={selectedTab === key} />
        ))}
      </div>
    </section>
  )
})

const useStyles = createUseStyles({
  section: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    padding: [vw(32), span(1), 0],
    [theme.breakpoints.up('md')]: {
      padding: [vw(32, 'desktop'), span(4), 0]
    },
    '&.first': {
      padding: [vw(195), span(1), 0],
      [theme.breakpoints.up('md')]: {
        padding: [vw(240, 'desktop'), span(4), 0]
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: vw(32),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginBottom: vw(96, 'desktop')
    }
  },
  title: {
    fontSize: vw(20),
    marginBottom: vw(32),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(20, 'desktop'),
      marginBottom: 0
    }
  },
  tabs: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    margin: [0, vw(-16)],
    [theme.breakpoints.up('md')]: {
      margin: [0, vw(-16, 'desktop'), 0, vw(16, 'desktop')]
    }
  },
  tabButton: {
    opacity: 0.4,
    color: 'inherit',
    transition: 'opacity 0.25s ease-in-out',
    textTransform: 'uppercase',
    display: 'block',
    padding: [0, 0, vw(6)],
    position: 'relative',
    margin: [0, vw(16)],
    fontFamily: theme.fonts.din,
    fontSize: vw(12),
    [theme.breakpoints.up('md')]: {
      padding: [0, 0, vw(6, 'desktop')],
      margin: [0, vw(16, 'desktop')],
      fontSize: vw(12, 'desktop')
    },
    '&.selected': {
      opacity: 1
    },
    '&:hover': {
      opacity: 1
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 1,
      backgroundColor: 'currentColor'
    }
  },
  tabContentWrapper: {
    display: 'flex',
    whiteSpace: 'nowrap'
  },
  tabContent: {
    whiteSpace: 'normal',
    opacity: 0,
    position: 'relative',
    transition: 'opacity 0.25s ease-in-out',
    minWidth: '100%',
    pointerEvents: 'none',
    '&.selected': {
      opacity: 1,
      pointerEvents: 'all'
    },
    '& p': {
      lineHeight: 1.1,
      fontSize: vw(30),
      marginBottom: vw(30),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(80, 'desktop'),
        fontSize: vw(45, 'desktop')
      }
    },
    '& a': {
      color: theme.colors.secondary,
      textDecoration: 'none',
      fontStyle: 'italic'
    }
  },
  logoLabel: {
    fontFamily: theme.fonts.din,
    fontSize: vw(10),
    lineHeight: 1.2,
    display: 'flex',
    alignItems: 'center',
    margin: [0, vw(16), vw(16), 0],
    textTransform: 'uppercase',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      margin: [vw(4, 'desktop'), vw(32, 'desktop'), 0, 0],
      fontSize: vw(10, 'desktop')
    }
  },
  logos: {
    display: 'flex',
    marginTop: vw(30),
    width: '100%',
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginTop: vw(60, 'desktop')
    }
  },
  logoWrapper: {
    marginBottom: vw(16),
    marginRight: vw(15),
    [theme.breakpoints.up('md')]: {
      marginRight: vw(15, 'desktop'),
      marginBottom: vw(16, 'desktop')
    },
    '&:last-child': {
      margin: 0
    }
  },
  logo: {
    height: vw(40),
    [theme.breakpoints.up('md')]: {
      height: vw(40, 'desktop'),
      marginRight: vw(15, 'desktop')
    },
    '& picture, & img': {
      height: '100%'
    },
    '& img': {
      position: 'relative',
      width: 'auto'
    },
    '& picture:before': {
      display: 'none'
    }
  },
  copy: {
    textAlign: 'center',
    fontSize: vw(24),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(45, 'desktop')
    }
  }
}, { name: 'ShopInStoreSlice' })
