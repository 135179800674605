import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage'

const ImageSlice = forwardRef(({
  className,
  slice: { image }
}, ref) => {
  const classes = useStyles()
  return image
    ? (
      <section className={cn(className, classes.wrapper)} ref={ref}>
        <ResponsiveImage className={classes.image} {...image} />
      </section>
    ) : null
})

const useStyles = createUseStyles({
  wrapper: {
    border: [1, 'solid', theme.colors.black],
    margin: [0, 'auto', 40]
  },
  image: {
  }
}, { name: 'ImageSlice' })

export default ImageSlice
