import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import Link from 'components/Link'
import theme from 'style/theme'
import cn from 'classnames'
import { vw } from 'helpers/vw'
import { expo } from 'style/eases'

const useStyles = createUseStyles({
  button: {
    color: theme.colors.primary,
    textTransform: 'uppercase',
    fontFamily: theme.fonts.din,
    lineHeight: 1,
    fontWeight: 'bold',
    border: `1px solid ${theme.colors.primary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    fontSize: vw(12),
    padding: [vw(16), vw(20), vw(14), vw(20)],
    borderRadius: vw(24),
    flexGrow: 0,
    flexShrink: 0,
    transition: `background 2s ${expo.out}, transform 2s ${expo.out}`,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop'),
      padding: [vw(16, 'desktop'), vw(20, 'desktop'), vw(14, 'desktop'), vw(20, 'desktop')],
      borderRadius: vw(24, 'desktop'),
      '&:hover': {
        transitionDuration: '0.5s',
        transform: 'scale(1.1) translateZ(0)'
      }
    },
    '& span': {
      fontFamily: theme.fonts.austin,
      textTransform: 'lowercase',
      fontWeight: 'normal',
      fontSize: vw(15),
      marginLeft: vw(5),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(15, 'desktop'),
        marginLeft: vw(5, 'desktop')
      }
    },
    '&.disable': {
      opacity: 0.8,
      pointerEvents: 'none'
    }
  },
  transparent: {
    background: 'transparent',
    borderColor: theme.colors.beige,
    color: theme.colors.beige,
    '&:hover': {
      borderColor: theme.colors.beige
    }
  },
  white: {
    borderColor: theme.colors.primary,
    background: theme.colors.beige,
    color: theme.colors.primary
  }
}, { name: 'Button' })

const Button = forwardRef(({ children, to, link, subtext, transparent, className, ...rest }, ref) => {
  const classes = useStyles()
  const Tag = to || link ? Link : 'button'
  const variationClass = transparent ? classes.transparent : classes.white

  return (
    <Tag className={cn(classes.button, variationClass, className)} to={to} link={link} ref={ref} {...rest}>{children}{subtext && <span> {subtext}</span>}</Tag>
  )
})

export default Button
