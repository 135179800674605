import React, { useRef, useState, useEffect, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import useHlsPlayer from '../hooks/useHlsPlayer'
import get from 'lodash/get'
import ResponsiveImage from './ResponsiveImage'
import theme from '../style/theme'
import { useInView } from 'react-intersection-observer'
import { ReactComponent as PlayIcon } from '../images/play.svg'
import { vw } from '../helpers/vw'

const getVideoAspect = (video) => {
  const aspect = get(video, ['data', 'aspect'], '16:9').split(':')
  return aspect[1] / aspect[0]
}

const VideoPlayer = ({ className, video, posterImage, ...rest }) => {
  const aspect = getVideoAspect(video)
  const classes = useStyles({ aspect })
  const { url } = video
  const [playing, setPlaying] = useState(false)
  const playerRef = useRef()

  useHlsPlayer(playerRef, url)
  const [inViewRef, inView] = useInView({ threshold: 0 })

  const handleVideoClick = useCallback(() => {
    if (playerRef.current) {
      setPlaying(true)
      playerRef.current.play()
    }
  }, [])

  useEffect(() => {
    if (playerRef.current && !inView) {
      setPlaying(false)
      playerRef.current.pause()
    }
  }, [inView])

  return (
    <div className={cn(classes.videoContainer, className)} ref={inViewRef}>
      <video
        ref={playerRef}
        src={url}
        className={cn(classes.video, { playing })}
        controls
        {...rest}
      />
      <button className={cn(classes.playButtonContainer, { playing })} onClick={handleVideoClick} aria-label='Play Video'>
        <ResponsiveImage {...posterImage} className={classes.videoPoster} aspect={1} />
        <PlayIcon className={classes.playIcon} />
      </button>
    </div>
  )
}

const useStyles = createUseStyles({
  videoContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: ({ aspect }) => `${aspect * 100}%`,
    backgroundColor: theme.colors.black
  },
  video: {
    outline: 'none',
    width: '100%',
    display: 'block',
    opacity: 0,
    transition: 'opacity 0.15s ease-in-out',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    '&.playing': {
      opacity: 1
    }
  },
  playButtonContainer: {
    outline: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 1,
    pointerEvents: 'all',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 0.15s ease-in-out',
    '&.playing': {
      opacity: 0,
      pointerEvents: 'none'
    }
  },
  videoPoster: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  },
  playIcon: {
    position: 'relative',
    width: vw(53),
    height: vw(59),
    [theme.breakpoints.up('md')]: {
      width: vw(106, 'desktop'),
      height: vw(118, 'desktop')
    }
  }
}, { name: 'VideoPlayer' })

export default VideoPlayer
