import React from 'react'
import { InView } from 'react-intersection-observer'
import { createUseStyles } from 'react-jss'
import gsap, { Expo } from 'gsap'
import matchAll from 'string.prototype.matchall'

const useStyles = createUseStyles({
  words: {
    display: 'inline-block',
    marginTop: '0.45em',
    '&>span': {
      overflow: 'hidden',
      display: 'inline-block',
      marginTop: '-0.13em',
      padding: ['0.01em', '0.03em', '0.12em', '0.03em'],
      '&>span': {
        display: 'inline-block',
        transform: 'translateY(calc(100% + 0.14em)) translateZ(0)',
        willChange: 'transform'
      }
    }
  }
})

const SplitWordsAnimation = (props) => {
  const classes = useStyles()

  const splitWords = (content) => {
    let dom = `<span class="${classes.words}">`
    const matches = [...matchAll(content.replace(/<br \/>/gi, '<br>'), (/<\s*(\w+\b)(?:(?!<\s*\/\s*\1\b)[\s\S])*<\s*\/\s*\1\s*>|\S+/g))]
    matches.forEach(v => {
      const match = v[0]
      if (match !== '<br>' && match !== '<br/>') { dom += `<span><span>${match}</span></span> ` } else { dom += match }
    })
    dom += '</span>'
    return dom
  }
  const content = splitWords(props.children)

  const handleChange = (inView, entry) => {
    if (inView) {
      gsap.to(entry.target.querySelectorAll(`.${classes.words}>span>span`), 1.2, { ease: Expo.easeOut, y: 0, stagger: props.stagger || 0.06, delay: props.delay || 0 })
    }
  }

  return (
    <InView as={props.as || 'p'} onChange={handleChange} threshold={0.5} triggerOnce className={props.className} dangerouslySetInnerHTML={{ __html: content }} />
  )
}

export default SplitWordsAnimation
