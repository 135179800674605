import React, { forwardRef, useRef } from 'react'
import NewsletterSignup from './NewsletterSignup'
import useResizeObserver from 'hooks/useResizeObserver'
import gsap from 'gsap'
let resized = false

const NewsletterPopup = forwardRef((props, ref) => {
  const popupRef = useRef()

  useResizeObserver(popupRef, (entry) => {
    if (!resized) {
      resized = true
      gsap.fromTo(popupRef.current, 1.5, { y: '110%' }, {
        y: '0%',
        opacity: 1,
        clearProps: 'transform',
        ease: 'expo.out',
        duration: 1.5,
        delay: 0.4,
        onStart: () => {
          popupRef.current.style.visibility = 'inherit'
        }
      })
    } else {
      popupRef.current.style.visibility = 'inherit'
    }
  })

  return (
    <div ref={popupRef} style={{ opacity: 0 }}>
      <NewsletterSignup
        placeholder='Sign up for 10% off your first order'
        submitText='ok'
        isSmall
      />
    </div>
  )
})

export default NewsletterPopup
