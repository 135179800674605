export default function () {
  window.lazySizesConfig = window.lazySizesConfig || {}
  window.lazySizesConfig.init = false
  window.lazySizesConfig.expand = 500
  window.lazySizesConfig.hFac = 2
  require('lazysizes/plugins/attrchange/ls.attrchange')
  require('lazysizes/plugins/respimg/ls.respimg')
  require('lazysizes/plugins/object-fit/ls.object-fit')
  const lazysizes = require('lazysizes')
  lazysizes.init()
}
