import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { vw } from 'helpers/vw'
import theme from '../../../style/theme'
import { Field, ErrorMessage, useFormikContext } from 'formik'
import Color from 'color'

const TextField = ({ className, name, label, required, disabled, highlighted, ...rest }) => {
  const { values } = useFormikContext()
  const value = values[name]
  const classes = useStyles()
  return (
    <div className={cn(classes.field, className, { active: value, disabled, highlighted })}>
      <label htmlFor={name}>{label}</label>
      <Field name={name} {...rest} required={required} />
      <ErrorMessage name={name} component='span' className={classes.error} />
    </div>
  )
}

const useStyles = createUseStyles({
  field: {
    width: '100%',
    position: 'relative',
    marginBottom: vw(32),
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(32, 'desktop') // [vw(12, 'desktop'), vw(16, 'desktop')]
    },
    '& > label': {
      fontFamily: theme.fonts.austin,
      fontWeight: 300,
      pointerEvents: 'none',
      fontSize: vw(18),
      lineHeight: vw(35),
      position: 'absolute',
      opacity: 0.5,
      transition: 'all 0.1s ease-in-out',
      top: vw(12),
      left: vw(12),
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('md')]: {
        fontSize: vw(25, 'desktop'),
        lineHeight: vw(35, 'desktop'),
        top: vw(16, 'desktop'),
        left: vw(12, 'desktop')
      }
    },
    '& > input, & > textarea': {
      fontFamily: theme.fonts.austin,
      fontWeight: 300,
      border: [1, 'solid', Color(theme.colors.beige).alpha(0.5).string()],
      width: '100%',
      borderRadius: 0,
      backgroundColor: 'transparent',
      color: 'currentColor',
      lineHeight: vw(35),
      fontSize: vw(18),
      padding: [vw(12), vw(12), vw(4)],
      outline: 'none',
      [theme.breakpoints.up('md')]: {
        padding: [vw(16, 'desktop'), vw(12, 'desktop'), vw(4, 'desktop')],
        lineHeight: vw(35, 'desktop'),
        fontSize: vw(25, 'desktop')
      }
    },
    '& > span': {
      fontFamily: theme.fonts.din,
      position: 'absolute',
      top: `calc(100% + ${vw(4)})`,
      right: 0,
      fontSize: vw(12),
      [theme.breakpoints.up('md')]: {
        top: `calc(100% + ${vw(4, 'desktop')})`,
        fontSize: vw(12, 'desktop')
      }
    },
    '& > input:focus': {
      borderColor: theme.colors.beige,
      outline: 'none'
    },
    '&.active': {
      '& > input, & > textarea': {
        borderColor: theme.colors.beige
      },
      '& > label': {
        top: vw(2),
        transform: 'scale(0.5)',
        transformOrigin: 'top left',
        [theme.breakpoints.up('md')]: {
          top: vw(2, 'desktop')
        }
      }
    },
    '&.disabled': {
      '& > input, & > textarea': {
        borderColor: `${Color(theme.colors.beige).alpha(0.5).string()} !important`,
        color: Color(theme.colors.beige).alpha(0.5).string()
      }
    },
    '&.highlighted': {
      '& > input, & > textarea': {
        borderColor: theme.colors.beige
      }
    }
  }
}, { name: 'TextField' })

export default TextField
