import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import theme from '../style/theme'
import { getFooter, showFooterBanner, hasToolbarFilter, hasProductToolbar } from '../redux/selectors'
import { vw } from '../helpers/vw'
import { span } from '../helpers/span'
import { ReactComponent as NutrafruitLogo } from '../images/nutrafruit.svg'
import { ReactComponent as Logo } from '../images/footer-logo.svg'
import Link from './Link'
import TrackedLink from './TrackedLink'
import { resolveLink } from '../helpers/resolveLink'

import FooterBanner from './FooterBanner'
import NewsletterSignup from './NewsletterSignup'

const FooterNewsletter = () => {
  const footer = useSelector(getFooter)
  const {
    newsletter_title: title,
    newsletter_placeholder: placeholder,
    newsletter_copy_richtext: copy,
    newsletter_subscribed_message: successMessage,
    newsletter_error_message: errorMessage,
    newsletter_submit_button_text: submitText
  } = footer
  return (
    <NewsletterSignup
      title={title}
      placeholder={placeholder}
      copy={copy}
      successMessage={successMessage}
      errorMessage={errorMessage}
      submitText={submitText}
    />
  )
}

function Footer () {
  const classes = useStyles()
  const footer = useSelector(getFooter)
  const showBanner = useSelector(showFooterBanner)
  const { links, copyright } = footer
  const toolbarProductVisible = useSelector(hasProductToolbar)
  const toolbarFilterVisible = useSelector(hasToolbarFilter)
  const withToolbar = toolbarProductVisible || toolbarFilterVisible

  return (
    <footer>
      {showBanner && <FooterBanner />}
      <div className={cn(classes.footer, withToolbar && classes.footerToolbarPadding)}>
        <div className={classes.content}>
          <div className={classes.newsletterWrapper}>
            <FooterNewsletter />
          </div>
          <Logo viewBox='0 0 361 419' className={classes.image} />
          <nav className={classes.links}>
            <NutrafruitLogo className={classes.nutrafruitLogo} />
            {links && (
              <ul className={classes.list}>
                {links.map((footerLink) => {
                  const {
                    id,
                    link,
                    pixel_event: pixelEvent
                  } = footerLink
                  const resolvedLink = resolveLink(link[0])
                  return (
                    <li key={id}>
                      {pixelEvent ? (
                        <TrackedLink className={classes.link} link={resolvedLink} event={pixelEvent} />
                      ) : (
                        <Link className={classes.link} link={resolvedLink} />
                      )}
                    </li>
                  )
                })}
              </ul>
            )}
          </nav>
        </div>
        <span className={classes.copyrightContainer}>
          <span className={classes.copyright}>{copyright}</span>
          <NutrafruitLogo className={classes.nutrafruitLogoMobile} />
        </span>
      </div>
    </footer>
  )
}

const useStyles = createUseStyles({
  footer: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.beige,
    padding: [vw(50), span(1)],
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: [vw(100, 'desktop'), span(1)]
    }
  },
  footerToolbarPadding: {
    paddingBottom: `calc(${vw(50)} + ${vw(40)})`,
    [theme.breakpoints.up('md')]: {
      paddingBottom: `calc(${vw(100, 'desktop')} + ${vw(40, 'desktop')})`
    }
  },
  newsletterWrapper: {
    marginBottom: vw(50),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  },
  content: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      '& > *': {
        width: '33%'
      }
    }
  },
  image: {
    display: 'block',
    margin: [0, 'auto'],
    width: span(22),
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      width: vw(380, 'desktop')
    }
  },
  links: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right'
    }
  },
  list: {
    padding: 0,
    margin: 0,
    justifyContent: 'flex-end',
    listStyle: 'none',
    marginTop: vw(40),
    '& > *:not(:last-child)': {
      margin: [0, 0, vw(14)]
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      marginTop: vw(26, 'desktop'),
      '& > *:not(:last-child)': {
        margin: [0, '1em', 0, 0]
      }
    }
  },
  link: {
    color: 'currentColor',
    textTransform: 'uppercase',
    fontSize: vw(14),
    fontFamily: theme.fonts.din,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(10, 'desktop')
    },
    '&:hover': {
      textDecoration: 'none'
    }
  },
  nutrafruitLogo: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      width: vw(150, 'desktop')
    }
  },
  copyrightContainer: {
    display: 'flex',
    marginTop: vw(60),
    fontSize: vw(12),
    fontFamily: theme.fonts.din,
    fontStyle: 'normal',
    lineHeight: 1.2,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      fontSize: vw(12, 'desktop'),
      marginTop: vw(60, 'desktop'),
      textAlign: 'center'
    }
  },
  nutrafruitLogoMobile: {
    width: vw(130),
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  copyright: {
    opacity: 0.5,
    display: 'block',
    maxWidth: vw(130)
  }
}, { name: 'Footer' })

export default Footer
