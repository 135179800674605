import { useEffect, useRef } from 'react'
import Hls from 'hls.js'
import mux from 'mux-embed'

const useHlsPlayer = (playerRef, source, onReady) => {
  const hlsRef = useRef
  useEffect(() => {
    if (playerRef.current) {
      const video = playerRef.current
      if (Hls.isSupported()) {
        hlsRef.current = new Hls()
        hlsRef.current.loadSource(source)
        hlsRef.current.attachMedia(playerRef.current)
        hlsRef.current.on(Hls.Events.MANIFEST_PARSED, () => {
          if (onReady) {
            onReady(video, hlsRef.current)
          }
        })
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = source
        video.addEventListener('loadedmetadata', () => {
          if (onReady) {
            onReady(video)
          }
        })
      }

      mux.monitor(playerRef.current, {
        debug: false,
        hlsjs: hlsRef.current,
        Hls: Hls,
        data: {
          env_key: 'production',
          player_name: 'Video Player',
          player_init_time: window.muxPlayerInitTime
        }
      })
      return () => {
        if (hlsRef.current) {
          hlsRef.current.detachMedia()
        }
      }
    }
  }, [source])

  return [hlsRef]
}

export default useHlsPlayer
