import React, { forwardRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import theme from 'style/theme'
import { span } from 'helpers/span'
import { vw } from 'helpers/vw'
import cn from 'classnames'
import { expo } from 'style/eases'
import { useSelector, useDispatch } from 'react-redux'
import Color from 'color'
import { toggleCartDialog } from '../../redux/actions'
import {
  isCartDialogOpen,
  getCartEmptyCopy,
  getCartTitle,
  isCartEmpty,
  getCartLineItems,
  getCartSubtotalPriceAmount,
  getCartDiscount,
  getCartLineItemsSubtotalPrice
} from '../../redux/selectors'
import LineItem from './LineItem'
import { formatPrice } from '../../helpers/format'
import useOutsideClickListener from '../../hooks/useOutsideClickListner'
import { ReactComponent as Close } from 'images/close.svg'
import BlockContent from '@sanity/block-content-to-react'
import CartFooter from './CartFooter'

const CartDialog = forwardRef((props, ref) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const open = useSelector(isCartDialogOpen)
  const emptyCartCopy = useSelector(getCartEmptyCopy)
  const title = useSelector(getCartTitle)
  const lineItems = useSelector(getCartLineItems)
  const totalAmount = useSelector(getCartSubtotalPriceAmount)
  const lineItemsSubtotalPrice = useSelector(getCartLineItemsSubtotalPrice)
  const discount = useSelector(getCartDiscount)

  const handleClose = useCallback(() => {
    dispatch(toggleCartDialog())
  }, [])

  const empty = useSelector(isCartEmpty)

  const containerRef = useOutsideClickListener(open, handleClose)

  return (
    <section className={cn(classes.panel, { open })} ref={containerRef}>
      <div className={classes.main}>
        <header className={classes.header}>
          <span className={classes.title}>
            <BlockContent blocks={title.text} />
          </span>
          <button className={classes.closeButton} aria-label='close' onClick={handleClose}><Close viewBox='0 0 12 12' /></button>
        </header>
        {!empty && (
          <div className={classes.products}>
            {lineItems.map(lineItem => (<LineItem key={lineItem.id} lineItem={lineItem} />))}
            {discount ? (
              <>
                <span className={classes.subtotal}>Subtotal <span className={classes.price}>{formatPrice(lineItemsSubtotalPrice)}</span></span>
                <span className={classes.discount}>Discount <span className={classes.price}>{formatPrice(discount)}</span></span>
                <span className={classes.total}>Total <span className={classes.price}>{formatPrice(totalAmount)}</span></span>
              </>
            ) : <span className={classes.total}>{formatPrice(totalAmount)}</span>}
          </div>
        )}
        {empty && (
          <div className={classes.empty}>
            {emptyCartCopy}
          </div>
        )}
      </div>
      {!empty && (
        <CartFooter />
      )}
    </section>
  )
})

const useStyles = createUseStyles({
  panel: {
    position: 'fixed',
    transform: 'translate3d(100%,0,0)',
    transition: `transform 1s ${expo.out}`,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: theme.zIndex.cart,
    background: theme.colors.beige,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: span(9),
      borderLeft: [1, 'solid', Color(theme.colors.primary).alpha(0.3).toString()]
    },
    '&.open': {
      transform: 'translate3d(0,0,0)'
    }
  },
  main: {
    flex: '1 1 auto',
    overflow: 'auto',
    padding: span(1),
    [theme.breakpoints.up('md')]: {
      padding: span(1, 9)
    }
  },
  products: {
  },
  header: {
    fontFamily: theme.fonts.austin,
    fontSize: 35,
    textTransform: 'uppercase',
    color: theme.colors.primary,
    display: 'flex',
    alignItems: 'center',
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(8, 'desktop')
    }
  },
  title: {
    flex: '1 1 auto',
    '& > p': {
      margin: 0
    }
  },
  closeButton: {
    cursor: 'pointer',
    flex: '0 0 auto',
    appearance: 'none',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 34,
    height: 34,
    border: 'none',
    color: theme.colors.white,
    background: theme.colors.secondary,
    transition: `background 1s ${expo.out}, transform 1s ${expo.out}`,
    '&:hover': {
      background: theme.colors.primary,
      transitionDuration: '0.5s',
      transform: 'scale(1.1) translateZ(0)'
    }
  },
  empty: {
    fontSize: vw(24),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(24, 'desktop')
    }
  },
  totals: {
    textTransform: 'uppercase',
    display: 'block',
    textAlign: 'right',
    fontFamily: theme.fonts.austin,
    marginTop: vw(4),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(8, 'desktop')
    }
  },
  subtotal: {
    composes: '$totals',
    fontSize: vw(14),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop')
    }
  },
  discount: {
    composes: '$totals',
    fontSize: vw(14),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop')
    }
  },
  total: {
    composes: '$totals',
    fontSize: vw(20),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(20, 'desktop')
    }
  },
  price: {
    display: 'inline-block',
    width: vw(80),
    [theme.breakpoints.up('md')]: {
      width: vw(80, 'desktop')
    }
  }
}, { name: 'CartDialog' })

export default CartDialog
