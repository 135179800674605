import React, { forwardRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import {
  incrementCartLineItemQuantityActionCreator,
  decrementCartLineItemQuantityActionCreator,
  removeFromCartActionCreator
} from '../../redux/actions'
import { isCartBusy, getProductDefaultsByHandle, getComplimentaryProductsByHandle } from '../../redux/selectors'
import { formatPrice } from '../../helpers/format'
import Color from 'color'
import ResponsiveImage from '../ResponsiveImage'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'
import theme from 'style/theme'

const LineItem = forwardRef(({ lineItem }, ref) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const busy = useSelector(isCartBusy)
  const productDefaultsByHandle = useSelector(getProductDefaultsByHandle)
  const complimentaryProductsByHandle = useSelector(getComplimentaryProductsByHandle)

  const defaultProduct = productDefaultsByHandle[lineItem.product.slug]
  const complimentaryProduct = complimentaryProductsByHandle[lineItem.product.slug]
  const isComplimentaryProduct = !!complimentaryProduct

  const handleIncrement = useCallback(() => {
    if (busy) return
    dispatch(incrementCartLineItemQuantityActionCreator(lineItem.variant.id))
  }, [busy])

  const handleDecrement = useCallback(() => {
    if (busy) return
    dispatch(decrementCartLineItemQuantityActionCreator(lineItem.variant.id))
  }, [busy])

  const handleRemove = useCallback(() => {
    if (busy) return
    dispatch(removeFromCartActionCreator(lineItem.variant.id))
  }, [busy])

  if (lineItem.quantity === 0) return null

  const image = get(complimentaryProduct, ['image'], get(defaultProduct, ['image'], lineItem.image))

  return (
    <div className={classes.container}>
      <ResponsiveImage {...image} alt={lineItem.title} className={classes.image} />
      <div className={classes.details}>
        <div className={classes.title}>{get(defaultProduct, ['title'], lineItem.title)}</div>
        <div className={classes.price}>
          {!isComplimentaryProduct && formatPrice(parseFloat(lineItem.variant.price.amount))}
          {get(defaultProduct, ['subtitle']) && <span className={classes.subtitle}>{get(defaultProduct, ['subtitle'])}</span>}
        </div>
        <div className={classes.quantity}>
          {!isComplimentaryProduct && (
            <>
              <button className={classes.adjustButton} onClick={handleDecrement} aria-label='Decrement Product'>-</button>
              <span className={classes.quantityLabel}>{lineItem.quantity}</span>
              <button className={classes.adjustButton} onClick={handleIncrement} aria-label='Increment Product'>+</button>
            </>
          )}
          <button className={classes.removeButton} onClick={handleRemove} aria-label='Remove Product'>Remove</button>
        </div>
      </div>
      <div className={classes.price}>
        {formatPrice(lineItem.quantity * parseFloat(lineItem.variant.price.amount))}
      </div>
    </div>
  )
})

const borderColor = Color(theme.colors.primary).alpha(0.3).toString()

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    color: theme.colors.primary,
    display: 'flex',
    padding: [vw(24), 0],
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      padding: [vw(24, 'desktop'), 0]
    },
    '&:after': {
      content: '""',
      backgroundColor: borderColor,
      height: 1,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      [theme.breakpoints.up('md')]: {
        left: 0,
        right: 0
      }
    }
  },
  image: {
    width: '25%',
    flex: '0 0 auto'
  },
  details: {
    flex: '1 1 auto',
    padding: [0, vw(14)],
    [theme.breakpoints.up('md')]: {
      padding: [0, vw(24, 'desktop')]
    }
  },
  title: {
    fontFamily: theme.fonts.austin,
    lineHeight: 1.1,
    textTransform: 'uppercase',
    fontSize: vw(20),
    marginBottom: vw(4),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(20, 'desktop'),
      marginBottom: vw(4, 'desktop')
    }
  },
  price: {
    flex: '0 0 auto',
    fontFamily: theme.fonts.austin,
    fontSize: vw(18),
    marginBottom: vw(8),
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(18, 'desktop'),
      marginBottom: vw(8, 'desktop')
    }
  },
  subtitle: {
    textTransform: 'uppercase',
    opacity: 0.5,
    fontFamily: theme.fonts.din,
    fontSize: vw(12),
    marginLeft: vw(4),
    marginTop: vw(1),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(4, 'desktop'),
      marginLeft: vw(8, 'desktop'),
      fontSize: vw(12, 'desktop')
    }
  },
  quantity: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.fonts.din
  },
  button: {
    background: 'none',
    outline: 'none',
    opacity: 0.5,
    width: vw(30),
    height: vw(30),
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: vw(30, 'desktop'),
      height: vw(30, 'desktop')
    }
  },
  quantityLabel: {
    composes: '$button',
    border: [1, 'solid', borderColor],
    borderLeft: 'none',
    borderRight: 'none',
    opacity: 0.5,
    paddingTop: 2
  },
  adjustButton: {
    composes: '$button',
    border: [1, 'solid', borderColor],
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  },
  removeButton: {
    background: 'none',
    border: 'none',
    outline: 'none',
    textDecoration: 'underline',
    fontFamily: theme.fonts.din,
    textTransform: 'uppercase',
    cursor: 'pointer',
    opacity: 0.5,
    marginLeft: vw(22),
    minHeight: vw(30),
    [theme.breakpoints.up('md')]: {
      marginLeft: vw(12, 'desktop'),
      minHeight: vw(30, 'desktop')
    },
    '&:first-child': {
      marginLeft: 0
    },
    '&:hover': {
      opacity: 1
    }
  }
}, { name: 'LineItem' })

export default LineItem
