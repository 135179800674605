export const BREAKPOINT_CHANGED = 'BREAKPOINT_CHANGED'
export const BRIGHT_SLICES_MOUNTED = 'BRIGHT_SLICES_MOUNTED'

export const CLOSE_MENU = 'CLOSE_MENU'
export const OPEN_MENU = 'OPEN_MENU'

export const breakpointChanged = breakpoint => ({
  type: BREAKPOINT_CHANGED,
  payload: {
    breakpoint
  }
})

export const brightSlicesMounted = slice => ({
  type: BRIGHT_SLICES_MOUNTED
})

export const closeMenu = () => ({ type: CLOSE_MENU })
export const openMenu = () => ({ type: OPEN_MENU })
