import React, { useRef, useEffect, useState } from 'react'
import Color from 'color'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { span } from '../../../helpers/span'
import { vw } from '../../../helpers/vw'
import useRaf from '../../../hooks/useRaf'
import { getProductDefaultsByHandle, isCookieAccepted } from '../../../redux/selectors'
import theme from '../../../style/theme'
import ResponsiveImage from '../../ResponsiveImage'
import cn from 'classnames'
import BuyButtonWithQuantity from '../ProductHero/BuyButtonWithQuantity'
import useProductAddToCartLabel from '../ProductHero/useProductAddToCartLabel'
import SubscribeButton from '../../SubscribeButton'
import get from 'lodash/get'
import { formatPrice } from '../../../helpers/format'
import { createPortal } from 'react-dom'
import inDom from 'dom-helpers/canUseDOM'
import SmoothScroll from '../../SmoothScroll'
import gsap from 'gsap'
import detectIt from 'detect-it'
import useWindowResize from '../../../hooks/useWindowResize'

const TOP_OFFSET = 100

const ProductToolbar = ({ slice: { product, handle, title, theme } }) => {
  const classes = useStyles()
  const containerRef = useRef()
  const localsRef = useRef({ show: false, topOffset: TOP_OFFSET, cookiesAccepted: false })
  const productDefaults = useSelector(getProductDefaultsByHandle)[handle] || {}
  const cookiesAccepted = useSelector(isCookieAccepted)

  const productTitle = title || productDefaults.title || product.title
  const { image, best_seller_label: bestSellerLabel } = productDefaults
  const price = get(product, ['variants', 0, 'price', 'amount'])

  const addToCartLabel = useProductAddToCartLabel(product)
  const buyLabel = <>{addToCartLabel}<span className={classes.price}>{formatPrice(price)}</span></>

  localsRef.current.cookiesAccepted = cookiesAccepted

  const resize = () => {
    const productHero = document.getElementById('product-hero')
    if (productHero) {
      localsRef.current.topOffset = Math.max(productHero.getBoundingClientRect().height - (window.innerHeight / 2), TOP_OFFSET)
    }
  }

  useEffect(resize, [])
  useWindowResize(resize)

  useRaf(() => {
    if (localsRef.current.cookiesAccepted) {
      const y = detectIt.primaryInput === 'touch' ? window.scrollY : SmoothScroll.y
      const show = y > localsRef.current.topOffset
      if (show && !localsRef.current.show) {
        localsRef.current.show = true
        gsap.to(containerRef.current, { y: '-100%', duration: 1, ease: 'power4.inOut' })
      }
      if (!show && localsRef.current.show) {
        localsRef.current.show = false
        gsap.to(containerRef.current, { y: '0%', duration: 1, ease: 'power4.inOut' })
      }
    }
  }, [])

  return (
    <div className={cn(classes.container, theme)} ref={containerRef}>
      <div className={classes.left}>
        {image && <ResponsiveImage {...image} className={classes.image} aspect={image.aspect} />}
        <span className={classes.title}>{productTitle}</span>
        {bestSellerLabel && <span className={classes.bestSellerLabel}>{bestSellerLabel}</span>}
      </div>
      <div className={classes.right}>
        <BuyButtonWithQuantity
          product={product}
          text={buyLabel}
          classNames={{
            button: classes.buyButton,
            quantityButtons: classes.quantityButtons,
            quantityLabel: classes.quantityLabel
          }}
        />
        <SubscribeButton product={product} className={classes.subscribeButton} transparent={false} />
      </div>
    </div>
  )
}

export default (props) => {
  const [ready, setReady] = useState()
  useEffect(() => setReady(true), [])
  return inDom && ready ? createPortal(<ProductToolbar {...props} />, document.body) : null
}

const useStyles = createUseStyles({
  container: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: theme.zIndex.toolbar,
    top: '100%',
    left: 0,
    right: 0,
    padding: [vw(4), span(1)],
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    borderTop: [1, 'solid', Color(theme.colors.white).alpha(0.3).string()],
    [theme.breakpoints.up('md')]: {
      padding: [vw(2, 'desktop'), span(1)]
    },
    '&.light': {
      backgroundColor: theme.colors.white,
      color: theme.colors.black,
      borderColor: Color(theme.colors.primary).alpha(0.3).string()
    },
    '&.light $image:before': {
      opacity: 0.1
    },
    '&.light $buyButton button': {
      backgroundColor: theme.colors.primary,
      color: theme.colors.white,
      borderColor: theme.colors.white
    }
  },
  left: {
    display: 'flex',
    alignItems: 'center'
  },
  right: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontFamily: theme.fonts.austin,
    fontSize: vw(17),
    lineHeight: 1,
    textTransform: 'uppercase',
    marginRight: vw(8),
    [theme.breakpoints.up('md')]: {
      marginRight: 0,
      fontSize: vw(24, 'desktop')
    }
  },
  image: {
    position: 'relative',
    flexGrow: 0,
    flexShrink: 0,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      borderRadius: '50%',
      backgroundColor: theme.colors.secondary,
      height: vw(30),
      width: vw(30),
      transform: 'translate(-50%, -50%)',
      [theme.breakpoints.up('md')]: {
        height: vw(42, 'desktop'),
        width: vw(42, 'desktop')
      }
    },
    marginRight: vw(4),
    width: vw(34),
    [theme.breakpoints.up('md')]: {
      marginRight: vw(8, 'desktop'),
      width: vw(50, 'desktop')
    },
    '& picture:before': {
      display: 'none'
    },
    '& img': {
      position: 'static',
      width: '100%',
      height: 'auto',
      transform: 'rotate(15deg)'
    }
  },
  bestSellerLabel: {
    fontFamily: theme.fonts.din,
    fontSize: vw(10),
    display: 'none',
    border: [1, 'solid', 'currentColor'],
    padding: [vw(7), vw(12), vw(5)],
    marginLeft: vw(16),
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      padding: [vw(7, 'desktop'), vw(12, 'desktop'), vw(5, 'desktop')],
      marginLeft: vw(16, 'desktop'),
      display: 'block',
      fontSize: vw(10, 'desktop')
    }
  },
  buyButton: {
    '& button': {
      backgroundColor: 'transparent',
      color: 'currentColor',
      fontSize: vw(12),
      borderColor: 'currentColor',
      padding: [vw(14), vw(20), vw(12), vw(20)],
      [theme.breakpoints.up('md')]: {
        padding: [vw(14, 'desktop'), vw(20, 'desktop'), vw(12, 'desktop'), vw(20, 'desktop')],
        fontSize: vw(12, 'desktop')
      }
    }
  },
  price: {
    fontFamily: theme.austin
  },
  quantityLabel: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  quantityButtons: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  subscribeButton: {
    marginLeft: vw(64),
    position: 'relative',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginLeft: vw(64, 'desktop')
    },
    '& button': {
      padding: [vw(14), vw(20), vw(12), vw(20)],
      [theme.breakpoints.up('md')]: {
        padding: [vw(14, 'desktop'), vw(20, 'desktop'), vw(12, 'desktop'), vw(20, 'desktop')]
      }
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: vw(-32),
      top: 0,
      bottom: 0,
      width: 1,
      backgroundColor: 'currentColor',
      opacity: 0.3,
      [theme.breakpoints.up('md')]: {
        left: vw(-32, 'desktop')
      }
    },
    '& span': {
      fontFamily: theme.fonts.din,
      textTransform: 'uppercase',
      fontSize: vw(12),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(12, 'desktop')
      }
    }
  }
})
