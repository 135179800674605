import isIE11 from './isIE11'
import inDOM from 'dom-helpers/canUseDOM'

let scrollbarWidth

export const getScrollBarWidth = () => {
  if (!inDOM) return 0
  if (scrollbarWidth !== undefined) return scrollbarWidth

  // Create the measurement node
  var scrollDiv = document.createElement('div')

  scrollDiv.style.width = '100px'
  scrollDiv.style.height = '100px'
  scrollDiv.style.overflow = 'scroll'
  scrollDiv.style.position = 'absolute'
  scrollDiv.style.top = '-9999px'
  document.body.appendChild(scrollDiv)

  // Get the scrollbar width
  scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth

  if (isIE11) {
    scrollbarWidth = Math.max(0, scrollbarWidth - 2)
  }

  // Delete the DIV
  document.body.removeChild(scrollDiv)
  return scrollbarWidth
}
