import React, { useRef, useEffect, useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import gsap from 'gsap'
import RichText from '../../RichText'
import { vw } from '../../../helpers/vw'
import icons from './icons'
import Color from 'color'
import { ReactComponent as CaretIcon } from '../../../images/caret.svg'

const Item = ({ title, icon, children }) => {
  const classes = useStyles()
  const contentRef = useRef()
  const caretRef = useRef()
  const [open, setOpen] = useState()

  const onToggle = useCallback((index) => {
    setOpen(x => !x)
  }, [])

  useEffect(() => {
    if (contentRef.current) {
      gsap.to(contentRef.current, { height: open ? 'auto' : 0, duration: 0.25, ease: 'sine.inOut' })
      gsap.to(caretRef.current, { rotate: open ? 180 : 0, opacity: open ? 0.3 : 1, duration: 0.25, ease: 'sine.inOut' })
    }
  }, [open])

  const Icon = icon || <></>

  return (
    <li className={classes.item}>
      <button className={classes.toggleButton} onClick={onToggle}>
        {icon && <Icon className={classes.icon} />}
        <span className={classes.title}>{title}</span>
        <CaretIcon className={classes.caret} ref={caretRef} />
      </button>
      <div className={classes.content} ref={contentRef}>
        <div className={classes.innerContent}>
          {children}
        </div>
      </div>
    </li>
  )
}

export default ({ productInformation }) => {
  const classes = useStyles()
  return (
    <ul className={classes.container}>
      {productInformation.map(({ title, details, icon }) => (
        <Item key={title} title={title} icon={icons[icon]} open={false}>
          <RichText content={details.text} className={classes.copy} />
        </Item>
      ))}
    </ul>
  )
}

const useStyles = createUseStyles({
  container: {
    fontFamily: theme.fonts.din,
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  toggleButton: {
    width: '100%',
    textAlign: 'left',
    color: 'currentColor',
    border: 'none',
    margin: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    fontSize: vw(13),
    padding: [vw(16), 0],
    [theme.breakpoints.up('md')]: {
      padding: [vw(16, 'desktop'), 0],
      fontSize: vw(13, 'desktop')
    }
  },
  item: {
    borderStyle: 'solid',
    borderColor: Color(theme.colors.white).alpha(0.2).string(),
    borderWidth: [1, 0, 0, 0],
    '&:last-child': {
      borderWidth: [1, 0]
    }
  },
  content: {
    height: 0,
    overflow: 'hidden',
    fontSize: vw(14),
    lineHeight: 1.4,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop')
    }
  },
  innerContent: {
    paddingBottom: vw(16),
    [theme.breakpoints.up('md')]: {
      paddingBottom: vw(16, 'desktop')
    }
  },
  title: {
    flexGrow: 1,
    flexShrink: 1
  },
  icon: {
    flexGrow: 0,
    flexShrink: 0,
    width: vw(14),
    height: vw(13),
    marginRight: vw(8),
    [theme.breakpoints.up('md')]: {
      width: vw(14, 'desktop'),
      height: vw(13, 'desktop'),
      marginRight: vw(8, 'desktop')
    }
  },
  caret: {
    flexGrow: 0,
    flexShrink: 0,
    width: vw(16),
    height: vw(9),
    marginLeft: vw(8),
    [theme.breakpoints.up('md')]: {
      width: vw(16, 'desktop'),
      height: vw(9, 'desktop'),
      marginLeft: vw(8, 'desktop')
    }
  },
  copy: {
    fontWeight: 300
  }
})
