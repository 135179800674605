import React, { forwardRef, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme from 'style/theme'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'
import Button from '../Button'
import { resolveLink } from 'helpers/resolveLink'
import SplitWordsAnimation from 'components/SplitWordsAnimation'

const useStyles = createUseStyles({
  header: {
    color: theme.colors.white,
    background: theme.colors.primary
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: [vw(170), 0, vw(170)],
    minHeight: '100vh',
    [theme.breakpoints.up('md')]: {
      padding: [vw(190, 'desktop'), 0, vw(190, 'desktop')]
    }
  },
  title: {
    lineHeight: '0.80',
    margin: [0, span(1)],
    fontSize: vw(46),
    textTransform: 'uppercase',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      margin: [0, 'auto'],
      fontSize: vw(65, 'desktop'),
      width: span(12)
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: vw(30),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginTop: vw(60, 'desktop')
    }
  },
  button: {
    minWidth: 240,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}, { name: 'PromptBanner' })

const PromptBanner = forwardRef(({ slice, disableLazy, page, nav }, ref) => {
  const { message, link } = slice
  const classes = useStyles()

  const mainRef = useRef()
  const contentRef = useRef()

  return (
    <header ref={mainRef} className={classes.header}>
      <div className={classes.wrapper} ref={contentRef}>
        <SplitWordsAnimation as='h1' stagger='0.08' className={classes.title}>{message}</SplitWordsAnimation>
        {link && <div className={classes.buttons}>{link.map((v, i) => <Button className={classes.button} link={resolveLink(v)} key={i} />)}</div>}
      </div>
    </header>
  )
})

export default PromptBanner
