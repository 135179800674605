import inDOM from 'dom-helpers/canUseDOM'
import { ActionType } from 'redux-promise-middleware'
import {
  REHYDRATED,
  CONTENT_TYPE,
  SET_ARTICLE_TAG_FILTER,
  LOAD_MORE_ARTICLES,
  queryStringChangeCreator
} from '../../actions'
import { GetArticleListSlice, getCurrentQueryString } from '../../selectors'
import { fetchArticles } from '../../api'
import get from 'lodash/get'

export const PAGE_SIZE = 7

const getQueryStringArguments = (store) => {
  const qs = getCurrentQueryString(store.getState())
  const page = parseInt(get(qs, ['page'], 0))
  const tag = get(qs, ['tag'])
  return { page, tag }
}

export default store => next => action => {
  if (!inDOM) {
    return next(action)
  }

  const ret = next(action)
  const articleListSlice = GetArticleListSlice(store.getState())
  if (articleListSlice) {
    const slug = articleListSlice.parent_article.slug
    const { page, tag } = getQueryStringArguments(store)
    if (action.type === REHYDRATED || action.type === `${CONTENT_TYPE}_${ActionType.Fulfilled}`) {
      fetchArticles(store.dispatch, store.getState, articleListSlice.id, { tag, slug, page: 0, limit: PAGE_SIZE * (page + 1) })
    }

    if (action.type === SET_ARTICLE_TAG_FILTER) {
      store.dispatch(queryStringChangeCreator(store.getState(), { page: 0, tag: action.payload.tag }))
      fetchArticles(store.dispatch, store.getState, articleListSlice.id, { tag: action.payload.tag, slug, page: 0, limit: PAGE_SIZE })
    }

    if (action.type === LOAD_MORE_ARTICLES) {
      store.dispatch(queryStringChangeCreator(store.getState(), { page: page + 1, tag }))
      fetchArticles(store.dispatch, store.getState, articleListSlice.id, { tag, slug, page: page + 1, limit: PAGE_SIZE })
    }
  }
  return ret
}
