import React, { forwardRef, useRef, useState, useEffect, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import {
  getStockists,
  getStockistPlace
} from '../../redux/selectors'
import {
  closeStockistDialog
} from '../../redux/actions'
import StockistListItem from './StockistListItem'
import Link from '../Link'
import theme from 'style/theme'
import { vw } from 'helpers/vw'

const rad = (x) => x * Math.PI / 180

const getDistance = (p1, p2) => {
  var R = 6378137 // Earth’s mean radius in meter
  var dLat = rad(p2.lat - p1.lat)
  var dLong = rad(p2.lng - p1.lng)
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c
  return d
}

const StockistList = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const linkRef = useRef()
  const place = useSelector(getStockistPlace)
  const unfilteredStockists = useSelector(getStockists)
  const [visibleStockists, setVisibleStockists] = useState(unfilteredStockists)

  useEffect(() => {
    const lat = get(place, ['geometry', 'location', 'lat'])
    const lng = get(place, ['geometry', 'location', 'lng'])
    if (!isEmpty(place) && lat && lng) {
      const pointOfReference = { lat: lat(), lng: lng() }
      const stockistsWithDistance = unfilteredStockists.map(stockist => {
        const stockistGeo = get(stockist, ['mapLocation'])
        const distanceFrom = getDistance(pointOfReference, stockistGeo)
        return {
          ...stockist,
          distanceFrom
        }
      })
      const stockistsInRange = stockistsWithDistance.filter(
        stockist => stockist.distanceFrom < 50000 // Only showing stockist within 50km
      )
      setVisibleStockists(orderBy(stockistsInRange, ['distanceFrom'], ['asc']))
    } else {
      setVisibleStockists(unfilteredStockists)
    }
  }, [place]) // eslint-disable-line

  const handleGoToShop = useCallback((e) => {
    if (e) e.preventDefault()
    dispatch(closeStockistDialog())
    setTimeout(() => {
      linkRef.current.click()
    }, 500)
  }, [])

  return (
    <div className={classes.stockistList}>
      <Link ref={linkRef} className={classes.hiddenLink} to='/shop' />
      {isEmpty(visibleStockists) ? (
        <div className={classes.noStockists}>
          <span>Oh no! It appears the Queen has not yet graced this area with her presence. </span>
          <Link className={classes.link} to='/shop' onClick={handleGoToShop}>Try purchasing online instead.</Link>
        </div>
      ) : (
        visibleStockists.map(stockist => <StockistListItem key={stockist.id} {...stockist} />)
      )}
    </div>
  )
})

const useStyles = createUseStyles({
  stockistList: {
    flex: '1 1 auto',
    overflow: 'scroll'
  },
  noStockists: {
    fontFamily: theme.fonts.austin,
    fontSize: vw(32),
    color: theme.colors.primary,
    [theme.breakpoints.up('md')]: {
      fontSize: vw('32', 'desktop')
    }
  },
  link: {
    color: 'inherit'
  },
  hiddenLink: {
    display: 'none'
  }
}, { name: 'StockistList' })

export default StockistList
