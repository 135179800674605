import React, { forwardRef, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme from 'style/theme'
import { span } from 'helpers/span'
import { vw } from 'helpers/vw'
import Flickity from 'react-flickity-component'
import cn from 'classnames'
import hexToRgba from 'hex-to-rgba'
import { InView } from 'react-intersection-observer'
import gsap from 'gsap'

const useStyles = createUseStyles({
  sliderWrapper: {
    background: theme.colors.primary,
    color: theme.colors.white,
    textAlign: 'center',
    padding: [vw(40), span(2), vw(30)],
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: [vw(100, 'desktop'), span(2)]
    }
  },
  slider: {
    outline: 'none',
    listStyle: 'none',
    display: 'flex',
    whiteSpace: 'nowrap',
    '& .flickity-enabled': {
      position: 'relative'
    },
    '& .flickity-enabled:focus': {
      outline: 'none'
    },
    '& .flickity-viewport': {
      position: 'relative',
      width: '100%',
      height: '100%',
      outline: 'none'
    },
    '& .flickity-slider': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      outline: 'none'
    },
    '& .flickity-enabled.is-draggable': {
      'user-select': 'none'
    },
    '& .flickity-enabled.is-draggable .flickity-viewport': {
      cursor: 'grab'
    },
    '& .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down': {
      cursor: 'grabbing'
    }
  },
  title: {
    display: 'block',
    textTransform: 'uppercase',
    borderTop: `1px solid ${hexToRgba(theme.colors.white, 0.2)}`,
    lineHeight: 0.8,
    fontSize: vw(45),
    paddingTop: vw(50),
    marginBottom: vw(50),
    [theme.breakpoints.up('md')]: {
      paddingTop: vw(90, 'desktop'),
      marginBottom: vw(90, 'desktop'),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: vw(185, 'desktop')
    }
  },
  link: {
    textTransform: 'uppercase',
    fontFamily: theme.fonts.din,
    color: theme.colors.primary,
    fontSize: '10px',
    fontWeight: 'bold'
  },
  card: {
    border: `1px solid ${hexToRgba(theme.colors.white, 0.2)}`,
    width: '100%',
    marginRight: span(1, 20),
    padding: span(3, 20),
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      width: span(6, 20),
      marginRight: span(1, 20),
      padding: span(1, 20)
    }
  },
  cardTitle: {
    whiteSpace: 'normal',
    fontSize: vw(25),
    marginBottom: vw(40),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(25, 'desktop'),
      marginBottom: vw(40, 'desktop')
    }
  },
  cardSurtitle: {
    whiteSpace: 'normal',
    display: 'block',
    textTransform: 'uppercase',
    fontFamily: theme.fonts.din,
    fontSize: vw(12),
    marginBottom: vw(12),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop'),
      marginBottom: vw(12, 'desktop')
    }
  },
  cardCopy: {
    whiteSpace: 'normal',
    fontSize: vw(17),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(17, 'desktop')
    }
  },
  inViewWrapper: {
    '& li:nth-child(1), & li:nth-child(2), & li:nth-child(3), & li:nth-child(4)': {
      transform: `translateX(calc(${span(22, 19)} - 1px))`,
      [theme.breakpoints.up('md')]: {
        transform: `translateX(calc(${span(23, 6)} - 1px))`
      }
    }
  }
}, { name: 'CardList' })

const flickityOptions = {
  // freeScroll: true,
  prevNextButtons: false,
  pageDots: false,
  contain: true,
  cellAlign: 'left'
}

const ArticleSlider = forwardRef(({ slice, page, nav }, ref) => {
  const classes = useStyles()
  const { cards, title } = slice
  const mainRef = useRef()

  const handleInView = (inView, entry) => {
    if (inView) {
      const targets = Array.from(entry.target.querySelectorAll('li')).filter((v, i) => { return i < 4 })
      gsap.to(targets, 2, { ease: 'expo.out', x: 0, stagger: 0.06 })
    }
  }

  return (
    <div ref={mainRef} className={classes.sliderWrapper}>
      <h2 className={classes.title}>{title}</h2>
      <InView onChange={handleInView} rootMargin='-20% 0px' triggerOnce className={classes.inViewWrapper}>
        <Flickity static options={flickityOptions} className={cn('carousel', classes.slider)}>
          {cards && cards.map((article, i) =>
            <div className={classes.card} key={i}>
              <span className={classes.cardSurtitle}>{article.surtitle}</span>
              <h3 className={classes.cardTitle}>{article.title}</h3>
              <p className={classes.cardCopy}>{article.copy}</p>
            </div>
          )}
        </Flickity>
      </InView>
    </div>
  )
})

export default ArticleSlider
