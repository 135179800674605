import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import first from 'lodash/first'
import get from 'lodash/get'
import theme from 'style/theme'
import ResponsiveImage from '../ResponsiveImage'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'
import { getForegroundColorFromImage } from 'style/colorHelper'
import Button from '../Button'
import Link from '../Link'
import { resolveLink } from 'helpers/resolveLink'
import OvalCopy from '../OvalCopy'
import SplitWordsAnimation from 'components/SplitWordsAnimation'

const Banner = forwardRef(({
  className,
  slice: { image, title, link, size, vector_image: vector, copy }
}, ref) => {
  const lnk = first(link)
  const foreground = getForegroundColorFromImage(image)
  const classes = useStyles({ foreground, vector })

  return (
    <section className={cn(className, classes.section, size)} ref={ref}>
      <ResponsiveImage className={classes.image} {...image} parallax={{ ratio: theme.parallax.ratio * 0.9, scale: 1.1 }}>
        {vector
          ? (
            <Link link={resolveLink(lnk)}>
              <OvalCopy vector={vector} classNames={{ vector: classes.vector }} title={title} excerpt={copy} className={classes.vector} />
            </Link>
          ) : (
            <div className={classes.content}>
              {title && <SplitWordsAnimation as='h2' className={classes.title}>{title}</SplitWordsAnimation>}
              {lnk && <Button link={resolveLink(lnk)} className={classes.button} />}
            </div>
          )}
      </ResponsiveImage>
    </section>
  )
})

const useStyles = createUseStyles({
  section: {
    color: ({ foreground }) => foreground,
    '&.small': {
      padding: 0,
      [theme.breakpoints.up('md')]: {
        padding: span(1)
      }
    },
    '&.small $image picture:before': {
      paddingTop: '120%',
      [theme.breakpoints.up('md')]: {
        paddingTop: '54%'
      }
    }
  },
  image: {
    '& picture:before': {
      paddingTop: '250%',
      [theme.breakpoints.up('md')]: {
        paddingTop: '72%'
      }
    }
  },
  vector: {
    width: '150vw',
    height: ({ vector }) => vector ? `${150 / get(vector, ['aspect'])}vw` : 0,
    [theme.breakpoints.up('md')]: {
      width: '40vw',
      height: ({ vector }) => vector ? `${40 / get(vector, ['aspect'])}vw` : 0
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: vw(700, 'desktop')
    }
  },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: vw(45),
    lineHeight: 0.8,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(95, 'desktop')
    }
  },
  button: {
    marginTop: vw(34),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(46, 'desktop')
    }
  }
}, { name: 'Banner' })

export default Banner
