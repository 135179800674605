import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import isEmpty from 'lodash/isEmpty'
import theme from 'style/theme'
import { span } from 'helpers/span'
import { vw } from 'helpers/vw'
import { expo } from 'style/eases'
import { useSelector, useDispatch } from 'react-redux'
import {
  getCartCouponCopy,
  getCartCheckoutLink,
  getCartBundleCopy,
  getCartBundleLink,
  getCartBundleImage,
  showCartBundles,
  getActiveBundle,
  getCartDiscount
} from '../../redux/selectors'
import { checkoutCartActionCreator } from '../../redux/actions'
import RichText from '../RichText'
import Link from '../Link'
import { resolveLink } from '../../helpers/resolveLink'
import ResponsiveImage from '../ResponsiveImage'
import Color from 'color'
import { formatPrice } from '../../helpers/format'
import ComplimentaryItems from '../ComplimentaryItems'
import useComplimentaryItemsForCart from './useComplimentaryItemsForCart'

const CartFooter = (props) => {
  const classes = useStyles()
  const couponCopy = useSelector(getCartCouponCopy)
  const checkoutLink = useSelector(getCartCheckoutLink)

  const bundleCopy = useSelector(getCartBundleCopy)
  const bundleLink = useSelector(getCartBundleLink)
  const bundleImage = useSelector(getCartBundleImage)
  const showBundles = useSelector(showCartBundles)
  const activeBundle = useSelector(getActiveBundle)
  const discount = useSelector(getCartDiscount)
  const dispatch = useDispatch()

  const onCheckoutClick = useCallback(() => {
    dispatch(checkoutCartActionCreator())
  }, [])

  const complimentaryItems = useComplimentaryItemsForCart()

  return (
    <footer className={classes.footer}>
      <ComplimentaryItems
        items={complimentaryItems}
        showAddToCartButton
        showCartMessage
        className={classes.complimentaryItems}
        titleClassName={classes.complimentaryItemTitle}
        imageClassName={classes.complimentaryItemImage}
      />
      {!showBundles && couponCopy && !isEmpty(couponCopy.text) && (
        <div className={classes.container}>
          <RichText content={couponCopy.text} />
        </div>
      )}
      {showBundles && (
        <div className={classes.container}>
          <ResponsiveImage {...bundleImage} className={classes.image} />
          <div className={classes.bundleContent}>
            <RichText content={bundleCopy.text} />
            <Link link={resolveLink(bundleLink)} className={classes.link} />
          </div>
        </div>
      )}
      {activeBundle && !!discount && (
        <div className={classes.container}>
          <ResponsiveImage {...bundleImage} className={classes.image} />
          <div className={classes.bundleContent}>
            <p>
              {`${activeBundle.title} bundle added. You've Saved ${formatPrice(discount)}`}!
            </p>
            <span className={classes.disclaimer}>
              Discount applied automatically at checkout. Limit of 1 discount per order.
            </span>
          </div>
        </div>
      )}
      <Link link={resolveLink(checkoutLink)} className={classes.button} shouldOpenInTab onClick={onCheckoutClick} />
    </footer>
  )
}

const useStyles = createUseStyles({
  footer: {
    flex: '0 0 auto',
    padding: [0, span(1), span(1)],
    [theme.breakpoints.up('md')]: {
      padding: [0, span(1, 9), vw(60, 'desktop')]
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    border: [1, 'solid', Color(theme.colors.primary).alpha(0.3).string()],
    padding: [vw(8), vw(16)],
    marginBottom: vw(26),
    lineHeight: 1,
    [theme.breakpoints.up('md')]: {
      padding: [vw(16, 'desktop'), vw(16, 'desktop')],
      marginBottom: vw(24, 'desktop')
    },
    '& h1, h2, h3': {
      textTransform: 'uppercase',
      fontSize: vw(25),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(25, 'desktop')
      }
    },
    '& p': {
      fontFamily: theme.fonts.din,
      fontSize: vw(16),
      [theme.breakpoints.up('md')]: {
        lineHeight: 1,
        fontSize: vw(25, 'desktop'),
        fontFamily: theme.fonts.austin,
        textTransform: 'uppercase'
      }
    }
  },
  bundleContent: {
    '& p': {
      fontSize: vw(16),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(22, 'desktop')
      }
    },
    '& > p:last-child': {
      marginBottom: 0
    }
  },
  disclaimer: {
    fontFamily: theme.fonts.din,
    fontSize: vw(14),
    [theme.breakpoints.up('md')]: {
      lineHeight: 1,
      fontSize: vw(14, 'desktop')
    }
  },
  image: {
    flexShrink: 0,
    width: vw(140),
    marginRight: vw(32),
    [theme.breakpoints.up('md')]: {
      width: vw(80, 'desktop'),
      marginRight: vw(32, 'desktop')
    }
  },
  link: {
    color: theme.colors.primary,
    display: 'block',
    marginTop: vw(8),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(8, 'desktop')
    }
  },
  button: {
    display: 'block',
    textAlign: 'center',
    cursor: 'pointer',
    background: theme.colors.primary,
    color: theme.colors.beige,
    width: '100%',
    textTransform: 'uppercase',
    border: 'none',
    appearance: 'none',
    outline: 'none',
    borderRadius: vw(40),
    fontSize: vw(12),
    fontFamily: theme.fonts.din,
    textDecoration: 'none',
    padding: [vw(16), vw(20), vw(14)],
    [theme.breakpoints.up('md')]: {
      borderRadius: vw(40, 'desktop'),
      fontSize: vw(12, 'desktop'),
      transition: `background 2s ${expo.out}, transform 2s ${expo.out}`,
      padding: [vw(16, 'desktop'), vw(20, 'desktop'), vw(14, 'desktop')],
      '&:hover': {
        background: theme.colors.secondary,
        transitionDuration: '0.5s',
        transform: 'scale(1.03) translateZ(0)'
      }
    }
  },
  complimentaryItems: {
    marginBottom: vw(26),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(26, 'desktop')
    }
  },
  complimentaryItemTitle: {
    fontFamily: theme.fonts.austin,
    fontSize: vw(21),
    textTransform: 'uppercase',
    marginBottom: vw(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(4, 'desktop'),
      fontSize: vw(21, 'desktop')
    }
  },
  complimentaryItemImage: {
    width: vw(80),
    height: vw(80),
    [theme.breakpoints.up('md')]: {
      width: vw(110, 'desktop'),
      height: vw(120, 'desktop')
    }
  }
}, { name: 'CartFooter' })

export default CartFooter
