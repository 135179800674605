import React, { forwardRef, useRef, useEffect, useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import get from 'lodash/get'
import ResponsiveImage from '../../ResponsiveImage'
import theme from 'style/theme'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'
import { formatPrice } from '../../../helpers/format'
import Link from '../../Link'
import { resolveLink } from '../../../helpers/resolveLink'
import useRaf from '../../../hooks/useRaf'
import useWindowResize from '../../../hooks/useWindowResize'
import SmoothScroll from '../../SmoothScroll'
import { useSelector } from 'react-redux'
import { getProductDefaultsByHandle, getShippingInfo } from '../../../redux/selectors'
import Flickity from 'react-flickity-component'
import RadioButtons from './RadioButtons'
import Accordion from './Accordion'
import gsap from 'gsap'
import { ReactComponent as CaretIcon } from '../../../images/caret.svg'
import ComplimentaryItems from '../../ComplimentaryItems'

const flickityOptions = {
  prevNextButtons: false,
  pageDots: true,
  contain: true,
  cellAlign: 'center'
}

const ProductHero = forwardRef(({ slice, disableLazy, page, nav }, ref) => {
  const {
    title,
    subtitle,
    product,
    handle,
    image,
    hero_images: images,
    product_info: productInfo,
    product_details: productDetails,
    product_information: productInformation,
    linkToReviews,
    discount,
    delivery_info_link: deliveryLink,
    savings
  } = slice

  const shippingInfo = useSelector(getShippingInfo)
  const classes = useStyles()
  const detailsRef = useRef()
  const scrollButtonRef = useRef()
  const price = get(product, ['variants', 0, 'price', 'amount'])
  const layoutRef = useRef({ })

  const bestSeller = get(useSelector(getProductDefaultsByHandle), [get(product, ['slug']), 'best_seller_label'])

  const resize = () => { layoutRef.current = { width: window.innerWidth, height: window.innerHeight } }
  useEffect(resize, [])
  useWindowResize(resize)

  useRaf(() => {
    if (layoutRef.current.width > theme.breakpoints.values.md) {
      detailsRef.current.style.transform = `translate3d(0, ${SmoothScroll.y}px, 0)`
      scrollButtonRef.current.style.transform = `translate3d(0, ${SmoothScroll.y}px, 0)`
    } else {
      detailsRef.current.style.transform = 'translate3d(0, 0, 0)'
    }
  }, [])

  const onReviewsClick = useCallback((e) => {
    const element = document.getElementById('reviews')
    const { top } = element.getBoundingClientRect()
    gsap.to(window, { duration: 2, scrollTo: top + window.scrollY - (window.innerHeight * 0.1), ease: 'expo.out' })
    e.preventDefault()
  }, [])

  const onScrollClick = useCallback(() => {
    gsap.to(detailsRef.current, { duration: 1, scrollTo: detailsRef.current.scrollHeight - window.innerHeight })
  }, [])

  useEffect(() => {
    if (detailsRef.current.scrollHeight - window.innerHeight > 0) {
      gsap.to(scrollButtonRef.current, { autoAlpha: 1, duration: 0.5, ease: 'sine.out' })
      const scroll = () => {
        gsap.to(scrollButtonRef.current, { autoAlpha: 0, duration: 0.5, ease: 'sine.out' })
        detailsRef.current.removeEventListener('scroll', scroll)
      }
      detailsRef.current.addEventListener('scroll', scroll)
      return () => {
        detailsRef.current.removeEventListener('scroll', scroll)
      }
    }
  }, [])

  const heroImages = useMemo(() => { return images || [image] }, [images, image])
  const complimentaryItems = get(useSelector(getProductDefaultsByHandle), [handle, 'complimentary_items'])

  return (
    <header className={classes.header} id='product-hero'>
      <div className={classes.images}>
        <div className={classes.imageList}>
          {heroImages && heroImages.map((image, key) => (
            <ResponsiveImage key={key} className={classes.image} {...image} disableLazy={disableLazy} parallax={{ ratio: theme.parallax.ratio, origin: 'top' }} />
          ))}
        </div>
        <Flickity static options={flickityOptions} className={cn('carousel', classes.imageSlider)}>
          {heroImages && heroImages.map((image, key) => (
            <ResponsiveImage key={key} className={classes.image} {...image} disableLazy={disableLazy} parallax={{ ratio: theme.parallax.ratio, origin: 'top' }} />
          ))}
        </Flickity>
      </div>
      <div className={classes.productDetails}>
        <div ref={detailsRef} className={classes.productDetailsWrapper}>
          {bestSeller && <div className={classes.bestSeller}>{bestSeller}</div>}
          {title && <h1 className={classes.title}>{title}</h1>}
          {subtitle && <span className={classes.subtitle}>{subtitle}{linkToReviews && <a href='#reviews' className={classes.link} onClick={onReviewsClick}>See Reviews</a>}</span>}
          {productDetails && <p className={classes.details}>{productDetails}</p>}
          {price && (
            <span className={classes.price}>
              {formatPrice(price)}
              {discount && <span className={classes.discount}>{formatPrice(discount)}</span>}
              {savings && <span className={classes.savings}>(Save {formatPrice(savings)})</span>}
              {productInfo && <span className={classes.productInfo}>{productInfo}</span>}
              <div
                data-pp-message
                data-pp-style-layout='text'
                data-pp-style-logo-type='inline'
                data-pp-style-text-color='white'
                data-pp-amount={price}
              />
              {(shippingInfo || deliveryLink) && (
                <div className={classes.shippingRow}>
                  {shippingInfo && <p className={classes.shippingInfo}>{shippingInfo}</p>}
                  {deliveryLink && <Link className={classes.deliveryLink} link={resolveLink(deliveryLink[0])} />}
                </div>
              )}
            </span>
          )}
          <div className={classes.purchaseContainer}>
            <RadioButtons slice={slice} />
          </div>
          {productInformation && (
            <Accordion productInformation={productInformation} />
          )}
          <ComplimentaryItems items={complimentaryItems} />
        </div>
        <button className={classes.scrollButton} onClick={onScrollClick} ref={scrollButtonRef}>
          <CaretIcon className={classes.caret} />
        </button>
      </div>
    </header>
  )
})

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.colors.secondary,
    overflow: 'hidden',
    color: theme.colors.beige,
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
      flexDirection: 'row'
    }
  },
  images: {
    background: theme.colors.primary,
    flexGrow: 1,
    flexShrink: 1
  },
  imageList: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  imageSlider: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    '& .flickity-page-dots': {
      position: 'absolute',
      bottom: vw(24),
      left: '50%',
      listStyle: 'none',
      display: 'flex',
      margin: [0, vw(-4)],
      transform: 'translate(-50%, 0)'
    },
    '& .dot': {
      cursor: 'pointer',
      borderWidth: vw(1),
      borderStyle: 'solid',
      borderRadius: '50%',
      backgroundColor: 'transparent',
      width: vw(8),
      height: vw(8),
      margin: [0, vw(4)],
      position: 'relative',
      overflow: 'hidden',
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: vw(8),
        height: vw(8),
        borderRadius: '50%',
        transform: 'translate(-50%, -50%) scale(0)',
        transition: 'transform 0.25s ease-in-out',
        backgroundColor: theme.colors.white
      },
      '&.is-selected': {
        '&:after': {
          transform: 'translate(-50%, -50%) scale(1)'
        }
      }
    }
  },
  image: {
    position: 'relative',
    zIndex: 2,
    background: theme.colors.primary,
    '& picture:before': {
      paddingTop: '145%'
    },
    [theme.breakpoints.up('md')]: {
      height: '100vh',
      '& picture:before': {
        paddingTop: 0
      }
    }
  },
  productDetails: {
    alignSelf: 'flex-start',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      flex: '0 0 auto',
      width: span(9),
      height: '100vh'
    }
  },
  productDetailsWrapper: {
    padding: [span(4), span(2)],
    width: '100%',
    [theme.breakpoints.up('md')]: {
      top: 0,
      left: 0,
      position: 'absolute',
      width: 'calc(100% + 20px)',
      height: '100vh',
      overflowY: 'scroll',
      overflowX: 'hidden',
      padding: [vw(120, 'desktop'), span(4), `calc(${span(2)} + 20px)`, span(2)]
    }
  },
  bestSeller: {
    fontFamily: theme.fonts.din,
    textTransform: 'uppercase',
    fontSize: vw(10),
    marginBottom: vw(16),
    border: [1, 'solid', 'currentColor'],
    padding: [vw(10), vw(20), vw(8)],
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      padding: [vw(10, 'desktop'), vw(20, 'desktop'), vw(8, 'desktop')],
      fontSize: vw(10, 'desktop'),
      marginBottom: vw(16, 'desktop')
    }
  },
  title: {
    fontFamily: theme.fonts.austin,
    fontSize: vw(40),
    lineHeight: 1,
    textTransform: 'uppercase',
    marginBottom: vw(12),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(48, 'desktop'),
      marginBottom: vw(12, 'desktop')
    }
  },
  subtitle: {
    fontFamily: theme.fonts.austin,
    fontSize: vw(20),
    lineHeight: 1.2,
    textTransform: 'uppercase',
    display: 'block',
    marginBottom: vw(16),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(20, 'desktop'),
      marginBottom: vw(16, 'desktop')
    }
  },
  details: {
    fontSize: vw(14),
    marginBottom: vw(32),
    lineHeight: 1.4,
    fontFamily: theme.fonts.din,
    fontWeight: 300,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop'),
      marginBottom: vw(56, 'desktop')
    }
  },
  price: {
    display: 'block',
    marginBottom: vw(16),
    fontSize: vw(40),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(40, 'desktop'),
      marginBottom: vw(16, 'desktop')
    }
  },
  discount: {
    fontSize: vw(21),
    textDecorationLine: 'line-through',
    display: 'inline-block',
    marginLeft: vw(8),
    verticalAlign: 'baseline',
    lineHeight: 1.5,
    [theme.breakpoints.up('md')]: {
      marginLeft: vw(8, 'desktop'),
      fontSize: vw(21, 'desktop')
    }
  },
  savings: {
    composes: '$discount',
    textDecorationLine: 'none',
    textTransform: 'uppercase'
  },
  infoContainer: {
    marginTop: vw(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      marginTop: vw(8, 'desktop')
    }
  },
  productInfo: {
    fontFamily: theme.fonts.din,
    fontSize: vw(14),
    lineHeight: 1.2,
    whiteSpace: 'pre-line',
    marginLeft: vw(8),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop'),
      marginLeft: vw(8, 'desktop')
    }
  },
  purchaseContainer: {
    marginBottom: vw(32),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(56, 'desktop')
    }
  },
  shippingRow: {
    marginTop: vw(8),
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      marginTop: vw(8, 'desktop')
    }
  },
  shippingInfo: {
    fontSize: vw(11),
    fontWeight: 300,
    fontFamily: theme.fonts.din,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(11, 'desktop')
    }
  },
  link: {
    color: 'currentColor',
    textTransform: 'uppercase',
    fontFamily: theme.fonts.din,
    opacity: 0.5,
    fontSize: vw(10),
    display: 'inline-block',
    marginLeft: vw(8),
    [theme.breakpoints.up('md')]: {
      marginLeft: vw(8, 'desktop'),
      fontSize: vw(10, 'desktop')
    },
    '&:hover': {
      opacity: 1
    }
  },
  deliveryLink: {
    composes: ['$link'],
    flex: '0 0 auto',
    display: 'block'
  },
  scrollButton: {
    width: vw(32),
    height: vw(32),
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: vw(16),
    right: span(1),
    display: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
    opacity: 0,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      bottom: vw(16, 'desktop'),
      width: vw(32, 'desktop'),
      height: vw(32, 'desktop')
    }
  },
  caret: {
    width: vw(16),
    height: vw(9),
    [theme.breakpoints.up('md')]: {
      width: vw(16, 'desktop'),
      height: vw(9, 'desktop')
    }
  }
}, { name: 'Product Hero' })

export default ProductHero
