import React, { useRef } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import { vw } from 'helpers/vw'
import theme from 'style/theme'
import { span } from 'helpers/span'
import { ReactComponent as Star } from 'images/star.svg'
import SplitWordsAnimation from 'components/SplitWordsAnimation'
import { InView } from 'react-intersection-observer'
import gsap, { Quart } from 'gsap'

const useStyles = createUseStyles({
  blockquote: {
    textTransform: 'uppercase',
    color: 'white',
    textAlign: 'center',
    width: '100%',
    margin: [-1, 0],
    background: theme.colors.primary,
    paddingTop: vw(40),
    paddingBottom: vw(100),
    [theme.breakpoints.up('md')]: {
      paddingTop: vw(70, 'desktop'),
      paddingBottom: vw(100, 'desktop')
    },
    '&::before, &::after': {
    },
    '&::before': {
      content: '“'
    },
    '&::after': {
      content: '”'
    },
    '&.withoutAttribution': {
      paddingTop: vw(100),
      paddingBottom: vw(100),
      [theme.breakpoints.up('md')]: {
        paddingTop: vw(100, 'desktop'),
        paddingBottom: vw(100, 'desktop')
      }
    }
  },
  header: {
    fontSize: vw(14),
    marginBottom: vw(15),
    fontFamily: theme.fonts.din,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop'),
      marginBottom: vw(15, 'desktop')
    }
  },
  p: {
    lineHeight: '0.80',
    margin: [0, span(1)],
    fontSize: vw(46),
    [theme.breakpoints.up('md')]: {
      margin: [0, 'auto'],
      fontSize: vw(65, 'desktop'),
      width: span(10)
    }
  },
  footer: {
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      marginTop: vw(25),
      [theme.breakpoints.up('md')]: {
        marginTop: vw(25, 'desktop')
      },
      '& li': {
        opacity: 0,
        willChange: 'opacity'
      }
    }
  }
}, { name: 'Quote' })

const Recipe = ({ slice, page }) => {
  const ref = useRef()
  const classes = useStyles()
  const { text, attribution, rating } = slice

  const stars = []
  for (let j = 0; j < rating; j++) {
    stars.push(<li key={j}><Star viewBox='0 0 16 13' /></li>)
  }

  const handleStarsInView = (inView, entry) => {
    if (inView) {
      gsap.fromTo(ref.current.querySelectorAll('li'), 1.6, { opacity: 0, scale: 0.8 }, { ease: Quart.easeOut, opacity: 1, scale: 1, stagger: 0.07, delay: 0.25 })
    }
  }

  return (
    <blockquote className={cn(classes.blockquote, { withoutAttribution: !attribution })} ref={ref}>
      {attribution && <header className={classes.header}>{attribution}</header>}
      <SplitWordsAnimation className={classes.p}>{text}</SplitWordsAnimation>
      <footer className={classes.footer}>
        <InView as='ul' onChange={handleStarsInView} rootMargin='-20% 0px' triggerOnce>
          {stars}
        </InView>
      </footer>
    </blockquote>
  )
}

export default Recipe
