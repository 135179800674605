import React from 'react'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from './ResponsiveImage'
import theme from 'style/theme'
import { vw } from 'helpers/vw'
import cn from 'classnames'
import Link from 'components/Link'
import { quart, expo } from '../style/eases'
import Placeholder from './Placeholder'
import mapValues from 'lodash/mapValues'
import { span } from '../helpers/span'

const useStyles = createUseStyles({
  article: {
    flexGrow: 0,
    flexShrink: 0,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop')
    },
    '& figure': {
      margin: 0
    },
    '& > a': {
      color: 'currentColor',
      textDecoration: 'none',
      '&:hover': {
        '& $hero img': {
          [theme.breakpoints.up('md')]: {
            transitionDuration: '1s',
            transform: 'scale(1.05) translateZ(0)'
          }
        }
      }
    }
  },
  hero: {
    '& img': {
      [theme.breakpoints.up('md')]: {
        transition: `opacity 0.5s ${quart.out},transform 2s ${expo.out}`
      }
    }
  },
  content: {},
  learn: {
    display: 'block',
    textTransform: 'uppercase',
    fontFamily: theme.fonts.din,
    color: theme.colors.primary,
    fontSize: vw(10),
    margin: [vw(20), 0],
    transition: `opacity 0.8s ${quart.out}`,
    opacity: 0.5,
    [theme.breakpoints.up('md')]: {
      margin: [vw(20, 'desktop'), 0, vw(15, 'desktop')],
      fontSize: vw(10, 'desktop')
    }
  },
  title: {
    lineHeight: 1,
    fontSize: vw(35),
    marginTop: vw(20),
    padding: [0, `${100 / theme.grid.values.min}vw`],
    [theme.breakpoints.up('md')]: {
      fontSize: vw(35, 'desktop'),
      marginTop: vw(15, 'desktop'),
      padding: [0, `${50 / theme.grid.values.min}vw`]
    }
  },
  excerpt: {
    fontSize: vw(18),
    marginTop: vw(5),
    marginBottom: 0,
    padding: [0, `${300 / theme.grid.values.min}vw`],
    [theme.breakpoints.up('md')]: {
      fontSize: vw(18, 'desktop'),
      marginTop: vw(10, 'desktop'),
      padding: [0, `${100 / theme.grid.values.min}vw`]
    }
  }
}, { name: 'ArticlePreview' })

const useFeatureStyles = createUseStyles({
  article: {
    position: 'relative',
    '& figure': {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
          width: '50%'
        }
      }
    }
  },
  learn: {
    margin: [0, 0, vw(14)],
    [theme.breakpoints.up('md')]: {
      margin: [0, 0, vw(16, 'desktop')]
    }
  },
  content: {
    position: 'absolute',
    bottom: span(1),
    left: span(1),
    right: span(1),
    backgroundColor: theme.colors.beige,
    padding: vw(40),
    [theme.breakpoints.up('md')]: {
      padding: [0, span(1)],
      backgroundColor: 'transparent',
      position: 'static'
    }
  },
  title: {
    textTransform: 'uppercase',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(95, 'desktop')
    }
  },
  excerpt: {
    padding: 0,
    marginTop: vw(12),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(14, 'desktop')
    }
  }
})

const mergeStyle = (classNames, otherClassNames) => {
  return mapValues(classNames, (value, key) => cn(value, otherClassNames[key]))
}

const ArticlePreview = ({ classes, className, title, excerpt, hero, url, linkLabel, placeholder }) => {
  if (placeholder) {
    return (
      <article className={cn(className, classes.article)}>
        <figure>
          <Placeholder className={classes.hero} type='image' aspect={360 / 460} />
          <figcaption className={classes.content}>
            <Placeholder className={classes.learn} width='16%' align='center' />
            <Placeholder className={classes.title} width='60%' align='center' />
            <Placeholder className={classes.excerpt} type='multiline' align='center' lines={2} />
          </figcaption>
        </figure>
      </article>
    )
  }

  return (
    <article className={cn(className, classes.article)}>
      <Link to={url}>
        <figure>
          {hero && <ResponsiveImage className={classes.hero} {...hero} aspect={360 / 460} />}
          <figcaption className={classes.content}>
            {linkLabel && <span className={classes.learn}>{linkLabel}</span>}
            <h3 className={classes.title}>{title}</h3>
            {excerpt && <p className={classes.excerpt}>{excerpt}</p>}
          </figcaption>
        </figure>
      </Link>
    </article>
  )
}

const ArticlePreviewWrapper = ({ feature, ...rest }) => {
  const previewClasses = useStyles()
  const featureClasses = mergeStyle(previewClasses, useFeatureStyles())

  return <ArticlePreview classes={feature ? featureClasses : previewClasses} {...rest} />
}

export default ArticlePreviewWrapper
