import get from 'lodash/get'
import sumBy from 'lodash/sumBy'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import intersectionWith from 'lodash/intersectionWith'
import { createSelector } from 'reselect'
import {
  getCartCheckoutLabel,
  getCartShowBundles,
  getBundles
} from './content'
import groupBy from 'lodash/groupBy'
import reduce from 'lodash/reduce'
import first from 'lodash/first'

export const isCartDialogOpen = state => get(state, ['cart', 'open'])

export const getCartId = state => get(state, ['cart', 'content', 'id'])
export const isCartCompleted = state => get(state, ['cart', 'content', 'completedAt'])
export const getCartLineItemsCount = state => sumBy(getCartLineItems(state), 'quantity')
export const getCartCheckoutUrl = state => get(state, ['cart', 'content', 'checkoutUrl'])
export const getCartTotalPriceAmount = state => get(state, ['cart', 'content', 'totalPrice', 'amount'])
export const getCartSubtotalPriceAmount = state => get(state, ['cart', 'content', 'subtotalPrice', 'amount'])
export const getCartLineItemsSubtotalPrice = state => get(state, ['cart', 'content', 'lineItemsSubtotalPrice', 'amount'])
export const isCartEmpty = state => isEmpty(getCartLineItems(state))

export const getCartDiscount = createSelector(
  getCartTotalPriceAmount,
  getCartLineItemsSubtotalPrice,
  (total, lineItemsAmount) => {
    return lineItemsAmount - total
  }
)

export const getCartLineItems = createSelector(
  state => get(state, ['cart', 'content', 'lineItems']),
  (lineItems) => {
    return reduce(groupBy(lineItems, x => x.product.id), (result, items) => {
      const item = first(items)
      item.quantity = sumBy(items, item => item.quantity)
      result.push(item)
      return result
    }, [])
  }
)

export const selectActiveBundle = (bundles, lineItems) => {
  return find(bundles, bundle => {
    if (bundle.products) {
      const result = intersectionWith(bundle.products, lineItems, (product, lineItem) => {
        return product.quantity <= lineItem.quantity &&
          get(product, ['product', 'variants', 0, 'id']) === get(lineItem, ['variant', 'id'], get(lineItem, ['variantId']))
      })
      return result.length === bundle.products.length
    }
  })
}

export const getActiveBundle = createSelector(
  getBundles,
  getCartLineItems,
  selectActiveBundle
)

export const getCartCheckoutLink = createSelector(
  getCartCheckoutUrl,
  getCartCheckoutLabel,
  getActiveBundle,
  (checkoutUrl, copy, activeBundle) => {
    var url = checkoutUrl
    // This will add the discount code to the package if one is active
    if (activeBundle) {
      url += `&discount=${activeBundle.discount_code}`
    }
    return {
      type: 'externalLink',
      text: copy,
      url
    }
  }
)

export const showCartBundles = createSelector(
  getCartShowBundles,
  getBundles,
  getActiveBundle,
  (show, bundles, activeBundle) => {
    return show && !isEmpty(bundles) && !activeBundle
  }
)

export const isCartBusy = state => get(state, ['cart', 'busy'])
