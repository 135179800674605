import React, { forwardRef, useRef, useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { isCurrentBreakpointMobile, isBreakpointSet } from '../../redux/selectors'
import ResponsiveImage from '../ResponsiveImage'
import theme from '../../style/theme'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'
import Button from '../Button'
import Link from 'components/Link'
import { resolveLink } from 'helpers/resolveLink'
import SplitWordsAnimation from 'components/SplitWordsAnimation'
import NewsletterPopup from '../NewsletterPopup'
import ParallaxElement from 'components/ParallaxElement'
import useResizeObserver from 'hooks/useResizeObserver'
import { values } from 'style/breakpoints'
import { quart } from 'style/eases'
import RichText from '../RichText'

const HeroContent = forwardRef(({ title, subtitle, excerpt, link, isHome }, ref) => {
  const classes = useStyles()

  return (
    <div className={isHome ? classes.wrapperInImageHome : classes.wrapperInImage} ref={ref}>
      <SplitWordsAnimation as='h1' stagger={isHome ? 0.05 : 0.08} delay={isHome ? 0.5 : 0} className={cn(classes.title, classes.titleInImage)}>
        {title}
      </SplitWordsAnimation>
      {subtitle && (<span className={cn(classes.subtitle, classes.subtitleInImage)}>{subtitle}</span>)}
      {excerpt && (<p className={cn(classes.excerpt, classes.excerptInImage)}>{excerpt}</p>)}
      {!isEmpty(link) && (
        <div className={classes.buttons}>
          {link.map((v, i) => <Button className={classes.button} link={resolveLink(v)} transparent key={i} />)}
        </div>
      )}
    </div>
  )
})

const Hero = forwardRef(({ slice, disableLazy, page, nav }, ref) => {
  const {
    title,
    subtitle,
    copy,
    image: desktopImage,
    mobile_image: mobileImage,
    variation,
    label,
    logos,
    video_mp4: videoMp4,
    video_webm: videoWebm,
    video_mobile_mp4: videoMobileMp4,
    video_mobile_webm: videoMobileWebm,
    show_newsletter_popup: showNewsletterPopup = false
  } = slice
  const classes = useStyles()
  const isHome = page && page.id === 'home'

  const isMobile = useSelector(isCurrentBreakpointMobile)
  const breakpointSet = useSelector(isBreakpointSet)

  const image = useMemo(() => {
    if (breakpointSet) {
      return isMobile ? mobileImage : desktopImage
    } else {
      return {}
    }
  }, [breakpointSet, isMobile, mobileImage, desktopImage])

  const mainRef = useRef()
  const contentRef = useRef()
  const videoRef = useRef()
  const [videoSrc, setVideoSrc] = useState()

  const logoComponents = useMemo(() => {
    return logos ? logos.map((logo, i) => {
      const { image, url } = logo
      const Container = logo.url ? Link : ({ to, target, ...props }) => <span {...props} />
      return <Container key={i} to={url} target='_blank' className={classes.logoWrapper}><ResponsiveImage className={classes.logo} {...image} /></Container>
    }) : undefined
  }, [logos])

  useEffect(() => {
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.addEventListener('playing', () => {
        videoRef.current.style.opacity = 1
      })
    } else if (videoRef.current) {
      videoRef.current.style.opacity = 1
    }
  }, [])

  useEffect(() => {
    if (variation !== 'dark' && (!image || variation === 'bright')) {
      const current = nav.current
      current.observeBrightSlice(mainRef)
      return () => { current.unobserveBrightSlice(mainRef) }
    }
  }, [image, variation])

  useResizeObserver(mainRef, (entry) => {
    if (videoMp4 && videoMobileMp4) {
      const prevState = videoRef.current.isMobile
      videoRef.current.isMobile = entry.width < values.md
      if (prevState !== videoRef.current.isMobile) {
        if (videoMobileWebm && videoWebm && videoRef.current.canPlayType('video/webm') === 'maybe') {
          setVideoSrc(videoRef.current.isMobile ? videoMobileWebm.url : videoWebm.url)
        } else {
          setVideoSrc(videoRef.current.isMobile ? videoMobileMp4.url : videoMp4.url)
        }
      }
    }
  })

  return (
    <header ref={mainRef} className={cn(classes.header, variation)}>
      {videoMp4 ? (
        <div className={classes.image}>
          <HeroContent
            {...slice}
            ref={contentRef}
            isHome={isHome}
          />
          <ParallaxElement ratio={theme.parallax.ratio * 0.8} origin='top' className={classes.videoWrapper}>
            <video muted autoPlay playsInline ref={videoRef} src={videoSrc} />
          </ParallaxElement>
        </div>
      ) : image ? (
        <ResponsiveImage
          className={classes.image}
          {...image}
          disableLazy={disableLazy}
          disableWebp
          parallax={{ ratio: theme.parallax.ratio * 0.8, origin: 'top' }}
        >
          <HeroContent
            {...slice}
            ref={contentRef}
            isHome={isHome}
          />
        </ResponsiveImage>
      ) : (
        <div className={classes.wrapper} ref={contentRef}>
          <SplitWordsAnimation as='h1' stagger='0.08' className={classes.title}>{title}</SplitWordsAnimation>
          {subtitle && (<span className={cn(classes.subtitle)}>{subtitle}</span>)}
          {copy && copy.text && (<RichText className={classes.excerpt} content={copy.text} />)}
        </div>
      )}
      <div className={classes.heroFooter}>
        {showNewsletterPopup && (
          <div className={classes.newsletterWrapper}>
            <NewsletterPopup />
          </div>
        )}
        {logos && (
          <div className={classes.logos}>
            {label && <span className={classes.logoLabel}>{label}</span>}
            {logoComponents}
          </div>
        )}
      </div>
    </header>
  )
})

const useStyles = createUseStyles({
  header: {
    position: 'relative',
    '&.dark': {
      color: theme.colors.white,
      background: theme.colors.primary
    }
  },
  wrapper: {
    padding: [vw(170), 0, vw(60)],
    [theme.breakpoints.up('md')]: {
      padding: [vw(190, 'desktop'), 0, 0],
      fontSize: vw(185, 'desktop')
    }
  },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    lineHeight: 0.8,
    fontSize: vw(54),
    padding: [0, 0],
    [theme.breakpoints.up('md')]: {
      fontSize: vw(120, 'desktop'),
      padding: [0, span(1)]
    }
  },
  titleHome: {
    padding: [0, span(0)]
  },
  subtitle: {
    display: 'block',
    textAlign: 'center',
    fontFamily: theme.fonts.din,
    lineHeight: 0.8,
    fontSize: vw(16),
    marginTop: vw(20),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(16, 'desktop'),
      marginTop: vw(5, 'desktop')
    }
  },
  excerpt: {
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(80, 'desktop')
    },
    '& > *': {
      lineHeight: 1,
      fontSize: vw(30),
      marginTop: vw(70),
      padding: [0, span(2)],
      textAlign: 'left',
      marginBottom: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: vw(45, 'desktop'),
        marginTop: vw(70, 'desktop'),
        padding: [0, span(4)]
      }
    }
  },
  image: {
    background: theme.colors.primary,
    position: 'relative',
    zIndex: 0,
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      height: '100vh'
    },
    '& video': {
      objectFit: 'cover',
      fontFamily: '"object-fit: cover;"',
      width: '100%',
      height: '100%'
    },
    '& picture, & video': {
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
      }
    },
    '&:before': {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  },
  wrapperInImage: {
    position: 'relative',
    zIndex: 1,
    padding: ['50vh', 0, '15vh'],
    height: '100%',
    [theme.breakpoints.up('md')]: {
      padding: 0,
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  wrapperInImageHome: {
    position: 'relative',
    zIndex: 1,
    padding: ['45vh', vw(16), '30vh'],
    height: '100%',
    [theme.breakpoints.up('md')]: {
      padding: 0,
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  videoWrapper: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      width: '100%',
      top: 0,
      left: 0
    },
    '& video': {
      opacity: 0,
      transition: `opacity 1s ${quart.out}`
    }
  },
  titleInImage: {
    color: theme.colors.white,
    marginTop: 0
  },
  subtitleInImage: {
    color: theme.colors.white
  },
  excerptInImage: {
    color: theme.colors.white
  },
  buttons: {
    display: 'flex',
    marginTop: vw(30),
    padding: [0, span(2)],
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginTop: vw(60, 'desktop')
    }
  },
  button: {
    background: theme.colors.white,
    color: theme.colors.primary,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  heroFooter: {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      bottom: vw(32),
      padding: [0, span(1), 0],
      width: '100%'
    }
  },
  newsletterWrapper: {
    marginBottom: vw(40),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      position: 'absolute',
      width: 'auto',
      left: span(1),
      top: 'auto',
      bottom: vw(32, 'desktop'),
      zIndex: 1
    }
  },
  logos: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: [0, span(1), 0],
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: vw(32, 'desktop')
    }
  },
  logoLabel: {
    fontFamily: theme.fonts.din,
    fontSize: vw(14),
    lineHeight: 1.2,
    display: 'flex',
    alignItems: 'center',
    marginRight: vw(16),
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      marginRight: vw(16, 'desktop'),
      fontSize: vw(14, 'desktop')
    }
  },
  logoWrapper: {
    marginRight: vw(16),
    [theme.breakpoints.up('md')]: {
      marginRight: vw(16, 'desktop')
    },
    '&:last-child': {
      margin: 0
    }
  },
  logo: {
    height: vw(16),
    [theme.breakpoints.up('md')]: {
      height: vw(24, 'desktop')
    },
    '& picture, & img': {
      height: '100%'
    },
    '& img': {
      position: 'relative',
      width: 'auto'
    },
    '& picture:before': {
      display: 'none'
    }
  }
}, { name: 'Hero' })

export default Hero
