import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { vw } from 'helpers/vw'
import theme from '../../../style/theme'

const RadioButton = ({ onChange, className, name, label, ...rest }) => {
  const classes = useStyles()
  const checked = rest.value
  const handleClick = useCallback((e) => {
    e.target.value = !checked
    e.target.name = name
    onChange(e)
  })
  return (
    <div className={cn(classes.container, className)} onClick={handleClick}>
      <div className={cn(classes.button, { checked })}>
        <div className={cn(classes.check, { checked })} />
        <input checked={checked} name={name} type='radio' className={classes.input} onChange={onChange} />
      </div>
      <label htmlFor={name} className={classes.label}>{label}</label>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    cursor: 'pointer',
    display: 'block',
    marginLeft: vw(8),
    [theme.breakpoints.up('md')]: {
      marginLeft: vw(8, 'desktop')
    }
  },
  button: {
    flexShrink: 0,
    cursor: 'pointer',
    borderRadius: '50%',
    border: [1, 'solid', 'currentColor'],
    width: '1.5em',
    height: '1.5em',
    opacity: 0.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.checked': {
      opacity: 1
    }
  },
  check: {
    width: '80%',
    height: '80%',
    margin: 'auto',
    backgroundColor: 'currentColor',
    borderRadius: '50%',
    transform: 'scale(0)',
    transition: 'transform 0.1s ease-in-out',
    '&.checked': {
      transform: 'scale(1)'
    }
  },
  input: {
    display: 'none'
  }
}, { name: 'RadioButton' })

export default RadioButton
