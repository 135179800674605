import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import cn from 'classnames'
import Button from './Button'
import { addToCartActionCreator, openCartDialog } from '../redux/actions'
import {
  isCartBusy,
  getCartBuyButtonLabels,
  isCurrentBreakpointMobile,
  getProductDefaultsByHandle,
  getProductPopupsByHandle
} from '../redux/selectors'
import BuyButtonPopup from './BuyButtonPopup'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'

const useDelayedPopupState = () => {
  const [show, setShow] = useState(false)
  const closeTimeoutRef = useRef()
  const isMobile = useSelector(isCurrentBreakpointMobile)

  const onShow = useCallback(() => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current)
    }
    setShow(true)
  }, [])

  const onHide = useCallback(() => {
    closeTimeoutRef.current = setTimeout(() => {
      setShow(false)
    }, isMobile ? 0 : 500)
  }, [isMobile])

  return {
    show,
    onShow,
    onHide
  }
}

const BuyButton = ({ product, className, buttonClassName, text, noPopup, quantity = 1, ...rest }) => {
  const dispatch = useDispatch()
  const busy = useSelector(isCartBusy)
  const labels = useSelector(getCartBuyButtonLabels)
  const addToCartLabel = get(useSelector(getProductDefaultsByHandle), [get(product, ['slug']), 'add_to_cart_label'], labels.addToCartLabel)
  const initialText = !get(product, ['availableForSale'], false) ? labels.outOfStockLabel : (text || addToCartLabel)
  const [state, setState] = useState(initialText)
  const buttonRef = useRef()
  const clicked = useRef()
  const classes = useStyles()
  const isMobile = useSelector(isCurrentBreakpointMobile)
  const hasPopup = get(useSelector(getProductPopupsByHandle), [product.slug])

  const disable = state !== initialText || !product.availableForSale

  const { show, onShow, onHide } = useDelayedPopupState()

  const onClick = useCallback((e) => {
    if (!disable) {
      // We need to use a ref here because we have not way to know this is the request sent to the server
      clicked.current = true
      dispatch(addToCartActionCreator(get(product, ['variants', 0, 'id']), quantity))
    }
  }, [disable, product, quantity])

  useEffect(() => {
    if (clicked.current) {
      if (busy) {
        // This locks the width so that it does not change when the text changes
        setState(labels.addingToCartLabel)
      } else {
        setState(labels.addedToCartLabel)
        // Show the poopup if there is one on mobile
        if (isMobile) {
          onShow()
        }
        setState(initialText)
        clicked.current = false
        // On mobile we may have  a popup that show when they add the item to the cart
        // and this will interfere with the card dialog if we try and open it
        if (!isMobile || !hasPopup) {
          dispatch(openCartDialog())
        }
      }
    }
  }, [busy])

  return (
    <div
      className={cn(classes.container, className)}
      onMouseEnter={!isMobile ? onShow : undefined}
      onMouseLeave={!isMobile ? onHide : undefined}
    >
      <Button
        ref={buttonRef}
        onClick={onClick}
        className={cn(classes.button, buttonClassName, { disable })}
        {...rest}
      >
        <div className={cn(classes.buyText, { hide: state !== initialText })}>{initialText}</div>
        <div className={cn(classes.buyingText, { hide: state === initialText })}>{state}</div>
      </Button>
      {product && !noPopup && (
        <BuyButtonPopup handle={product.slug} show={show} onClose={onHide} />
      )}
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      position: 'relative'
    }
  },
  button: {
    position: 'relative',
    '&.disable': {
      cursor: 'default',
      opacity: 0.8
    }
  },
  buyText: {
    '&.hide': {
      visibility: 'hidden'
    }
  },
  buyingText: {
    display: 'block',
    position: 'absolute',
    left: 0,
    width: '100%',
    textAlign: 'center',
    transition: 'none',
    '&.hide': {
      display: 'none'
    }
  }
})

export default BuyButton
