import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import hexToRgba from 'hex-to-rgba'
import theme from 'style/theme'
import { vw } from 'helpers/vw'

const StockistListItem = forwardRef((props, ref) => {
  const {
    name,
    link,
    location
  } = props
  const {
    address,
    postcode,
    suburb,
    state
  } = location
  const classes = useStyles()
  return (
    <div className={classes.stockistListItem}>
      <span className={classes.stockistName}>{name}</span>
      <div className={classes.bottomContainer}>
        <span className={classes.address}>
          {address},
          <br />
          {`${suburb} ${state} ${postcode}`}
        </span>
        <a
          href={link}
          className={classes.gMapsLink}
          target='_blank'
          rel='noopener noreferrer'
          aria-label={`View ${name} on a new tab in Google Maps`}
        >
          <span>Get Directions</span>
        </a>
      </div>
    </div>
  )
})

const useStyles = createUseStyles({
  stockistListItem: {
    display: 'block',
    borderTop: [1, 'solid', theme.colors.primary],
    padding: [vw(24), 0],
    [theme.breakpoints.up('md')]: {
      borderTop: `1px solid ${hexToRgba(theme.colors.primary, 0.2)}`,
      padding: [vw(24, 'desktop'), 0]
    },
    '&:first-child': {
      borderTop: 'none'
    }
  },
  stockistName: {
    display: 'block',
    textTransform: 'uppercase',
    fontSize: vw(20),
    fontWeight: 400,
    marginBottom: vw(16),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(20, 'desktop'),
      marginBottom: vw(16, 'desktop')
    }
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  address: {
    display: 'block',
    fontFamily: theme.fonts.din,
    fontSize: vw(14),
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop')
    }
  },
  gMapsLink: {
    display: 'block',
    fontFamily: theme.fonts.din,
    textTransform: 'uppercase',
    textDecoration: 'underline',
    color: theme.colors.primary,
    opacity: 0.5,
    fontSize: vw(12),
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop')
    },
    '&:hover': {
      opacity: 1
    }
  }
}, { name: 'StockistListItem' })

export default StockistListItem
