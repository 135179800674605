import React, { forwardRef, useMemo } from 'react'
import map from 'lodash/map'
import get from 'lodash/get'
import min from 'lodash/min'
import Slider from './Slider'

const ProductSlider = forwardRef(({
  className,
  slice: { title, products, show_product_info: showInfo, show_buy_button: showBuyButton }
}, ref) => {
  const minAspect = min(map(products, product => get(product, ['image', 'aspect']) || get(product, ['product', 'images', 0, 'aspect'])))
  const items = useMemo(() => {
    return map(products, product => ({
      image: get(product, ['image']) || get(product, ['product', 'images', 0]),
      title: get(product, ['title']) || get(product, ['product', 'title']),
      price: get(product, ['product', 'variants', 0, 'price', 'amount']),
      comparePrice: get(product, ['product', 'variants', 0, 'comparePrice', 'amount']),
      info: get(product, ['link', 'reference', 'product_hero', 'product_info']),
      buyButtonText: get(product, ['link', 'reference', 'product_hero', 'buy_button_text']),
      link: product.link
    }))
  }, [products])

  return (
    <Slider title={title} items={items} minAspect={minAspect} showInfo={showInfo} showBuyButton={showBuyButton} />
  )
})

export default ProductSlider
