import React, { useCallback } from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import { getProductPopupsByHandle, isCurrentBreakpointMobile } from '../redux/selectors'
import cn from 'classnames'
import ResponsiveImage from './ResponsiveImage'
import { createUseStyles } from 'react-jss'
import { expo } from '../style/eases'
import theme from '../style/theme'
import { span } from '../helpers/span'
import { vw } from '../helpers/vw'
import canUseDOM from 'dom-helpers/canUseDOM'
import Button from './Button'

const BuyButtonPopup = ({ className, handle, show, onClose, ...rest }) => {
  const popup = useSelector(getProductPopupsByHandle)[handle]
  const classes = useStyles()
  const isMobile = useSelector(isCurrentBreakpointMobile)

  if (!popup) return null
  const { title, copy, image, close_label: closeLabel } = popup

  const onClick = useCallback((e) => {
    e.preventDefault()
  }, [])

  const renderChildren = () => (
    <div className={cn(classes.popup, className, { show })} onClick={onClick}>
      <div className={classes.container}>
        {image && <ResponsiveImage {...image} className={classes.image} />}
        <span className={classes.title}>{title}</span>
        <p className={classes.copy}>{copy}</p>
        <Button onClick={onClose} className={classes.closeButton} transparent>{closeLabel}</Button>
      </div>
    </div>
  )

  return isMobile && canUseDOM ? createPortal(renderChildren(), document.body) : renderChildren()
}

const useStyles = createUseStyles({
  popup: {
    cursor: 'default',
    position: 'fixed',
    width: '100%',
    textAlign: 'center',
    color: theme.colors.black,
    whiteSpace: 'normal',
    fontFamily: theme.fonts.austin,
    transition: `opacity 1s ${expo.out}, transform 1s ${expo.out}`,
    transform: 'translate(100%, 0)',
    zIndex: theme.zIndex.popup,
    top: 0,
    bottom: 0,
    '&.show': {
      transform: 'translate(0, 0)'
    },
    [theme.breakpoints.up('md')]: {
      opacity: 0,
      position: 'absolute',
      transitionDuration: '0.25s',
      transform: 'scale(0) translate(-50%, 0)',
      transformOrigin: '0 100%',
      width: vw(240, 'desktop'),
      top: 'unset',
      bottom: '100%',
      left: '50%',
      '&.show': {
        opacity: 1,
        transform: 'scale(1) translate(-50%, 0)'
      }
    }
  },
  container: {
    padding: span(1),
    backgroundColor: theme.colors.white,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      height: 'auto',
      padding: [vw(40, 'desktop'), vw(20, 'desktop')],
      marginBottom: vw(16, 'desktop')
    }
  },
  image: {
    marginBottom: vw(16),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(16, 'desktop')
    }
  },
  title: {
    fontSize: vw(25),
    lineHeight: 0.9,
    marginBottom: vw(8),
    textTransform: 'uppercase',
    display: 'block',
    width: '80%',
    margin: [0, 'auto', vw(8)],
    [theme.breakpoints.up('md')]: {
      margin: [0, 'auto', vw(8, 'desktop')],
      fontSize: vw(25, 'desktop')
    }
  },
  copy: {
    fontFamily: theme.fonts.austin,
    fontSize: vw(17),
    lineHeight: 1.2,
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(17, 'desktop')
    }
  },
  closeButton: {
    width: '100%',
    backgroundColor: theme.colors.primary,
    marginTop: vw(32),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
})

export default BuyButtonPopup
