import React, { useCallback, useMemo, useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import range from 'lodash/range'
import fill from 'lodash/fill'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import ArticlePreview from '../ArticlePreview'
import Link from 'components/Link'
import Button from 'components/Button'
import theme from 'style/theme'
import { span } from 'helpers/span'
import { vw } from 'helpers/vw'
import { useSelector, useDispatch } from 'react-redux'
import {
  getArticlesSelector,
  getIsArticlesBusySelector,
  getTags,
  getCurrentQueryString,
  getArticleTagFilter
} from 'redux/selectors'
import {
  setArticleTagsFilterCreator,
  loadMoreArticlesCreator
} from 'redux/actions'
import { resolveInternalLinkUrl } from 'helpers/resolveLink'
import Flickity from 'react-flickity-component'

import { PAGE_SIZE } from 'redux/middlewares/content/articles'
import { expo } from 'style/eases'
import { compact } from 'lodash'
import { format } from '../../helpers/date'

const ArticleList = ({ index, slice: { id, cta_label: ctaLabel, load_more_label: loadMoreLabel = 'Load More', tag_filters: tagFilters }, nav }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const mainRef = useRef()
  const busy = useSelector(getIsArticlesBusySelector(id))
  const articlesContent = useSelector(getArticlesSelector(id))
  const page = parseInt(get(useSelector(getCurrentQueryString), ['page'])) || 0
  const tags = isEmpty(tagFilters) ? useSelector(getTags) : tagFilters
  const filterTag = useSelector(getArticleTagFilter)
  const canLoadMore = Math.ceil(get(articlesContent, ['total'], 0) / PAGE_SIZE) > (page + 1)

  useEffect(() => {
    const current = nav.current
    current.observeBrightSlice(mainRef)
    return () => { current.unobserveBrightSlice(mainRef) }
  }, [])

  const articles = useMemo(() => {
    const articlePlaceholders = fill(range((page + 1) * PAGE_SIZE), null)
    const items = get(articlesContent, ['articles'], [])
    if (!articlesContent) {
      return articlePlaceholders
    }
    // If it is busy we want to show the loading placeholders
    if (busy) {
      return [
        ...items,
        ...articlePlaceholders.slice(items.length)
      ]
    }
    return items
  }, [articlesContent, busy])

  const loadMore = useCallback((e) => {
    dispatch(loadMoreArticlesCreator())
    e.preventDefault()
  })

  const filterArticles = (tag) => (e) => {
    dispatch(setArticleTagsFilterCreator(tag))
    e.preventDefault()
  }

  const renderArticle = (article, i) => {
    const feature = (i - 3) % 7 === 0
    const className = feature ? classes.feature : classes.article
    const tag = get(article, ['tags', 0, 'title'])
    const postDate = format(get(article, ['post_date']))
    const linkLabel = ctaLabel || compact([postDate, tag]).join(' | ')
    return (
      <ArticlePreview
        key={i}
        className={className}
        placeholder={!article}
        url={article && resolveInternalLinkUrl(article)}
        linkLabel={linkLabel}
        feature={feature}
        {...article}
      />
    )
  }

  const flickityOptions = {
    prevNextButtons: false,
    pageDots: false,
    contain: true,
    cellAlign: 'center'
  }

  return (
    <section ref={mainRef} className={cn(classes.section, { firstSlice: index === 0 })}>
      <nav className={classes.filter}>
        <Flickity static options={flickityOptions} className={cn('carousel', classes.slider)}>
          <span><Link to='/' onClick={filterArticles(undefined)} className={cn(classes.filterLink, { selected: !filterTag })}>All</Link></span>
          {tags.map(tag => (
            <span key={tag.slug}>
              <Link to='/' onClick={filterArticles(tag.slug)} className={cn(classes.filterLink, { selected: filterTag === tag.slug })}>{tag.title}</Link>
            </span>))}
        </Flickity>
      </nav>
      <div className={classes.container}>
        {articles.map(renderArticle)}
      </div>
      {canLoadMore && <Button className={classes.loadMoreButton} disabled={busy} onClick={loadMore}>{loadMoreLabel}</Button>}
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    overflow: 'hidden',
    '&.firstSlice': {
      paddingTop: vw(170),
      [theme.breakpoints.up('md')]: {
        paddingTop: vw(240, 'desktop')
      }
    }
  },
  slider: {
    outline: 'none',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      background: theme.colors.primary,
      transform: 'translate3d(0, 100%, 0)',
      padding: [0, span(1)],
      transition: `transform 1s ${expo.out} 0.3s`
    },
    '&.flickity-enabled': {
      transform: 'none'
    },
    '& .flickity-viewport': {
      [theme.breakpoints.down('md')]: {
        boxSizing: 'content-box',
        padding: [vw(20), 0]
      }
    },
    '& .flickity-slider': {
      whiteSpace: 'nowrap'
    }
  },
  filter: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      width: '100%',
      zIndex: theme.zIndex.toolbar,
      bottom: 0,
      left: 0
    },
    [theme.breakpoints.up('md')]: {
      padding: [0, span(1)]
    },
    '& a': {
      [theme.breakpoints.down('md')]: {
        color: theme.colors.white,
        '&:hover': {
          color: theme.colors.white
        }
      }
    }
  },
  filterLink: {
    fontFamily: theme.fonts.din,
    fontSize: vw(14),
    lineHeight: 2,
    textTransform: 'uppercase',
    color: theme.colors.black,
    fontWeight: 500,
    display: 'block',
    textDecoration: 'none',
    marginRight: vw(20),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop'),
      marginRight: vw(18, 'desktop')
    },
    '&:hover': {
      textDecoration: 'underline'
    },
    '&.selected': {
      textDecoration: 'underline'
    }
  },
  container: {
    padding: [vw(40), 0, 0],
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      margin: 0,
      padding: [vw(60, 'desktop'), 0]
    },
    display: 'flex',
    flexWrap: 'wrap'
  },
  feature: {
    margin: [0, 0, vw(40)],
    fontSize: vw(80),
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      margin: [0, 0, vw(40, 'desktop')],
      padding: [vw(40, 'desktop'), 0],
      fontSize: vw(80, 'desktop')
    }
  },
  article: {
    width: '100%',
    marginBottom: vw(40),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(40, 'desktop'),
      marginRight: span(1),
      width: span(6),
      '&:nth-child(7n - 6), &:nth-child(7n - 2)': {
        marginLeft: span(2)
      }
    }
  },
  loadMoreButton: {
    margin: [0, 'auto', vw(54)],
    [theme.breakpoints.up('md')]: {
      margin: [0, 'auto', vw(80, 'desktop')]
    }
  }
}, { name: 'ArticleList' })

export default ArticleList
