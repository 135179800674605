import { REHYDRATED, setCookieAccepted, SET_COOKIE_ACCEPTED } from '../actions'

export default store => next => action => {
  const ret = next(action)
  switch (action.type) {
    case REHYDRATED:
      store.dispatch(setCookieAccepted(!!window.localStorage.getItem('cookiesAccepted')))
      break
    case SET_COOKIE_ACCEPTED: {
      const accepted = action.payload
      if (accepted && !window.localStorage.getItem('cookiesAccepted')) {
        window.localStorage.setItem('cookiesAccepted', true)
      }
      break
    }
  }
  return ret
}
