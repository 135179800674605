import inDOM from 'dom-helpers/canUseDOM'
import { NOT_FOUND } from 'redux-first-router'
import { showLoading } from 'react-redux-loading-bar'
import qs from 'query-string'
import { resolveInternalLinkUrl } from '../helpers/resolveLink'
import {
  getCurrentRoutePath,
  getGlobalContent,
  getPageContent,
  isPageNotFound,
  isContentError404,
  getCurrentQueryString,
  isPreviewRoute,
  isPreviewMode,
  isContentBusy
} from './selectors'
import {
  fetchGlobalContent,
  fetchPageContent,
  fetchPageNotFoundContent
} from './api'
import {
  ROUTE_CONTENT,
  ROUTE_EMPTY,
  redirectCreator,
  requestPreviewSessionCreator
} from './actions'

const contentThunk = async (dispatch, getState) => {
  const path = getCurrentRoutePath(getState())
  const notFound = async () => {
    dispatch({ type: NOT_FOUND })
    return fetchPageNotFoundContent(dispatch, getState)
  }

  if (isPageNotFound(getState())) {
    return
  }

  const tasks = []
  if (!getGlobalContent(getState())) {
    tasks.push(fetchGlobalContent(dispatch, getState))
  }
  tasks.push(fetchPageContent(dispatch, getState, path))

  try {
    await Promise.all(tasks)
    if (isContentBusy(getState())) {
      // This should only happen when content arrived out of sequence (see: the race middleware).
      // Another request is already in progress, so don’t do anything further for this one.
      return
    }

    const content = getPageContent(getState())
    if (!content || !content.type || isContentError404(getState())) {
      await notFound()
    } else {
      const currentRoutePath = path
      const canonicalPath = resolveInternalLinkUrl(content)

      if (isPreviewMode(getState())) {
        if (!inDOM) {
          dispatch(requestPreviewSessionCreator())
        } else {
          // This will ensure any page refresh will still be in preview mode
          if (!isPreviewRoute(getState())) {
            window.history.replaceState(null, null, `/preview${path}`)
          }
        }
      } else {
        if (currentRoutePath !== canonicalPath) {
          const query = qs.stringify(getCurrentQueryString(getState()))
          dispatch(redirectCreator(getState(), canonicalPath + (query ? `?${query}` : '')))
        } else if (!inDOM) {
          // show the loading bar on the server, and we’ll hide it after hydration on the client
          dispatch(showLoading('page'))
        }
      }
    }
  } catch (error) {
    console.error(error)
    await notFound()
  }
}

export default {
  [ROUTE_CONTENT]: {
    path: '*',
    toPath: segment => segment,
    thunk: contentThunk
  },
  [ROUTE_EMPTY]: {
    path: '*',
    toPath: segment => segment
  }
}
