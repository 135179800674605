import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Helmet } from 'react-helmet-async'
import theme from '../style/theme'
import { useSelector } from 'react-redux'
import { getPageMetaData, getPageHeadTitle } from '../redux/selectors'
import PageLoadingBar from './PageLoadingBar'
import Page from './Page'
import CartDialog from './Cart/CartDialog'
import StockistsDialog from './Stockists/StockistsDialog'
import gsap from 'gsap'

function App () {
  const classes = useStyles()
  const meta = useSelector(getPageMetaData)
  const headTitle = useSelector(getPageHeadTitle)

  gsap.config({ force3D: true })

  useEffect(() => {
    const ReactPixel = require('react-facebook-pixel')
    const advancedMatching = {}
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: true
    }
    ReactPixel.default.init(process.env.REACT_APP_FB_PIXEL, advancedMatching, options)
    ReactPixel.default.pageView()
  }, [])

  return (
    <>
      <Helmet>
        <title>{headTitle}</title>
        {meta && (
          <>
            <meta name='title' content={meta.metaTitle} />
            {meta.metaDescription && <meta name='description' content={meta.metaDescription} />}
            {meta.metaKeywords && <meta name='keywords' content={meta.metaKeywords} />}
            {meta.ogUrl && <meta property='og:url' content={meta.ogUrl} />}
            <meta property='og:site_name' content={meta.siteName} />
            <meta property='og:title' content={meta.metaTitle} />
            {meta.metaDescription && <meta property='og:description' content={meta.metaDescription} />}
            {meta.metaImage && [
              <meta property='og:image' content={meta.metaImage.url} key={0} />,
              <meta property='og:image:width' content={meta.metaImage.width} key={1} />,
              <meta property='og:image:height' content={meta.metaImage.height} key={2} />
            ]}
            {meta.metaImage && <meta name='twitter:card' content={meta.twitterCardType} />}
            {meta.siteName && <meta name='twitter:site' content={meta.siteName} />}
            <meta name='twitter:title' content={headTitle} />
            {meta.metaDescription && <meta name='twitter:description' content={meta.metaDescription} />}
            {meta.metaImage && <meta name='twitter:image' content={meta.metaImage.url} />}
            <meta name='google-site-verification' content='N1Bnqomvnl8F3hbRUmduop7NtNScoqGph0ODu3SE700' />
            <link rel='canonical' href={meta.canonicalUrl} />
          </>
        )}
      </Helmet>
      <PageLoadingBar className={classes.loadingBar} />
      <Page className={classes.page} />
      <CartDialog />
      <StockistsDialog />
    </>
  )
}

const useStyles = createUseStyles({
  ...theme.global,
  page: {
    overflow: 'hidden'
  }

}, { name: 'App' })

export default App
