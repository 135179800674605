import composeReduxMiddlewares from '../../../helpers/composeReduxMiddlewares'
import loadingBarMiddleware from './loadingBar'
import raceMiddleware from './race'
import articlesMiddleware from './articles'

export default composeReduxMiddlewares(
  loadingBarMiddleware,
  raceMiddleware,
  articlesMiddleware
)
