import React, { forwardRef, useImperativeHandle, useRef, useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import Link from 'components/Link'
import theme from 'style/theme'
import cn from 'classnames'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'
import { quart, expo, back } from 'style/eases'
import useResizeObserver from 'hooks/useResizeObserver'
import 'intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleCartDialog,
  toggleStockistDialog,
  openMenu,
  closeMenu
} from 'redux/actions'
import {
  isCartDialogOpen,
  getHeaderContent,
  isMenuOpen,
  getCartLineItemsCount
} from 'redux/selectors'
import { resolveLink } from 'helpers/resolveLink'
import { ReactComponent as Logo } from 'images/header-logo.svg'
import { ReactComponent as Cart } from 'images/cart.svg'
import { ReactComponent as Close } from 'images/close.svg'
import { ReactComponent as Instagram } from 'images/instagram.svg'
import { ReactComponent as Burger } from 'images/burger.svg'
import { quad } from '../style/eases'
import SmoothScroll from './SmoothScroll'
import useRaf from '../hooks/useRaf'
import getHeight from 'dom-helpers/height'
import detectIt from 'detect-it'
import gsap from 'gsap'

let resized = false

const Nav = forwardRef((props, ref) => {
  const classes = useStyles()
  const navRef = useRef()
  const logoMobile = useRef()
  const logoRef = useRef()
  const leftRef = useRef()
  const cartBtnRef = useRef()
  const stockistBtnRef = useRef()
  const burgerBtnRef = useRef()

  let height = 0
  let logoTop = 0

  const brightElements = useRef([])
  const observer = useRef()
  const [isDark, setIsDark] = useState(false)
  const dispatch = useDispatch()
  const isCartOpen = useSelector(isCartDialogOpen)
  const isOpened = useSelector(isMenuOpen)
  const lineItems = useSelector(getCartLineItemsCount)
  const {
    shop_link: shopLink,
    blog_link: blogLink,
    recipes_link: recipesLink,
    research_link: researchLink,
    nutrafruit_link: nutrafruitLink,
    instagram_link: instagramLink
  } = useSelector(getHeaderContent)

  const onIntersect = (entries, observer) => {
    for (let i = 0, l = entries.length; i < l; i++) {
      const entry = entries[i]

      if (entry.isIntersecting) {
        setIsDark(entry.isIntersecting)
        break
      } else { setIsDark(entry.isIntersecting) }
    }
  }

  useResizeObserver(navRef, (entry) => {
    height = entry.height
    logoTop = navRef.current.offsetTop

    if (!resized) {
      resized = true
      gsap.fromTo(navRef.current, 1.5, { y: -(logoRef.current.offsetHeight + logoTop) + 'px' }, {
        y: 0,
        clearProps: 'transform',
        ease: 'expo.out',
        onStart: () => {
          navRef.current.style.visibility = 'inherit'
        }
      })
      gsap.fromTo(logoMobile.current, 1.5, { y: '-110%' }, {
        y: '0%',
        clearProps: 'transform',
        ease: 'expo.out',
        onStart: () => {
          logoMobile.current.style.visibility = 'inherit'
        }
      })

      cartBtnRef.current.style.transition = 'none'
      stockistBtnRef.current.style.transition = 'none'
      burgerBtnRef.current.style.transition = 'none'
      gsap.fromTo([
        leftRef.current,
        burgerBtnRef.current,
        cartBtnRef.current
      ], 1.5, { y: '-100px' }, {
        y: 0,
        clearProps: 'transform',
        ease: 'expo.out',
        delay: '0.1',
        onComplete: () => {
          burgerBtnRef.current.style.transitionDuration = ''
          cartBtnRef.current.style.transitionDuration = ''
        }
      })
    } else {
      navRef.current.style.visibility = 'inherit'
      logoMobile.current.style.visibility = 'inherit'
    }

    if (observer.current) observer.current.disconnect()
    observer.current = new window.IntersectionObserver(onIntersect,
      {
        root: null,
        rootMargin: `${Math.round(-logoTop - height / 2)}px 0px ${Math.round(logoTop + height / 2 - window.innerHeight + 1)}px 0px`,
        threshold: 0
      })

    brightElements.current.forEach(e => observer.current.observe(e))
  })

  useImperativeHandle(ref, () => ({
    observeBrightSlice (ref) {
      if (observer.current) observer.current.observe(ref.current)
      brightElements.current.push(ref.current)
    },
    unobserveBrightSlice (ref) {
      if (observer.current) observer.current.unobserve(ref.current)
      const index = brightElements.current.indexOf(ref.current)
      if (index !== -1) brightElements.current.splice(index, 1)
    }
  }))

  const handleOpen = useCallback(() => {
    dispatch(openMenu())
  }, [])

  const handleClose = useCallback(() => {
    dispatch(closeMenu())
  }, [])

  const handleOpenMiniCart = useCallback(() => {
    dispatch(toggleCartDialog())
  }, [])

  const handleOpenStockistsPanel = useCallback(() => {
    dispatch(toggleStockistDialog())
  }, [])

  useRaf(() => {
    const yOffset = getHeight(logoRef.current) * (window.innerWidth < theme.breakpoints.values.md ? 0.05 : 0.3)
    const isTouch = detectIt.primaryInput === 'touch'
    if (!isTouch) logoRef.current.style.transform = `translate3d(-50%, ${-SmoothScroll.y - yOffset}px, 0)`
  })

  return (
    <header className={cn(classes.header, isDark && classes.navIsDark)}>
      <nav ref={navRef} className={classes.nav}>
        <button className={cn(classes.button, classes.buttonBurger)} onClick={handleOpen} ref={burgerBtnRef} aria-label='Open Menu'>
          <Burger viewBox='0 0 13 7' />
        </button>
        <span className={cn(classes.panel, isOpened && classes.panelIsOpened)} ref={leftRef}>
          {shopLink && <Link className={classes.link} link={resolveLink(shopLink[0])} />}
          {recipesLink && <Link className={classes.link} link={resolveLink(recipesLink[0])} />}
          <button className={cn(classes.link)} onClick={handleOpenStockistsPanel} aria-label='Show list of stockists' ref={stockistBtnRef}>
            Find a Store
          </button>
          {instagramLink && <Link className={cn(classes.link, classes.instagram)} to={instagramLink.url} target='_blank'><Instagram /> {instagramLink.text}</Link>}
          <div className={classes.right}>
            {researchLink && <Link className={classes.link} link={resolveLink(researchLink[0])} />}
            {blogLink && <Link className={classes.link} link={resolveLink(blogLink[0])} />}
            {nutrafruitLink && <Link className={classes.link} link={resolveLink(nutrafruitLink[0])} />}
          </div>
          <button className={cn(classes.button, classes.buttonClose)} onClick={handleClose} aria-label='Close Menu'>
            <Close viewBox='0 0 12 12' />
          </button>
        </span>
        <Link to='/' className={classes.logo} ref={logoRef}>
          <Logo title='Queen Garnet' viewBox='0 0 213 110' />
        </Link>
        <button className={cn(classes.button)} onClick={handleOpenMiniCart} aria-label='Open Cart' ref={cartBtnRef}>
          {isCartOpen ? <Close viewBox='0 0 11 11' /> : <Cart viewBox='0 0 13 11' />}
          <span className={cn(classes.lineItems, { show: lineItems, small: lineItems >= 10 })}>{lineItems}</span>
        </button>
      </nav>
      <Link to='/' className={classes.logoMobile} ref={logoMobile}>
        <Logo title='Queen Garnet' viewBox='0 0 213 110' />
      </Link>
    </header>
  )
})

const useStyles = createUseStyles({
  header: {
    zIndex: theme.zIndex.nav,
    height: 0,
    top: 0,
    position: 'absolute',
    width: '100%',
    '& svg path, & svg rect': {
      fill: theme.colors.white,
      transition: `fill 0.5s ${quart.out}`
    }
  },
  nav: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.colors.white,
    zIndex: theme.zIndex.nav,
    top: 0,
    width: '100%',
    padding: [0, span(1)],
    marginTop: span(1),
    visibility: 'hidden',
    [theme.breakpoints.up('md')]: {
      marginTop: vw(60, 'desktop')
    }
  },
  navIsDark: {
    '& nav': {
      color: theme.colors.primary
    },
    '& svg path, & svg rect': {
      fill: theme.colors.primary
    },
    '& $instagram svg path': {
      fill: theme.colors.white,
      [theme.breakpoints.up('md')]: {
        fill: theme.colors.primary
      }
    },
    '& button': {
      // background: theme.colors.white
      '& svg path, & svg rect': {
        fill: theme.colors.white
      }
    }
  },
  logo: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, -5%)',
    width: vw(213),
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: vw(213, 'desktop'),
      transform: 'translate(-50%, -30%)'
    },
    '& svg': {
      width: '100%'
    }
  },
  logoMobile: {
    composes: '$logo',
    top: vw(10),
    zIndex: 1,
    marginTop: span(1),
    display: 'block',
    width: vw(213),
    visibility: 'hidden',
    transform: 'translate(-50%, 0)',
    '&>svg': {
      transform: 'translateY(-10%)'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
      marginTop: vw(60, 'desktop')
    }
  },
  link: {
    fontFamily: theme.fonts.din,
    color: 'currentColor',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '12px',
    transition: `color 0.5s ${quart.out}`,
    textDecoration: 'underline',
    '&:hover, &:visited': {
      color: 'currentColor'
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: vw(30),
      fontSize: vw(45),
      fontWeight: 'normal',
      textDecoration: 'none',
      fontFamily: theme.fonts.austin,
      color: theme.colors.white,
      '&:first-child': {
        marginTop: 'auto'
      },
      '&:last-child': {
        marginBottom: 0
      },
      '&:hover, &:visited': {
        color: theme.colors.white
      }
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '10px'
    }
  },
  panel: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100%',
      position: 'fixed',
      zIndex: 1,
      top: 0,
      left: 0,
      background: theme.colors.primary,
      transform: 'translate3d(-100%,0,0)',
      transition: `transform 1s ${quart.inOut}`
    }
  },
  panelIsOpened: {
    [theme.breakpoints.down('md')]: {
      transition: `transform 1s ${expo.out}`,
      transform: 'translate3d(0,0,0)'
    }
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      flexDirection: 'column',
      marginLeft: 0
    }
  },
  instagram: {
    [theme.breakpoints.down('md')]: {
      order: 10,
      marginTop: 'auto',
      fontFamily: theme.fonts.din,
      fontSize: vw(16),
      display: 'flex',
      alignItems: 'center',
      textTransform: 'none',
      '& svg': {
        marginRight: vw(8)
      }
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 0
    }
  },
  button: {
    position: 'relative',
    cursor: 'pointer',
    appearance: 'none',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '34px',
    height: '34px',
    border: 'none',
    background: theme.colors.secondary,
    transition: `background 2s ${expo.out}, transform 2s ${expo.out}`,
    '&:hover': {
      // background: theme.colors.primary,
      transitionDuration: '0.5s',
      transform: 'scale(1.1) translateZ(0)'
    }
  },
  buttonBurger: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  buttonClose: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: [span(1), 0, 0, span(1)],
    color: theme.colors.white,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  lineItems: {
    position: 'absolute',
    top: '-0.6em',
    right: '-0.6em',
    paddingTop: '0.25em',
    color: theme.colors.primary,
    backgroundColor: theme.colors.white,
    borderRadius: '50%',
    transform: 'scale(0)',
    width: '1.5em',
    height: '1.5em',
    fontSize: 12,
    transition: `transform 0.25s ${quad.out}`,
    '&.show': {
      transition: `transform 0.5s ${back.out}`,
      transform: 'scale(1)'
    },
    '&.small': {
      paddingTop: '0.35em',
      fontSize: 10,
      width: '1.8em',
      height: '1.8em'
    }
  }
}, { name: 'Nav' })

export default Nav
