import * as breakpoints from './breakpoints'
import * as grid from './grid'
import * as fonts from './fonts'
import color from 'color'

const parallax = {
  ratio: 0.8,
  scale: 1.2
}

const colors = {
  primary: '#43283A',
  secondary: '#381F30',
  tercary: '#A3919D',
  black: '#1a1819',
  white: '#fff',
  beige: '#F8F7F3'
}

const globalStyles = {
  '@global': {
    '*, *::before, *::after': {
      boxSizing: 'border-box',
      '-webkit-tap-highlight-color': 'transparent'
    },
    body: {
      // ...grid.generateAll(),
      backgroundColor: colors.beige,
      color: colors.primary,
      fontFamily: fonts.austin,
      '-webkitFontSmoothing': 'antialiased',
      '-mozOsxFontSmoothing': 'grayscale'
    },
    'h1,h2,h3,h4,h5': {
      fontWeight: 'normal',
      margin: [0]
    },
    p: {
      margin: [0, 0, '0.6em']
    },
    '::placeholder': {
      color: colors.primary,
      opacity: 0.6
    },
    '::selection': {
      backgroundColor: color(colors.secondary).lighten(4).hex()
    },
    button: {
      userSelect: 'none',
      cursor: 'pointer',
      appearance: 'none',
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: 0,
      margin: 0
    },
    '.flickity-enabled': {
      position: 'relative'
    },
    '.flickity-enabled:focus': {
      outline: 'none'
    },
    '.flickity-viewport': {
      position: 'relative',
      width: '100%',
      height: '100%',
      outline: 'none'
    },
    '.flickity-slider': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      outline: 'none'
    },
    '.flickity-enabled.is-draggable': {
      'user-select': 'none'
    },
    '&.flickity-enabled.is-draggable .flickity-viewport': {
      cursor: 'grab'
    },
    '&.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down': {
      cursor: 'grabbing'
    },
    '.srOnly': {
      border: '0 !important',
      clip: 'rect(1px, 1px, 1px, 1px) !important',
      clipPath: 'inset(50%) !important',
      height: '1px !important',
      margin: '-1px !important',
      overflow: 'hidden !important',
      padding: '0 !important',
      position: 'absolute !important',
      width: '1px !important',
      whiteSpace: 'nowrap !important'
    }
  }
}

export default {
  global: globalStyles,
  breakpoints,
  grid,
  colors,
  fonts,
  parallax,
  zIndex: {
    // Keep all zIndexes here in one place so it’s easy to see what order things are in
    toolbar: 99,
    nav: 100,
    cart: 101,
    popup: 102,
    pageLoadingBar: 103
  }
}
