import inDOM from 'dom-helpers/canUseDOM'
import SessionStorage from 'redux-first-router-restore-scroll/dist/SessionStorage'
import { BEGIN_ROUTE_EXIT_TRANSITION, ROUTE_EXIT_TRANSITION_ENDED } from '../actions'

class PauseableSessionStorage extends SessionStorage {
  paused = false

  pause () {
    this.paused = true
  }

  resume () {
    this.paused = false
  }

  save (location, key, value) {
    if (!this.paused) {
      return super.save(location, key, value)
    }
  }
}

let storage

export function getStateStorage () {
  if (!storage) {
    storage = new PauseableSessionStorage()
  }
  return storage
}

export default store => next => action => {
  if (inDOM) {
    if (action.type === BEGIN_ROUTE_EXIT_TRANSITION) {
      getStateStorage().pause()
    } else if (action.type === ROUTE_EXIT_TRANSITION_ENDED) {
      getStateStorage().resume()
    }
  }
  return next(action)
}
