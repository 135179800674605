import React, { forwardRef, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import RichText from '../RichText'
import theme from 'style/theme'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'

const useStyles = createUseStyles({
  content: {
    padding: [vw(30), 0],
    margin: [0, 'auto'],
    [theme.breakpoints.up('md')]: {
      padding: [vw(100, 'desktop'), 0],
      margin: [0, 'auto']
    },
    '& h2, & h3': {
      lineHeight: 0.86,
      textTransform: 'uppercase',
      fontSize: vw(12),
      padding: [0, span(3)],
      marginBottom: vw(30),
      fontWeight: 'bold',
      fontFamily: theme.fonts.din,
      [theme.breakpoints.up('md')]: {
        padding: [0, span(4)],
        marginBottom: vw(20, 'desktop'),
        fontSize: vw(12, 'desktop')
      }
    },
    '& blockquote': {
      lineHeight: 0.86,
      textTransform: 'uppercase',
      fontSize: vw(70),
      padding: [0, span(3)],
      margin: [vw(30), 0],
      fontWeight: 'normal',
      [theme.breakpoints.up('md')]: {
        padding: [0, span(3), 0, span(2)],
        margin: [vw(120, 'desktop'), 0, vw(80, 'desktop')],
        fontSize: vw(145, 'desktop')
      }
    },
    '& p, & ul': {
      lineHeight: 1.1,
      fontSize: vw(30),
      padding: [0, span(2), 0, span(2)],
      marginBottom: vw(30),
      [theme.breakpoints.up('md')]: {
        padding: [0, span(4)],
        marginBottom: vw(80, 'desktop'),
        fontSize: vw(45, 'desktop')
      }
    },
    '& ul': {
      listStyle: 'none'
    },
    '& ul > li': {
      marginBottom: vw(36),
      fontSize: vw(30),
      lineHeight: 1.2,
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(57),
        fontSize: vw(36)
      },
      '&:before': {
        content: '"-"',
        position: 'absolute',
        left: '-0.75em'
      },
      [theme.breakpoints.up('md')]: {
        lineHeight: 1.4,
        marginBottom: vw(57, 'desktop'),
        fontSize: vw(36, 'desktop')
      }
    },
    '& ul.tiles': {
      listStyleType: 'none',
      fontFamily: theme.fonts.din,
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      },
      '& > li': {
        position: 'relative',
        paddingBottom: vw(16),
        marginBottom: vw(16),
        width: '100%',
        '&:before': {
          display: 'none'
        },
        [theme.breakpoints.up('md')]: {
          width: '33%',
          paddingBottom: vw(32, 'desktop'),
          marginBottom: 0
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          height: 1,
          left: 0,
          right: 0,
          opacity: 0.2,
          backgroundColor: 'currentColor'
        }
      },
      '& > li:not(:last-child)': {
        marginRight: span(1)
      },
      '& .title': {
        fontSize: vw(10),
        display: 'block',
        marginBottom: vw(16),
        textTransform: 'uppercase',
        [theme.breakpoints.up('md')]: {
          fontSize: vw(13, 'desktop'),
          marginBottom: vw(16, 'desktop')
        }
      },
      '& .copy': {
        fontSize: vw(12),
        display: 'block',
        lineHeight: 1.3,
        opacity: 0.5,
        maxWidth: vw(240),
        whiteSpace: 'pre-wrap',
        [theme.breakpoints.up('md')]: {
          maxWidth: 'none',
          fontSize: vw(13, 'desktop')
        }
      }
    },
    '& a': {
      color: theme.colors.secondary,
      textDecoration: 'none',
      fontStyle: 'italic'
    },
    '&.dark': {
      color: 'white',
      background: theme.colors.primary,
      '& a': {
        color: 'white'
      }
    }
  }
}, { name: 'RichTextSlice' })

const RichTextSlice = forwardRef(({ className, slice, tag = 'section', serializers, page, nav }, ref) => {
  const classes = useStyles()
  const { text, variation } = slice
  const mainRef = useRef()

  if (!variation || variation === 'light') {
    useEffect(() => {
      const current = nav.current
      current.observeBrightSlice(mainRef)
      return () => { current.unobserveBrightSlice(mainRef) }
    }, [])
  }

  return (
    <RichText ref={mainRef} className={cn(classes.content, className, variation)} content={text} tag={tag} serializers={serializers} />
  )
})

export default RichTextSlice
