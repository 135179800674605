const initialState = {
  api: process.env.CLIENT_API_URL
}

export default function config (state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}
