import React, { forwardRef, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import isEmpty from 'lodash/isEmpty'
import ResponsiveImage from '../ResponsiveImage'
import theme from '../../style/theme'
import { span } from '../../helpers/span'
import { vw } from '../../helpers/vw'
import Button from '../Button'
import { resolveLink } from 'helpers/resolveLink'
import RichText from '../RichText'

const useStyles = createUseStyles({
  wrapper: {
    overflow: 'hidden',
    background: theme.colors.primary,
    padding: [vw(130), 0, vw(40), 0],
    [theme.breakpoints.up('md')]: {
      padding: [vw(200, 'desktop'), 0, vw(100, 'desktop'), 0]
    }
  },
  figure: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  figcaption: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: [0, span(2)],
    [theme.breakpoints.up('md')]: {
      padding: 0,
      marginLeft: span(1),
      width: span(7)
    }
  },
  title: {
    color: theme.colors.white,
    textTransform: 'uppercase',
    lineHeight: 1,
    fontSize: vw(40),
    marginBottom: vw(30),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(40, 'desktop'),
      marginBottom: vw(40, 'desktop'),
      width: span(5, 7)
    }
  },
  copy: {
    '& p': {
      color: theme.colors.white,
      lineHeight: 1,
      fontSize: vw(30),
      marginBottom: vw(30),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(30, 'desktop'),
        marginBottom: vw(30, 'desktop')
      },
      '&:last-of-type': {
        marginBottom: 0
      }
    }
  },
  imageWrapper: {
    position: 'relative',
    width: span(26),
    marginBottom: vw(35),
    [theme.breakpoints.up('md')]: {
      width: span(10),
      marginLeft: span(1),
      marginBottom: 0
    },
    '&::after': {
      content: '""',
      display: 'block',
      background: theme.colors.secondary,
      borderRadius: '50%',
      width: '100%',
      paddingBottom: '100%'
    }
  },
  image: {
    bottom: 0,
    left: '5%',
    width: '90%',
    position: 'absolute'
  },
  button: {
    flexGrow: 0,
    flexShrink: 0,
    marginTop: vw(24),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: vw(30, 'desktop')
    }
  }
}, { name: 'DiptychDark' })

const DiptychDark = forwardRef(({ slice, page, nav }, ref) => {
  const { title, image, copy, link } = slice
  const classes = useStyles()
  const mainRef = useRef()

  return (
    <section ref={mainRef} className={classes.wrapper}>
      <figure className={classes.figure}>
        <div className={classes.imageWrapper}>
          <ResponsiveImage className={classes.image} {...image} />
        </div>
        <figcaption className={classes.figcaption}>
          <h2 className={classes.title}>{title}</h2>
          {copy && copy.text && <RichText content={copy.text} className={classes.copy} />}
          {!isEmpty(link) && <Button className={classes.button} link={resolveLink(link[0])} transparent />}
        </figcaption>
      </figure>
    </section>
  )
})

export default DiptychDark
