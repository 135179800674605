import React from 'react'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import { LoadingBar as BaseLoadingBar } from 'react-redux-loading-bar'
import cn from 'classnames'
import theme from '../style/theme'
import { isBreakpointSet } from '../redux/selectors'

// The LoadingBar component doesn’t render any children. It’s not ideal but we can make it render children by
// overriding its render() method via inheritance.
class LoadingBarWithChildren extends BaseLoadingBar {
  constructor (props, context) {
    super(props, context)
    if (!props.hydrated && props.loading > 0) {
      // Kick start the progress bar on the server so it will be shown before/during hydration in the browser
      this.state = {
        percent: 20,
        status: 'starting'
      }
    }
  }

  render () {
    const { status } = this.state
    const { className, loadingBarClassName, children } = this.props
    if (status === 'hidden') {
      return <div />
    }

    const { opacity, ...style } = this.buildStyle()
    return (
      <div style={{ opacity }} className={className}>
        <div style={style} className={loadingBarClassName} />
        <div>{children}</div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.loadingBar.page, // only interested in scope 'page'
  hydrated: isBreakpointSet(state)
})

const LoadingBar = connect(mapStateToProps)(LoadingBarWithChildren)

function PageLoadingBar ({ className, ...props }) {
  const classes = useStyles()
  return (
    <LoadingBar className={cn(className, classes.container)} loadingBarClassName={classes.loadingBar} {...props} />
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: 3,
    pointerEvents: 'none',
    backgroundColor: theme.colors.background,
    zIndex: theme.zIndex.pageLoadingBar
  },
  loadingBar: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.primary
  }
}, { name: 'PageLoadingBar' })

export default PageLoadingBar
