import React, { useState, useRef } from 'react'
import detectIt from 'detect-it'
import useRaf from 'hooks/useRaf'
import useResizeObserver from 'hooks/useResizeObserver'
import useWindowScroll from 'hooks/useWindowScroll'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  sizeBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1,
    visibility: 'hidden'
  },
  box: {
    width: '100%',
    '&.is-active': {
      position: 'fixed'
    }
  }
}, { name: 'SmoothScroll' })

const SmoothScroll = ({ children, className, enabled = true, onChangePageY }) => {
  const classes = useStyles()

  // refs
  const ref = useRef()
  const elDummy = useRef()

  // physics
  let _y = useRef(0)
  let y = 0
  let oy = 0
  let vy = 0

  const isFirefox = useRef(typeof navigator !== 'undefined' ? navigator.userAgent.toLowerCase().indexOf('firefox') > -1 : false)
  const easing = isFirefox.current ? 0.35 : 0.12
  const easingTrackpad = 0.28

  // size
  const [height, setHeight] = useState(null)

  const disablePointerEvents = true
  let pointerDisabled = false

  // trackpad detection & gesture
  // let deltaArray = [0, 0, 0]
  // let direction = 1
  // let timer
  const isStopped = true
  // const wheelAcceleration = false
  let mode = 'mouse'
  let dragging = false

  const onTouchStart = (e) => {
    dragging = false
    mode = 'touch'
  }

  const handleMouseWheel = (e) => {
    if (!mode || mode === 'touch') mode = 'mouse'

    dragging = false

    // const deltaY = e.wheelDelta && !e.deltaY ? -e.wheelDelta : e.deltaY

    // const tDirection = deltaY > 0 ? 1 : -1
    // if (tDirection !== direction) { deltaArray = [0, 0, 0] }
    // direction = tDirection

    // clearTimeout(timer)
    // timer = setTimeout(() => {
    //   // deltaArray = [0, 0, 0]
    //   isStopped = true
    // }, 120)

    // if (isStopped) {
    //   isStopped = false
    //   if (wheelAcceleration) mode = 'mouse'
    //   wheelAcceleration = true
    // }

    // analyzeArray(deltaY)
  }

  // const analyzeArray = (deltaY) => {
  //   const deltaArray0Abs = Math.abs(deltaArray[0])
  //   const deltaArray1Abs = Math.abs(deltaArray[1])
  //   const deltaArray2Abs = Math.abs(deltaArray[2])
  //   const deltaAbs = Math.abs(deltaY)

  //   if ((deltaAbs > deltaArray2Abs) &&
  //       (deltaArray2Abs > deltaArray1Abs) &&
  //       (deltaArray1Abs > deltaArray0Abs)) {
  //     wheelAcceleration = true
  //   } else if ((deltaAbs < deltaArray2Abs) &&
  //               (deltaArray2Abs <= deltaArray1Abs)) {
  //     wheelAcceleration = false
  //     mode = 'trackpad'
  //   }

  //   deltaArray.shift()
  //   deltaArray.push(deltaY)
  // }

  const tick = (force) => {
    if (enabled) {
      if (force) {
        y = _y
        vy = 0
      } else if (!dragging && (mode === 'touch' || mode === 'trackpad')) {
        y += (_y - y) * easingTrackpad
        vy = _y - y
      } else {
        y += (_y - y) * easing
        vy = _y - y
      }

      // disable pointer events while scrolling
      if (disablePointerEvents) {
        if (!pointerDisabled && Math.abs(vy) > 10) {
          pointerDisabled = true
          ref.current.style.pointerEvents = 'none'
        } else if (pointerDisabled && Math.abs(vy) <= 10) {
          pointerDisabled = false
          ref.current.style.pointerEvents = ''
        }
      }
    }

    const yRound = ((y + 0.01) * 100 | 0) / 100 // rounding values

    if (yRound !== oy) {
      SmoothScroll.y = yRound
      const translate = `translate3d(0,${-yRound}px,0)`
      ref.current.style.transform = translate
      onChangePageY && onChangePageY(y)
    }

    oy = yRound
  }

  const wheelEvent = useRef()
  const firstResize = useRef(true)

  if (typeof document !== 'undefined') {
    wheelEvent.current = 'onwheel' in document ? 'wheel'
      : 'onmousewheel' in document ? 'mousewheel'
        : 'DOMMouseScroll'

    // enable smoothscrolling for non touch device only
    if (detectIt.primaryInput !== 'touch') {
      useResizeObserver(ref, (entry) => {
        setHeight(entry.height)

        if (ref.current && firstResize.current) {
          const passive = detectIt.passiveEvents ? { passive: true } : false
          ref.current.addEventListener(wheelEvent.current, handleMouseWheel, passive)
          ref.current.addEventListener('touchstart', onTouchStart, passive)
          ref.current.classList.add('is-active')

          y = oy = _y = window.pageYOffset

          firstResize.current = false
        }
        tick(true)
      })

      useWindowScroll((e) => {
        if (isStopped) dragging = true
        if (enabled) _y = e.pageYOffset
      })

      useRaf(() => tick())
    }
  }

  // useImperativeHandle(ref, () => ({
  //   setY (value, force = true) {
  //     _y = value
  //     tick(force)
  //   }
  // }))

  return (
    <div>
      <div className={classes.box} ref={ref}>
        {children}
      </div>
      <div className={classes.sizeBox} ref={elDummy} style={{ height: height + 'px' }} />
    </div>
  )
}

SmoothScroll.y = 0

export default SmoothScroll
