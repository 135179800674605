import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { Formik, Form, Field } from 'formik'
import RichText from '../../RichText'
import Button from '../../Button'
import * as Yup from 'yup'
import theme from '../../../style/theme'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'
import RadioButton from './RadioButton'
import IndustryField from './IndustryField'
import TextField from './TextField'

const initialValues = {
  name: '',
  email: '',
  phone: '',
  industry: '',
  other: '',
  newsletterSignup: false
}

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('*Required'),
  phone: Yup.string().required('*Required'),
  industry: Yup.string().required('*Required'),
  email: Yup.string()
    .email('*Please include @ in your email address')
    .required('*Required')
})

const MemberForm = forwardRef(({
  className,
  slice: {
    title,
    copy,
    name_field_name: nameLabel,
    email_field_name: emailLabel,
    phone_field_name: phoneLabel,
    industry_field: industryField,
    message_field_name: messageLabel,
    signup_copy: signupCopy,
    cta_button_label: ctaLabel,
    success_message_title: successTitle,
    back_home_label: backHomeLabel
  }
}, ref) => {
  const classes = useStyles()

  return (
    <section className={cn(className, classes.section)} ref={ref} id='membership_form'>
      <div className={classes.content}>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            fetch('/api/partnership', { // eslint-disable-line
              headers: {
                'content-type': 'application/json'
              },
              method: 'post',
              body: JSON.stringify(values)
            }).then(response => {
              const section = document.getElementById('membership_form')
              window.scrollTo(0, section.getBoundingClientRect().y + window.pageYOffset)
              setSubmitting(false)
              setStatus('success')
            })
          }}
        >
          {({ isSubmitting, status, values }) => {
            return (
              !isSubmitting && status === 'success'
                ? (
                  <div>
                    <h1 className={classes.title}>{successTitle}</h1>
                    <div className={classes.buttonContainer}>
                      <Button to='/'>{backHomeLabel}</Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <h1 className={classes.title}>{title}</h1>
                    <RichText className={classes.copy} content={copy.text} />
                    <Form className={classes.form}>
                      <h2 className={classes.fieldTitle}>Your Details<span className={classes.marker}>*</span></h2>
                      <TextField name='name' label={nameLabel} required />
                      <TextField name='email' label={emailLabel} required type='email' />
                      <TextField name='phone' label={phoneLabel} required type='phone' />
                      <h2 className={classes.fieldTitle}>Your Industry<span className={classes.marker}>*</span></h2>
                      <IndustryField industryField={industryField} />
                      <TextField name='message' label={messageLabel} as='textarea' maxlength='250' />
                      <Field name='newsletterSignup'>
                        {({ field }) => (
                          <RadioButton {...field} name='newsletterSignup' label={signupCopy} className={classes.signup} />
                        )}
                      </Field>
                      <div className={classes.buttonContainer}>
                        <Button type='submit' disabled={isSubmitting}>{isSubmitting ? 'Please wait...' : ctaLabel}</Button>
                      </div>
                    </Form>
                  </>
                )
            )
          }}
        </Formik>
      </div>
    </section>
  )
})

const useStyles = createUseStyles({
  section: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.primary,
    color: theme.colors.beige,
    padding: [vw(80), 0, vw(80)],
    [theme.breakpoints.up('md')]: {
      padding: [vw(200, 'desktop'), 0, vw(120, 'desktop')]
    }
  },
  content: {
    width: '100%',
    padding: [0, span(1)],
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: span(14)
    }
  },
  title: {
    fontSize: vw(45),
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: vw(32),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(65, 'desktop'),
      marginBottom: vw(40, 'desktop')
    }
  },
  copy: {
    fontFamily: theme.fonts.austin,
    textAlign: 'center',
    marginBottom: vw(32),
    fontSize: 35,
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(40, 'desktop'),
      fontSize: vw(35, 'desktop')
    }
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: [0, 0, vw(32)],
    [theme.breakpoints.up('md')]: {
      margin: [0, vw(64, 'desktop'), vw(40, 'desktop')]
    }
  },
  signup: {
    fontFamily: theme.fonts.din,
    fontSize: vw(12),
    margin: 0,
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      margin: [0, vw(16, 'desktop')],
      fontSize: vw(12, 'desktop')
    },
    '& label': {
      marginLeft: vw(16),
      [theme.breakpoints.up('md')]: {
        marginLeft: vw(24, 'desktop')
      }
    }
  },
  buttonContainer: {
    width: '100%',
    marginTop: vw(24),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(40, 'desktop')
    },
    '& > button, & > a': {
      width: '100%',
      margin: 'auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: vw(240, 'desktop')
      }
    }
  },
  fieldTitle: {
    width: '100%',
    margin: [vw(12), 0, vw(8)],
    fontFamily: theme.fonts.din,
    fontSize: vw(13),
    textTransform: 'uppercase',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      margin: [vw(12, 'desktop'), vw(16, 'desktop'), vw(8, 'desktop')],
      fontSize: vw(13, 'desktop'),
      textAlign: 'left'
    }
  },
  marker: {
    fontSize: '1.4em',
    display: 'inline-block',
    marginLeft: vw(2),
    [theme.breakpoints.up('md')]: {
      marginLeft: vw(2, 'desktop')
    }
  }
}, { name: 'MemberForm' })

export default MemberForm
