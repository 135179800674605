import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getFooterBanner } from '../redux/selectors'
import ResponsiveImage from './ResponsiveImage'
import theme from '../style/theme'
import { vw } from '../helpers/vw'
import { span } from '../helpers/span'
import { getForegroundColorFromImage } from '../style/colorHelper'
import Link from './Link'
import SplitWordsAnimation from 'components/SplitWordsAnimation'

function FooterBanner () {
  const banner = useSelector(getFooterBanner)
  const { image, title, subtitle, logos } = banner
  const foreground = getForegroundColorFromImage(image)
  const classes = useStyles({ foreground })

  return (
    <section className={classes.container}>
      <ResponsiveImage className={classes.image} {...image} parallax={{ ratio: theme.parallax.ratio, scale: theme.parallax.scale }}>
        <div className={classes.content}>
          <div className={classes.titleContainer}>
            <SplitWordsAnimation stagger='0.08' as='h2' className={classes.title}>{title}</SplitWordsAnimation> */
            <span className={classes.subtitle}>{subtitle}</span>
          </div>
          <div className={classes.logos}>
            {logos.map((logo, i) => {
              const { image, url } = logo
              const Container = logo.url ? Link : ({ to, target, ...props }) => <span {...props} />
              return <Container key={i} to={url} target='_blank' className={classes.logo}><ResponsiveImage {...image} /></Container>
            })}
          </div>
        </div>
      </ResponsiveImage>
    </section>
  )
}

const useStyles = createUseStyles({
  container: {
  },
  image: {
    '&>div>picture:before': {
      paddingTop: '265%'
    },
    [theme.breakpoints.up('md')]: {
      '&>div>picture:before': {
        paddingTop: '71%'
      }
    }
  },
  content: {
    color: ({ foreground }) => foreground,
    // position: 'relative',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
  },
  titleContainer: {
    padding: [0, span(2)],
    [theme.breakpoints.up('md')]: {
      padding: [0, span(5)]
    }
  },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    lineHeight: 0.75,
    fontSize: vw(60),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(105, 'desktop')
    }
  },
  subtitle: {
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'block',
    fontSize: vw(25),
    marginTop: vw(20),
    lineHeight: 1,
    [theme.breakpoints.up('md')]: {
      marginTop: vw(20, 'desktop'),
      fontSize: vw(25, 'desktop')
    }
  },
  logos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: [vw(80), span(1)],
    margin: [0, 'auto'],
    [theme.breakpoints.up('md')]: {
      margin: [0, 'auto'],
      padding: [vw(20), span(2), 0],
      width: '100%',
      maxWidth: vw(1440, 'desktop')
    }
  },
  logo: {
    margin: [vw(4), vw(8)],
    display: 'block',
    [theme.breakpoints.up('md')]: {
      margin: [vw(16, 'desktop'), vw(25, 'desktop')]
    },
    '& picture:before': {
      paddingTop: 0
    },
    '& img': {
      height: vw(58),
      width: 'auto',
      position: 'static',
      [theme.breakpoints.up('md')]: {
        height: vw(68, 'desktop')
      }
    }
  }
}, { name: 'FooterBanner' })

export default FooterBanner
