import React, { forwardRef, memo, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import map from 'lodash/map'
import ResponsiveImage from '../ResponsiveImage'
import theme from 'style/theme'
import { span } from 'helpers/span'
import { vw } from 'helpers/vw'
import BuyButton from '../BuyButton'
import SubscribeButton from '../SubscribeButton'
import Link from '../Link'
import { resolveLink } from 'helpers/resolveLink'
import RichText from 'components/RichText'
import InViewAnimate from 'components/InViewAnimate'
import ParallaxElement from 'components/ParallaxElement'
import { expo } from 'style/eases'
import get from 'lodash/get'
import { formatPrice } from 'helpers/format'
import { InView } from 'react-intersection-observer'
import gsap, { Expo } from 'gsap'
import SplitWordsAnimation from 'components/SplitWordsAnimation'

const Product = memo(({ className, product, quantity, subtitle, discount, image, description, link, index }) => {
  const classes = useStyles()
  const btnLabel = 'Learn more'
  const price = get(product, ['variants', 0, 'price', 'amount'])
  const title = get(link, ['text'], get(product, ['title']))

  const handleInViewChange = useCallback((inView, entry) => {
    if (inView) {
      gsap.fromTo(entry.target.childNodes, 1.6, { opacity: 0, y: '15vw' }, { ease: Expo.easeOut, opacity: 1, y: 0, stagger: 0.1 })
    }
  }, [])

  return (
    <article className={cn(className, classes.product)}>
      <InViewAnimate className={classes.imageWrapper} triggerOnce threshold={0.5}>
        <Link link={resolveLink(link)}>
          <ResponsiveImage className={classes.image} {...image} parallax={{ ratio: 1.1, rotateInit: 5, rotate: 5 * (index % 2 ? -1 : 1), ease: 0.1 }} />
        </Link>
      </InViewAnimate>
      <ParallaxElement ratio='0.9' ease='0.2' className={cn(classes.details, classes.parallax)}>
        <InView as='div' onChange={handleInViewChange} threshold={0.5} className={classes.detailsContent} triggerOnce>
          <Link link={resolveLink(link)} className={classes.detailsTitle}>
            <h3>{title}</h3>
          </Link>
          <div className={classes.detailsSubtitle}>{subtitle}</div>
          <div className={classes.detailsPriceQuantity}>
            {discount && <span className={classes.detailsDiscount}>{formatPrice(discount)}</span>}
            <span className={classes.detailsPrice}>{formatPrice(price)}</span>
            <span className={classes.detailsQuantity}>{quantity}</span>
          </div>
          {description && description.text && <RichText className={classes.detailsDescription} content={description.text} />}
          <Link link={resolveLink(link)} className={classes.learMoreBtn}>{btnLabel}</Link>
          {product && (
            <div className={classes.detailsCtas}>
              <BuyButton product={product} />
              <SubscribeButton product={product} />
            </div>
          )}
        </InView>
      </ParallaxElement>
    </article>
  )
})

const ProductCollection = forwardRef(({ className, slice: { intro_title: introTitle, intro, title, subtitle, copy, products } }, ref) => {
  const classes = useStyles()

  return (
    <section className={cn(className, classes.productCollection)}>
      {introTitle &&
        <h2 className={classes.introTitle}>
          {introTitle}
        </h2>}

      {intro &&
        <p className={classes.intro}>{intro}</p>}
      {title &&
        <h3 className={classes.title}>
          <svg viewBox='0 0 290.34 111' preserveAspectRatio='xMinYMin'>
            <path id='curve' d='M0,111S44.93,0,145.17,0,290.34,111,290.34,111' fill='transparent' />
            <text>
              <textPath xlinkHref='#curve' startOffset='50%' textAnchor='middle'>
                {title}
              </textPath>
            </text>
          </svg>
        </h3>}
      {subtitle && <SplitWordsAnimation className={classes.subtitle}>{subtitle}</SplitWordsAnimation>}
      {map(products, (product, i) => <Product className={classes.productCollectionProduct} key={product.id} index={i} {...product} />)}
    </section>
  )
})

const useStyles = createUseStyles({
  introTitle: {
    display: 'block',
    textAlign: 'center',
    margin: [0, 'auto'],
    paddingTop: vw(100),
    width: span(22),
    marginBottom: vw(20),
    fontSize: vw(40),
    lineHeight: 1,
    textTransform: 'upperCase',
    [theme.breakpoints.up('md')]: {
      paddingTop: vw(120, 'desktop'),
      marginBottom: vw(30, 'desktop'),
      fontSize: vw(65, 'desktop'),
      width: span(12)
    }
  },
  intro: {
    display: 'block',
    textAlign: 'center',
    margin: [0, 'auto'],
    width: span(22),
    fontSize: vw(20),
    lineHeight: 1,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(35, 'desktop'),
      width: span(13)
    }
  },
  title: {
    display: 'block',
    margin: [0, 'auto'],
    paddingTop: vw(100),
    width: span(18),
    '& svg': {
      overflow: 'visible'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: vw(130, 'desktop'),
      marginBottom: vw(20, 'desktop'),
      width: span(4.7)
    },
    '& text': {
      fill: 'white',
      textTransform: 'uppercase',
      fontSize: 45 // Not need to use the viewport as it is a svg, TODO: we should adjust the size of the font to fit the path
    }
  },
  subtitle: {
    textTransform: 'uppercase',
    display: 'block',
    textAlign: 'center',
    margin: [0, 'auto'],
    fontSize: vw(30),
    width: span(16),
    marginBottom: vw(100),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(30, 'desktop'),
      marginBottom: vw(200, 'desktop'),
      width: span(7)
    }
  },
  product: {
    paddingBottom: vw(60),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingBottom: vw(40, 'desktop')
    },
    '&:last-of-type': {
      paddingBottom: 0
    }
  },
  imageWrapper: {
    position: 'relative',
    width: span(26),
    marginLeft: span(-1),
    display: 'block',
    [theme.breakpoints.down('md')]: {
      marginBottom: vw(12)
    },
    [theme.breakpoints.up('md')]: {
      width: span(10),
      marginLeft: 0
    },
    '&:hover': {
      [theme.breakpoints.up('md')]: {
        '&::after': {
          transitionDuration: '0.6s',
          transform: 'scale(1.05) translateZ(0)'
        }
      }
    },
    '&::after': {
      content: '""',
      display: 'block',
      background: theme.colors.secondary,
      borderRadius: '50%',
      width: '100%',
      paddingBottom: '100%',
      opacity: 0,
      willChange: 'transform',
      transition: `transform 2s ${expo.out}, opacity 2s ${expo.out}`
    },
    '&.is-out': {
      '&::after': {
        transform: 'scale(0.8)'
        // transitionDuration: 'ss'
      }
    },
    '&.is-in': {
      '&::after': {
        transform: 'scale(1) translateZ(0)',
        opacity: 1
      }
    }
  },
  image: {
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    left: '5%',
    width: '90%'
  },
  details: {
    width: span(20),
    marginLeft: span(2),
    [theme.breakpoints.up('md')]: {
      width: span(6),
      marginLeft: 0
    },
    '& a': {
      color: 'currentColor',
      '&:visited': {
        color: 'currentColor'
      }
    },
    '& h3': {
      textTransform: 'uppercase',
      fontSize: vw(30),
      width: '80%',
      [theme.breakpoints.up('md')]: {
        fontSize: vw(40, 'desktop'),
        width: '100%'
      }
    }
  },
  detailsTitle: {
    display: 'block',
    textDecoration: 'none',
    lineHeight: 0.87,
    marginBottom: vw(5),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(5, 'desktop')
    }
  },
  detailsSubtitle: {
    textTransform: 'uppercase',
    fontSize: vw(20),
    marginBottom: vw(15),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(20, 'desktop'),
      marginBottom: vw(15, 'desktop')
    }
  },
  detailsCtas: {
    display: 'flex',
    alignItems: 'stretch',
    marginTop: vw(20),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(20, 'desktop')
    },
    '& > *': {
      marginRight: vw(12),
      [theme.breakpoints.up('md')]: {
        marginRight: vw(12, 'desktop')
      },
      '&:last-child': {
        marginRight: 0
      },
      '& > button': {
        height: '100%'
      }
    }
  },
  detailsDescription: {
    lineHeight: 1,
    fontSize: vw(30),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(30, 'desktop')
    }
  },
  detailsPriceQuantity: {
    marginBottom: vw(15),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(15, 'desktop')
    }
  },
  detailsPrice: {
    fontSize: vw(20),
    marginRight: vw(5),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(20, 'desktop'),
      marginRight: vw(5, 'desktop')
    }
  },
  detailsDiscount: {
    fontSize: vw(15),
    textDecorationLine: 'line-through',
    display: 'inline-block',
    marginRight: vw(7),
    lineHeight: 1.5,
    [theme.breakpoints.up('md')]: {
      marginRight: vw(7, 'desktop'),
      fontSize: vw(15, 'desktop')
    }
  },
  detailsQuantity: {
    textTransform: 'uppercase',
    fontFamily: theme.fonts.din,
    fontSize: vw(12),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop')
    }
  },
  detailsContent: {
    '& > div, & > a': {
      opacity: 0,
      willChange: 'transform, opacity'
    }
  },
  productCollection: {
    color: 'white',
    background: theme.colors.primary,
    paddingBottom: vw(80),
    [theme.breakpoints.up('md')]: {
      paddingBottom: vw(60, 'desktop')
    }
  },
  productCollectionProduct: {
    '&:nth-child(2n + 1)': {
      '& $imageWrapper': {
        [theme.breakpoints.up('md')]: {
          margin: [0, span(2), 0, span(1)]
        }
      }
    },
    '&:nth-child(2n)': {
      flexDirection: 'row-reverse',
      '& $imageWrapper': {
        [theme.breakpoints.up('md')]: {
          margin: [0, span(2), 0, span(2)]
        }
      }
    }
  },
  learMoreBtn: {
    display: 'block',
    fontFamily: theme.fonts.din,
    fontSize: vw(10),
    marginTop: vw(20),
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(10, 'desktop'),
      marginTop: vw(20, 'desktop')
    }
  }
}, { name: 'ProductCollection' })

export default ProductCollection
