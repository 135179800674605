import React, { forwardRef, useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import ArticlePreview from 'components/ArticlePreview'
import theme from 'style/theme'
import { span } from 'helpers/span'
import { vw } from 'helpers/vw'
import Flickity from 'react-flickity-component'
import cn from 'classnames'
import { resolveInternalLinkUrl } from '../../helpers/resolveLink'
import Link from 'components/Link'
import { InView } from 'react-intersection-observer'

const useStyles = createUseStyles({
  sliderWrapper: {
    padding: [vw(40), span(3), vw(80), span(2)],
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: [vw(100, 'desktop'), span(1)]
    }
  },
  header: {
    marginBottom: vw(60),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      marginBottom: vw(55, 'desktop'),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  slider: {
    outline: 'none',
    display: 'flex'
  },
  title: {
    display: 'block',
    textTransform: 'uppercase',
    fontSize: vw(45),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(45, 'desktop')
    }
  },
  link: {
    textTransform: 'uppercase',
    fontFamily: theme.fonts.din,
    color: theme.colors.primary,
    fontSize: '10px',
    fontWeight: 'bold'
  },
  article: {
    marginRight: span(1, 20),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: span(6, 22),
      marginRight: span(1, 22)
    }
  }
  // inViewWrapper: {
  //   '& article:nth-child(1), & article:nth-child(2), & article:nth-child(3), & article:nth-child(4)': {
  //     transform: `translateX(calc(${span(22, 19)} - 1px))`,
  //     [theme.breakpoints.up('md')]: {
  //       transform: `translateX(calc(${span(23, 6)} - 1px))`
  //     }
  //   }
  // }
}, { name: 'ArticleSlider' })

const ArticleSlider = forwardRef(({ slice, page, nav }, ref) => {
  const classes = useStyles()
  const { data, cta_label: ctaLabel, title, view_more_label: viewMoreLabel = 'View more' } = slice
  const { articles, slug } = data
  const mainRef = useRef()

  useEffect(() => {
    const current = nav.current
    current.observeBrightSlice(mainRef)
    return () => { current.unobserveBrightSlice(mainRef) }
  }, [])

  const flickityOptions = {
    prevNextButtons: false,
    pageDots: false,
    contain: true,
    cellAlign: 'left'
  }

  // const handleInView = (inView, entry) => {
  //   if (inView) {
  //     const targets = Array.from(entry.target.querySelectorAll('article')).filter((v, i) => { return i < 4 })
  //     gsap.to(targets, 2, { ease: 'expo.out', x: 0, stagger: 0.06 })
  //   }
  // }

  return (
    <div ref={mainRef} className={classes.sliderWrapper}>
      <header className={classes.header}>
        <h2 className={classes.title}>{title}</h2>
        {slug && <Link to={resolveInternalLinkUrl(data)} className={classes.link}>{viewMoreLabel}</Link>}
      </header>
      <InView rootMargin='-20% 0px' triggerOnce className={classes.inViewWrapper}>
        <Flickity static options={flickityOptions} className={cn('carousel', classes.slider)}>
          {articles && articles.map((article, i) =>
            <ArticlePreview className={classes.article} key={i} {...article} url={resolveInternalLinkUrl(article)} linkLabel={ctaLabel} />
          )}
        </Flickity>
      </InView>
    </div>
  )
})

export default ArticleSlider
