import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { ActionType } from 'redux-promise-middleware'

const makeFetchReducer = (actionType, listPropName) => function (state = {}, action) {
  const isLoadingMore = get(action, ['meta', 'more'])
  const paged = get(action, ['meta', 'paged'])
  switch (action.type) {
    case `${actionType}_${ActionType.Pending}`:
      return {
        ...state,
        busy: true,
        content: paged && !isLoadingMore ? undefined : state.content
      }
    case `${actionType}_${ActionType.Fulfilled}`:
      return {
        ...state,
        busy: false,
        error: undefined,
        content: isLoadingMore ? {
          ...state.content,
          ...action.payload,
          [listPropName]: [
            ...state.content[listPropName],
            ...action.payload[listPropName]
          ]
        } : action.payload
      }
    case `${actionType}_${ActionType.Rejected}`:
      return {
        ...state,
        busy: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const makeKeyedFetchReducer = (actionType, keyName, listPropName) => {
  const reducer = makeFetchReducer(actionType, listPropName)
  return (state = {}, action) => {
    const keyValue = get(action, ['meta', keyName])
    if (!keyValue) {
      return state
    }
    const prevState = state[keyValue]
    const nextState = reducer(prevState, action)
    if (nextState !== prevState) {
      return {
        ...state,
        [keyValue]: isEmpty(nextState) ? undefined : nextState
      }
    }
    return state
  }
}

export default makeFetchReducer
