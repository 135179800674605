import React, { useMemo, useEffect, useRef } from 'react'
import cn from 'classnames'
import getVideoId from 'get-video-id'
import { createUseStyles } from 'react-jss'
import { vw } from '../../helpers/vw'
import theme from '../../style/theme'
import { span } from '../../helpers/span'

export default ({ slice: { url }, index, nav }) => {
  const classes = useStyles()
  const ref = useRef()
  const embedCode = useMemo(() => {
    const videoId = url ? getVideoId(url) : ''

    if (!videoId) {
      return null
    }

    switch (videoId.service) {
      case 'youtube': {
        return <iframe src={`https://www.youtube.com/embed/${videoId.id}?rel=0`} frameBorder='0' allowFullScreen />
      }

      case 'vimeo': {
        return (
          <iframe
            src={`https://player.vimeo.com/video/${videoId.id}`}
            width='800'
            frameBorder='0'
            webkitallowfullscreen='true'
            mozallowfullscreen='true'
            allowFullScreen
          />
        )
      }
      default: {
        return <span>Unsupported video service: {videoId.service}</span>
      }
    }
  }, [url])

  useEffect(() => {
    const current = nav.current
    current.observeBrightSlice(ref)
    return () => { current.unobserveBrightSlice(ref) }
  }, [])

  return (
    <section className={cn(classes.section, { first: index < 2 })} ref={ref}>
      <div className={classes.container}>
        {embedCode}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    padding: [0, 0, vw(30), 0],
    margin: [0, 'auto'],
    [theme.breakpoints.up('md')]: {
      padding: [0, 0, vw(100, 'desktop'), 0],
      margin: [0, 'auto']
    },
    '&.first': {
      paddingTop: vw(30),
      [theme.breakpoints.up('md')]: {
        paddingTop: vw(100, 'desktop')
      }
    }
  },
  container: {
    margin: 'auto',
    position: 'relative',
    width: span(22),
    paddingBottom: `${(9 / 16) * 100}%`,
    overflow: 'hidden',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      width: span(20)
    },
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  }
})
