const hydrateApp = () => {
  require('./hydrateApp')
}

const loadPolyfills = async () => {
  // Add polyfills here if required
}

loadPolyfills()
  .then(hydrateApp)
