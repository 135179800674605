import React, { forwardRef, useMemo, useCallback } from 'react'
import map from 'lodash/map'
import get from 'lodash/get'
import min from 'lodash/min'
import find from 'lodash/find'
import Slider from './Slider'
import { useSelector, useDispatch } from 'react-redux'
import { getBundlesForSlider, getCartBuyButtonLabels } from '../../redux/selectors'
import { addBundleToCartActionCreator, openCartDialog } from '../../redux/actions'

const BundleSlider = forwardRef(({
  className,
  slice: { title, products, show_product_info: showInfo, show_buy_button: showBuyButton }
}, ref) => {
  const bundles = useSelector(getBundlesForSlider)
  const minAspect = min(map(bundles, bundle => get(bundle, ['image', 'aspect'])))
  const labels = useSelector(getCartBuyButtonLabels)
  const dispatch = useDispatch()

  const items = useMemo(() => {
    return map(bundles, bundle => {
      const disabled = !!find(bundle.products, product => !get(product, ['product', 'availableForSale']))
      return {
        image: bundle.image,
        title: bundle.title,
        price: bundle.amount,
        comparePrice: bundle.amount + bundle.discount_amount,
        info: bundle.details,
        buyButtonText: !disabled ? bundle.buy_text : labels.outOfStockLabel,
        disable: disabled
      }
    })
  }, [products])

  const handleBuyBundle = useCallback((item, index) => {
    if (item.disable) return
    const bundle = bundles[index]
    const itemsToAdd = map(bundle.products, product => ({
      variantId: get(product, ['product', 'variants', 0, 'id']),
      numberToAdd: product.quantity
    }))
    dispatch(addBundleToCartActionCreator(itemsToAdd))
    dispatch(openCartDialog())
  }, [bundles])

  return (
    <Slider title={title} items={items} minAspect={minAspect} showInfo showBuyButton onBuyClick={handleBuyBundle} />
  )
})

export default BundleSlider
