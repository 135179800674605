import React, { useCallback } from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../redux/selectors'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import { expo } from '../style/eases'
import theme from '../style/theme'
import { span } from '../helpers/span'
import { vw } from '../helpers/vw'
import canUseDOM from 'dom-helpers/canUseDOM'
import Button from './Button'
import Link from './Link'
import RichText from './RichText'

const SubscribePopup = ({ className, title, copy, onClose, show, subscribeHandler, ...rest }) => {
  const classes = useStyles()
  const isMobile = useSelector(isCurrentBreakpointMobile)

  const onClick = useCallback((e) => {
    e.preventDefault()
  }, [])

  const renderChildren = () => (
    <div className={cn(classes.popup, className, { show })} onClick={onClick}>
      <div className={classes.container}>
        <span className={classes.title}>{title}</span>
        <RichText className={classes.copy} content={copy.text} />
        <Button onClick={subscribeHandler} className={classes.subscribeButton} transparent>Subscribe</Button>
        <Button onClick={onClose} className={classes.closeButton} transparent>Close</Button>
        <Link className={classes.disclaimer} to='/terms-and-conditions/'>Terms & Conditions Apply</Link>
      </div>
    </div>
  )

  return isMobile && canUseDOM ? createPortal(renderChildren(), document.body) : renderChildren()
}

const useStyles = createUseStyles({
  popup: {
    cursor: 'default',
    position: 'fixed',
    width: '100%',
    textAlign: 'center',
    color: theme.colors.primary,
    whiteSpace: 'normal',
    fontFamily: theme.fonts.austin,
    transition: `opacity 1s ${expo.out}, transform 1s ${expo.out}`,
    transform: 'translate(100%, 0)',
    zIndex: theme.zIndex.popup,
    top: 0,
    bottom: 0,
    '&.show': {
      transform: 'translate(0, 0)'
    },
    [theme.breakpoints.up('md')]: {
      opacity: 0,
      position: 'absolute',
      transitionDuration: '0.25s',
      transform: 'scale(0) translate(-50%, 0)',
      transformOrigin: '0 100%',
      width: vw(240, 'desktop'),
      top: 'unset',
      bottom: '100%',
      left: '50%',
      '&.show': {
        opacity: 1,
        transform: 'scale(1) translate(-50%, 0)'
      }
    }
  },
  container: {
    backgroundColor: theme.colors.beige,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    padding: [vw(40), vw(32)],
    [theme.breakpoints.up('md')]: {
      padding: [vw(40, 'desktop'), vw(20, 'desktop'), vw(30, 'desktop')],
      marginBottom: vw(16, 'desktop')
    }
  },
  title: {
    display: 'block',
    fontSize: vw(60),
    lineHeight: 0.9,
    marginBottom: vw(48),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(35, 'desktop'),
      lineHeight: 0.9,
      marginBottom: vw(32, 'desktop')
    }
  },
  copy: {
    flex: '1',
    display: 'block',
    marginBottom: vw(6),
    fontFamily: theme.fonts.din,
    textTransform: 'uppercase',
    fontSize: vw(14),
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop')
    },
    '& ul': {
      display: 'block',
      margin: [0, 0, 0, vw(16)],
      padding: 0,
      [theme.breakpoints.up('md')]: {
        margin: [0, 0, 0, vw(16, 'desktop')]
      }
    },
    '& ul > li': {
      marginBottom: vw(20),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(16, 'desktop')
      }
    }
  },
  disclaimer: {
    fontFamily: theme.fonts.din,
    fontSize: vw(10),
    color: theme.colors.primary,
    textTransform: 'uppercase',
    textDecoration: 'none',
    opacity: 0.6,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(10, 'desktop'),
      textAlign: 'left'
    }
  },
  subscribeButton: {
    width: '100%',
    marginTop: vw(32),
    backgroundColor: theme.colors.primary,
    borderColor: theme.colors.primary,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  closeButton: {
    width: '100%',
    color: theme.colors.primary,
    borderColor: theme.colors.primary,
    marginTop: vw(8),
    marginBottom: vw(16),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
})

export default SubscribePopup
