import React, { forwardRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import BlockContent from '@sanity/block-content-to-react'
import cn from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import Color from 'color'
import theme from 'style/theme'
import { span } from 'helpers/span'
import { vw } from 'helpers/vw'
import { expo } from 'style/eases'
import { toggleStockistDialog } from '../../redux/actions'
import {
  isStockistDialogOpen,
  getStockistsDialogTitle,
  getStockistsDialogDisclaimer
} from '../../redux/selectors'
import useOutsideClickListener from '../../hooks/useOutsideClickListner'
import LocationInput from './LocationInput'
import StockistList from './StockistList'
import { ReactComponent as CloseIcon } from 'images/close.svg'
import { ReactComponent as ArrowIcon } from 'images/arrow-left.svg'

const StockistsDialog = forwardRef((props, ref) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const open = useSelector(isStockistDialogOpen)
  const title = useSelector(getStockistsDialogTitle)
  const disclaimer = useSelector(getStockistsDialogDisclaimer)

  const handleClose = useCallback(() => {
    dispatch(toggleStockistDialog())
  }, [])

  const containerRef = useOutsideClickListener(open, handleClose)

  return (
    <section className={cn(classes.panel, { open })} ref={containerRef}>
      <div className={classes.main}>
        <header className={classes.header}>
          <span className={classes.title}>
            <BlockContent blocks={title.text} />
          </span>
          <button className={classes.closeButton} aria-label='close' onClick={handleClose}>
            <CloseIcon className={cn(classes.desktopIcon, classes.buttonIcon)} viewBox='0 0 12 12' />
            <ArrowIcon className={cn(classes.mobileIcon, classes.buttonIcon)} viewBox='0 0 12 12' />
          </button>
        </header>
        <LocationInput />
        {disclaimer && (
          <span className={classes.disclaimer}>
            {disclaimer}
          </span>
        )}
        <StockistList />
      </div>
    </section>
  )
})

const useStyles = createUseStyles({
  panel: {
    position: 'fixed',
    transform: 'translate3d(-101%,0,0)',
    transition: `transform 1s ${expo.out}`,
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.cart,
    background: theme.colors.beige,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    [theme.breakpoints.up('md')]: {
      width: span(9),
      borderLeft: [1, 'solid', Color(theme.colors.primary).alpha(0.3).toString()]
    },
    '&.open': {
      transform: 'translate3d(0,0,0)'
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'auto',
    padding: [vw(80), vw(30), 0],
    [theme.breakpoints.up('md')]: {
      padding: [span(1, 9), span(1, 9), 0]
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: vw(16),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(40, 'desktop')
    }
  },
  title: {
    flex: '1 1 auto',
    '& > p': {
      fontFamily: theme.fonts.austin,
      fontSize: 40,
      textTransform: 'uppercase',
      color: theme.colors.primary,
      margin: 0,
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        textAlign: 'left'
      }
    }
  },
  buttonIcon: {
    overflow: 'visible'
  },
  desktopIcon: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  mobileIcon: {
    width: 12,
    height: 14,
    marginTop: -1,
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  closeButton: {
    cursor: 'pointer',
    flex: '0 0 auto',
    appearance: 'none',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 34,
    height: 34,
    border: 'none',
    color: theme.colors.white,
    background: theme.colors.secondary,
    transition: `background 1s ${expo.out}, transform 1s ${expo.out}`,
    '&:hover': {
      background: theme.colors.primary,
      transitionDuration: '0.5s',
      transform: 'scale(1.1) translateZ(0)'
    },
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: span(1),
      left: vw(30)
    }
  },
  disclaimer: {
    display: 'block',
    fontSize: vw(12),
    lineHeight: 1.3,
    fontWeight: 400,
    fontFamily: theme.fonts.din,
    marginBottom: vw(40),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop'),
      marginBottom: vw(40, 'desktop')
    }
  }
}, { name: 'StockistsDialog' })

export default StockistsDialog
