import React, { useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import SubscribeButton, { useSubscriptionData } from '../../SubscribeButton'
import cn from 'classnames'
import theme from '../../../style/theme'
import { vw } from '../../../helpers/vw'
import Color from 'color'
import RichText from '../../RichText'
import BuyButtonWithQuantity from './BuyButtonWithQuantity'
import useProductAddToCartLabel from './useProductAddToCartLabel'

const RadioButtons = ({ slice }) => {
  const {
    product,
    buy_button_text: buyButtonText
  } = slice
  const classes = useStyles()
  const [selected, setSelected] = useState(0)

  const onBuyClick = useCallback(() => { setSelected(0) }, [])
  const onSubscribeClick = useCallback(() => { setSelected(1) }, [])

  const { costPerMonth, popupCopy, subscriptionId } = useSubscriptionData(product)

  const addToCartLabel = useProductAddToCartLabel(product)

  return (
    <div className={classes.container}>
      {subscriptionId && (
        <div className={classes.radioButtons}>
          <button className={cn(classes.radio, { selected: selected === 0 })} onClick={onBuyClick}>{buyButtonText || addToCartLabel}</button>
          <button className={cn(classes.radio, { selected: selected === 1 })} onClick={onSubscribeClick}>Subscribe <span className={classes.costPerMonth}>{`$${costPerMonth} / month`}</span></button>
        </div>
      )}
      <div className={classes.buttons}>
        {selected === 0 && (
          <BuyButtonWithQuantity product={product} classNames={{ button: classes.button }} />
        )}
        {selected === 1 && (
          <div>
            {popupCopy && <RichText className={classes.copy} content={popupCopy.text} />}
            <SubscribeButton
              className={classes.button}
              product={product}
              transparent={false}
              text='Subscribe'
              showPopup={false}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  radioButtons: {
    display: 'flex',
    margin: [0, 0, vw(16)],
    [theme.breakpoints.up('md')]: {
      margin: [0, 0, vw(16, 'desktop')]
    }
  },
  radio: {
    color: Color(theme.colors.white).alpha(0.5).string(),
    border: [1, 'solid', theme.colors.white],
    margin: 0,
    width: '50%',
    textAlign: 'center',
    padding: [vw(13), vw(18), vw(10), vw(36)],
    fontFamily: theme.fonts.din,
    fontSize: vw(12),
    position: 'relative',
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop'),
      padding: [vw(13, 'desktop'), vw(20, 'desktop'), vw(10, 'desktop'), vw(38, 'desktop')]
    },
    '&.selected': {
      color: theme.colors.white
    },
    '&:last-child': {
      marginLeft: -1
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: vw(18),
      top: '50%',
      width: vw(8),
      height: vw(8),
      border: [1, 'solid', Color(theme.colors.white).alpha(0.5).string()],
      borderRadius: '50%',
      transform: 'translate(0, -50%)',
      [theme.breakpoints.up('md')]: {
        left: vw(20, 'desktop'),
        width: vw(8, 'desktop'),
        height: vw(8, 'desktop')
      }
    },
    '&.selected:after': {
      borderColor: theme.colors.white,
      backgroundColor: theme.colors.white
    }
  },
  costPerMonth: {
    fontFamily: theme.fonts.austin,
    fontSize: vw(12),
    opacity: 0.5,
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      marginLeft: vw(6, 'desktop'),
      fontSize: vw(12, 'desktop')
    }
  },
  button: {
    width: '100%',
    '& button': {
      width: '100%'
    }
  },
  copy: {
    marginBottom: vw(16),
    fontFamily: theme.fonts.din,
    fontSize: vw(14),
    fontWeight: 300,
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(16, 'desktop'),
      fontSize: vw(14, 'desktop')
    },
    '& ul, & ol': {
      listStyle: 'none',
      padding: 0,
      margin: 0
    },
    '& li': {
      marginBottom: vw(8),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(8, 'desktop')
      }
    }
  }
}, { name: 'RadioButtons' })

export default RadioButtons
