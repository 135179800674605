import React, { forwardRef, useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import RichText from '../RichText'
import theme from 'style/theme'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'

const useStyles = createUseStyles({
  content: {
    padding: [0, 0, vw(30), 0],
    margin: [0, 'auto'],
    [theme.breakpoints.up('md')]: {
      padding: [0, 0, vw(100, 'desktop'), 0],
      margin: [0, 'auto']
    },
    '&.first': {
      paddingTop: vw(30),
      [theme.breakpoints.up('md')]: {
        paddingTop: vw(100, 'desktop')
      }
    },
    '& blockquote': {
      lineHeight: 0.86,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: vw(54),
      fontWeight: 'normal',
      padding: [0, span(1)],
      margin: [vw(30), 0],
      [theme.breakpoints.up('md')]: {
        padding: [0, span(4)],
        margin: [vw(80, 'desktop'), 0],
        fontSize: vw(100, 'desktop')
      }
    },
    '& p, & ul': {
      lineHeight: 1.1,
      fontSize: vw(24),
      padding: [0, span(1)],
      marginBottom: vw(30),
      [theme.breakpoints.up('md')]: {
        padding: [0, span(6)],
        marginBottom: vw(60, 'desktop'),
        fontSize: vw(24, 'desktop')
      }
    },
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      padding: [0, span(1)],
      fontFamily: theme.fonts.din,
      textTransform: 'uppercase',
      margin: [0, 0, '1.5em'],
      [theme.breakpoints.up('md')]: {
        padding: [0, span(6)]
      }
    },
    '& h1': {
      fontSize: vw(24),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(32, 'desktop')
      }
    },
    '& h2': {
      fontSize: vw(22),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(24, 'desktop')
      }
    },
    '& h3': {
      fontSize: vw(14),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(14, 'desktop')
      }
    },
    '& a': {
      color: theme.colors.secondary,
      textDecoration: 'none',
      fontStyle: 'italic'
    }
  }
}, { name: 'RichTextSlice' })

const BlogRichTextSlice = forwardRef(({ index, className, slice, nav }, ref) => {
  const classes = useStyles()
  const { text } = slice
  const mainRef = useRef()

  useEffect(() => {
    const current = nav.current
    current.observeBrightSlice(mainRef)
    return () => { current.unobserveBrightSlice(mainRef) }
  }, [])

  return (
    <RichText ref={mainRef} className={cn(classes.content, { first: index <= 1 }, className)} content={text} tag='section' />
  )
})

export default BlogRichTextSlice
