import color from 'color'
import theme from './theme'

export const getForegroundColorFromImage = (image, defaultColor = theme.colors.black) => {
  const { palette: { dominant: { title } } } = image
  if (!title) {
    return defaultColor
  }
  const c = color(title)
  return c.isLight() ? theme.colors.beige : theme.colors.black
}
