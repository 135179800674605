/* eslint-env browser */
import { ActionType } from 'redux-promise-middleware'
import {
  CONTENT_TYPE,
  REHYDRATED,
  CHECKOUT_CART
} from '../actions'
import inDOM from 'dom-helpers/canUseDOM'
import {
  getPageTitle, getCartLineItems
} from '../selectors'

export default store => next => action => {
  if (!inDOM) return next(action)

  window.dataLayer = window.dataLayer || []

  switch (action.type) {
    case `${CONTENT_TYPE}_${ActionType.Fulfilled}`:
    case REHYDRATED: {
      const title = getPageTitle(store.getState())
      window.dataLayer.push({
        event: 'pageview',
        page: window.location.pathname,
        title
      })
      break
    }
    case CHECKOUT_CART: {
      const cartItems = getCartLineItems(store.getState())
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'AUD',
          add: {
            products: cartItems.map(item => ({
              name: item.product.title,
              id: item.product.id,
              price: item.variant.price.amount,
              quantity: item.quantity
            }))
          }
        }
      })
    }
    // no default
  }

  return next(action)
}
