import React from 'react'
import get from 'lodash/get'
import RouterLink from 'redux-first-router-link'
import toUrl from 'redux-first-router-link/dist/toUrl'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import { getRoutesMap } from '../redux/selectors'

// RouterLink's propTypes say its tagName prop should only be a string but there's no reason for it not to allow a
// component. Silence those warnings...
if (process.env.NODE_ENV !== 'production') {
  delete RouterLink.WrappedComponent.propTypes.tagName
}
const AWithRef = ({ withRef, withHref, ...props }) => <a {...props} ref={withRef} href={withHref} />

function fixExternalLinks ({ target, rel } = {}) {
  if (target && target !== '_self') {
    rel = 'noopener noreferrer' // https://mathiasbynens.github.io/rel-noopener/
  }
  return { target, rel }
}

const isExternalLink = url => typeof url === 'object' ? false : (
  /^[a-z]+:/.test(url) || // e.g. http:// https:// mailto: tel:
  url.substr(0, 2) === '//'
)

const isRoutedLink = url => typeof url === 'object' || !(
  isExternalLink(url) ||
  url.substr(0, 1) === '#' ||
  url.substr(0, 8) === '/static/'
)

const Link = React.forwardRef(function Link ({
  children,
  defaultText,
  link,
  to,
  shouldOpenInTab = false,
  nonLinkTag = React.Fragment,
  ...rest
}, ref) {
  const routesMap = useSelector(getRoutesMap)
  // Need to handle missing and empty links gracefully
  const hasLink = !!(to || get(link, ['url']))
  const Tag = hasLink ? (
    isRoutedLink((to || link.url)) ? RouterLink : 'a'
  ) : nonLinkTag
  const props = {}
  if (Tag !== React.Fragment) {
    Object.assign(props, rest)
  }
  if (Tag === RouterLink) {
    props.tagName = AWithRef
    props.withRef = ref
  } else {
    props.ref = ref
  }
  if (hasLink) {
    if (Tag === RouterLink) {
      props.to = to || link.url
      // Because we’re passing tagName to RouterLink, it will not set an href for us on the <a> so we need to pass the
      // href in as a prop that will be passed through by RouterLink - it captures the url, to and href for itself.
      props.withHref = toUrl(props.to, routesMap)
    } else {
      props.href = to || link.url
    }
    Object.assign(props, fixExternalLinks(link || rest))
  }
  const hasChildren = !isEmpty(compact(React.Children.toArray(children)))
  const extraProps = shouldOpenInTab ? { target: '_blank', rel: 'noopener noreferrer' } : {}
  return (
    <Tag {...props} {...extraProps}>
      {hasChildren ? children : get(link, ['text']) || defaultText}
    </Tag>
  )
})

export default Link
