import React, { useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import BuyButton from '../../BuyButton'
import theme from '../../../style/theme'
import { vw } from '../../../helpers/vw'
import cn from 'classnames'

const BuyButtonWithQuantity = ({ product, text = 'Add To Cart', classNames = {} }) => {
  const classes = useStyles()
  const [quantity, setQuantity] = useState(1)

  const incrementQuantity = useCallback(() => {
    setQuantity(value => value + 1)
  }, [])
  const decrementQuantity = useCallback(() => {
    setQuantity(value => Math.max(1, value - 1))
  }, [])

  return (
    <div className={classes.buyContainer}>
      <span className={cn(classes.quantityLabel, classNames.quantityLabel)}>Quantity</span>
      <div className={cn(classes.quantityButtons, classNames.quantityButtons)}>
        <button className={classes.adjustButton} onClick={decrementQuantity} aria-label='Decrement Product'>-</button>
        <span className={classes.quantity}>{quantity}</span>
        <button className={classes.adjustButton} onClick={incrementQuantity} aria-label='Increment Product'>+</button>
      </div>
      <BuyButton className={cn(classes.button, classNames.button)} product={product} text={text} quantity={quantity} />
    </div>
  )
}

const useStyles = createUseStyles({
  buyContainer: {
    display: 'flex',
    alignItems: 'center',
    color: 'currentColor',
    fontFamily: theme.fonts.din,
    fontSize: vw(14),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop')
    }
  },
  quantityButtons: {
    display: 'flex',
    alignItems: 'center',
    margin: [0, vw(4)],
    [theme.breakpoints.up('md')]: {
      margin: [0, vw(4, 'desktop')]
    }
  },
  adjustButton: {
    color: 'currentColor',
    border: 'none',
    padding: [vw(8), vw(12)],
    [theme.breakpoints.up('md')]: {
      padding: [vw(8, 'desktop'), vw(12, 'desktop')]
    }
  },
  quantityLabel: {
    fontWeight: 300
  },
  quantity: {
    display: 'flex',
    border: [1, 'solid', 'currentColor'],
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: vw(2),
    width: vw(32),
    height: vw(32),
    fontWeight: 300,
    [theme.breakpoints.up('md')]: {
      paddingTop: vw(2, 'desktop'),
      width: vw(32, 'desktop'),
      height: vw(32, 'desktop')
    }
  }
}, { name: 'BuyButtonWithQuantity' })

export default BuyButtonWithQuantity
