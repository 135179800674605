import React, { useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { isCurrentBreakpointMobile } from '../../redux/selectors'
import theme from '../../style/theme'
import { vw } from 'helpers/vw'
import RichText from '../RichText'
import { span } from '../../helpers/span'
import height from 'dom-helpers/height'

const PageContent = ({ slice: { title, copy } }) => {
  const classes = useStyles()
  const titleRef = useRef()
  const sectionRef = useRef()
  const isMobile = useSelector(isCurrentBreakpointMobile)

  useEffect(() => {
    if (!isMobile) {
      // Title runs vertical down the page, so set a min height for the container based on the size of the title
      const vwUnits = (height(titleRef.current) / window.innerWidth) * 100
      sectionRef.current.style.minHeight = `${vwUnits}vw`
    } else {
      sectionRef.current.style.minHeight = ''
    }
  }, [isMobile])

  return (
    <section ref={sectionRef} className={classes.section}>
      <h1 ref={titleRef} className={classes.title}>{title}</h1>
      {copy && <RichText className={classes.copy} content={copy.text} />}
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.beige,
    padding: [vw(160), 0, vw(60)],
    margin: [0, 'auto'],
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      padding: [vw(275, 'desktop'), 0, vw(120, 'desktop')],
      margin: [0, 'auto']
    }
  },
  title: {
    textTransform: 'uppercase',
    lineHeight: 0.8,
    fontSize: vw(60),
    margin: [0, 0, vw(30)],
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
      margin: 0,
      fontSize: vw(185, 'desktop'),
      width: '100vw',
      textAlign: 'right',
      position: 'absolute',
      right: `calc(100% - ${span(4)})`,
      transformOrigin: 'top right',
      transform: 'rotate(-90deg)',
      height: '1.6em',
      overflow: 'hidden'
    }
  },
  copy: {
    fontSize: vw(13),
    lineHeight: 1.4,
    textAlign: 'left',
    fontFamily: theme.fonts.din,
    padding: [0, span(1)],
    [theme.breakpoints.up('md')]: {
      padding: [0, span(4), 0, span(10)],
      fontSize: vw(14, 'desktop')
    },
    '& p': {
      padding: [0, span(1)],
      marginBottom: '1.4em',
      [theme.breakpoints.up('md')]: {
        padding: 0
      }
    },
    '& h2, & h3, & h4, & h5, & h6': {
      fontFamily: theme.fonts.austin,
      textTransform: 'uppercase',
      margin: [vw(30), 0],
      lineHeight: 1,
      [theme.breakpoints.up('md')]: {
        margin: [vw(60, 'desktop'), 0, vw(30, 'desktop')]
      }
    },
    '& h2': {
      fontSize: vw(32),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(45, 'desktop')
      }
    },
    '& a': {
      color: 'currentColor'
    }
  }
}, { name: 'PageContent' })

export default PageContent
