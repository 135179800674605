export const ADD_TO_CART = 'ADD_TO_CART'
export const ADD_BUNDLE_TO_CART = 'ADD_BUNDLE_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const INCREMENT_CART_LINE_ITEM_QUANTITY = 'INCREMENT_CART_LINE_ITEM_QUANTITY'
export const DECREMENT_CART_LINE_ITEM_QUANTITY = 'DECREMENT_CART_LINE_ITEM_QUANTITY'
export const CART_TYPE = 'CART'
export const SAVE_SHOPIFY_CHECKOUT_ID = 'SAVE_SHOPIFY_CHECKOUT_ID'
export const REMOVE_SHOPIFY_CHECKOUT_ID = 'REMOVE_SHOPIFY_CHECKOUT_ID'
export const CHECKOUT_CART = 'CHECKOUT_CART'
export const TOGGLE_CART_DIALOG = 'TOGGLE_CART_DIALOG'
export const OPEN_CART_DIALOG = 'OPEN_CART_DIALOG'
export const CLEAR_CART = 'CLEAR_CART'

export const toggleCartDialog = () => ({
  type: TOGGLE_CART_DIALOG
})

export const openCartDialog = () => ({
  type: OPEN_CART_DIALOG
})

export const addToCartActionCreator = (variantId, quantity) => ({
  type: ADD_TO_CART,
  payload: { variantId, quantity }
})

export const addBundleToCartActionCreator = bundle => ({
  type: ADD_BUNDLE_TO_CART,
  payload: bundle
})

export const incrementCartLineItemQuantityActionCreator = variantId => ({
  type: INCREMENT_CART_LINE_ITEM_QUANTITY,
  payload: variantId
})

export const decrementCartLineItemQuantityActionCreator = variantId => ({
  type: DECREMENT_CART_LINE_ITEM_QUANTITY,
  payload: variantId
})

export const removeFromCartActionCreator = variantId => ({
  type: REMOVE_FROM_CART,
  payload: variantId
})

export const cartActionCreator = (promise) => ({
  type: CART_TYPE,
  meta: { scope: 'cart' },
  payload: promise
})

export const saveShopifyCheckoutIdActionCreator = (promise, id) => ({
  type: SAVE_SHOPIFY_CHECKOUT_ID,
  meta: { id },
  payload: promise
})

export const removeShopifyCheckoutIdActionCreator = (promise) => ({
  type: REMOVE_SHOPIFY_CHECKOUT_ID,
  payload: promise
})

export const checkoutCartActionCreator = () => ({
  type: CHECKOUT_CART
})

export const clearCart = () => ({
  type: CLEAR_CART
})
