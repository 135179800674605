import React, { useState, useRef, useCallback } from 'react'
import cn from 'classnames'
import { loadStripe } from '@stripe/stripe-js'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { isCurrentBreakpointMobile, getShopifySubscriptionsByHandle } from '../redux/selectors'
import theme from '../style/theme'
import { vw } from 'helpers/vw'
import SubscribePopup from './SubscribePopup'
import Button from './Button'

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_API_KEY}`)

const useDelayedPopupState = () => {
  const [show, setShow] = useState(false)
  const closeTimeoutRef = useRef()
  const isMobile = useSelector(isCurrentBreakpointMobile)

  const onShow = useCallback(() => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current)
    }
    setShow(true)
  }, [])

  const onHide = useCallback(() => {
    closeTimeoutRef.current = setTimeout(() => {
      setShow(false)
    }, isMobile ? 0 : 500)
  }, [isMobile])

  return {
    show,
    setShow,
    onShow,
    onHide
  }
}

export const useSubscriptionData = (product) => {
  const shopifySubscriptionId = useSelector(getShopifySubscriptionsByHandle)
  const { slug } = product
  const subscriptionData = shopifySubscriptionId[slug]
  const {
    popup_copy: popupCopy,
    cost_per_month: costPerMonth,
    stripe_subscription_id: subscriptionId
  } = (subscriptionData || {})
  return { popupCopy, costPerMonth, subscriptionId }
}

const SubscribeButton = ({ product, className, text, transparent = true, showPopup = true }) => {
  const classes = useStyles()
  const buttonRef = useRef()
  const [, setStripeError] = useState(null)

  const isMobile = useSelector(isCurrentBreakpointMobile)
  const { show, onShow, setShow, onHide } = useDelayedPopupState()

  const { costPerMonth, popupCopy, subscriptionId } = useSubscriptionData(product)

  if (!subscriptionId || !costPerMonth) return null

  const disable = !product.availableForSale

  const handleClick = async (event) => {
    event.preventDefault()
    if (isMobile && !show) {
      setShow(true)
    } else {
      const stripe = await stripePromise
      setStripeError(null)
      if (!stripe) return null
      const items = [{
        plan: subscriptionId,
        quantity: 1
      }]
      const { error } = await stripe.redirectToCheckout({
        items,
        shippingAddressCollection: {
          allowedCountries: ['AU']
        },
        successUrl: `${window.location.href}/thank-you/`,
        cancelUrl: `${window.location.href}`
      })
      if (error) {
        const { message } = error
        setStripeError(message)
      }
    }
  }
  return (
    <div
      className={cn(classes.container, className)}
      onMouseEnter={!isMobile && showPopup ? onShow : undefined}
      onMouseLeave={!isMobile && showPopup ? onHide : undefined}
    >
      <Button
        ref={buttonRef}
        role='link'
        className={cn(classes.button, { disable })}
        disabled={disable}
        onClick={handleClick}
        transparent={transparent}
      >
        {text || (
          <>
            <div className={classes.buttonText}>Subscription</div>
            <div className={classes.cost}>{`$${costPerMonth} / month`}</div>
          </>
        )}
      </Button>
      {popupCopy && (
        <SubscribePopup
          title='Monthly Subscription'
          copy={popupCopy}
          show={show}
          onClose={onHide}
          subscribeHandler={handleClick}
        />
      )}
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      position: 'relative'
    }
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    '&.disable': {
      cursor: 'default',
      opacity: 0.8
    }
  },
  cost: {
    fontFamily: theme.fonts.austin,
    fontWeight: 300,
    textTransform: 'none',
    fontSize: vw(15),
    marginLeft: vw(5),
    [theme.breakpoints.up('md')]: {
      marginLeft: vw(5, 'desktop'),
      fontSize: vw(15, 'desktop')
    }
  },
  buttonText: {
    margin: 0,
    fontFamily: theme.fonts.din,
    textTransform: 'uppercase',
    // fontSize: vw(12),
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      // fontSize: vw(12, 'desktop')
    }
  }
})

export default SubscribeButton
