import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar'
import config from './config'
import content from './content'
import layout from './layout'
import stockists from './stockists'
import cart from './cart'
import newsletter from './newsletter'
import notifications from './notifications'

export default {
  config,
  content,
  layout,
  loadingBar,
  stockists,
  cart,
  newsletter,
  notifications
}
