import {
  TOGGLE_STOCKIST_DIALOG,
  OPEN_STOCKIST_DIALOG,
  CLOSE_STOCKIST_DIALOG,
  STOCKISTS_PLACE_CHANGED
} from '../actions'

const initialState = {
  stockistDialogOpen: false,
  place: null
}

const stockists = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_STOCKIST_DIALOG:
      return {
        ...state,
        stockistDialogOpen: !state.stockistDialogOpen
      }
    case OPEN_STOCKIST_DIALOG:
      return {
        ...state,
        stockistDialogOpen: true
      }
    case CLOSE_STOCKIST_DIALOG:
      return {
        ...state,
        stockistDialogOpen: false
      }
    case STOCKISTS_PLACE_CHANGED:
      return {
        ...state,
        place: action.payload
      }
    default:
      return state
  }
}

export default stockists
