import { SUBSCRIBE_REQUEST, SUBSCRIBE_TYPE_FULFILLED } from '../actions'
import { sendSubscribeRequest } from '../api'
import { trackEvent, PIXEL_EVENT_SUBSCRIBE } from '../../helpers/pixel'

export default store => next => action => {
  const ret = next(action)
  switch (action.type) {
    case SUBSCRIBE_REQUEST:
      sendSubscribeRequest(store.dispatch, store.getState, action.payload)
      break
    case SUBSCRIBE_TYPE_FULFILLED:
      trackEvent(PIXEL_EVENT_SUBSCRIBE)
      break
    default:
      break
  }
  return ret
}
