import React, { forwardRef, useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from '../ResponsiveImage'
import theme from 'style/theme'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'

const useStyles = createUseStyles({
  wrapper: {
    padding: [vw(40), 0],
    [theme.breakpoints.up('md')]: {
      padding: [vw(70, 'desktop'), 0, vw(100, 'desktop')]
    }
  },
  figure: {
    margin: 0,
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end'
    }
  },
  figcaption: {
    position: 'relative',
    zIndex: 1,
    background: theme.colors.white,
    width: span(20),
    marginTop: vw(-100),
    marginLeft: span(2),
    padding: [span(2.2), span(2), span(2)],
    [theme.breakpoints.up('md')]: {
      width: span(8),
      margin: [0, span(-1), 0, 0],
      padding: [span(1.2), span(1), span(1)]
    }
  },
  title: {
    fontSize: vw(30),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(35, 'desktop'),
      width: span(5, 6)
    }
  },
  image: {
    [theme.breakpoints.up('md')]: {
      width: span(15)
    }
  },
  list: {
    margin: 0,
    paddingLeft: '1em',
    fontFamily: theme.fonts.din,
    textTransform: 'uppercase',
    marginTop: vw(55),
    fontSize: vw(12),
    listStyle: 'none',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginTop: vw(210, 'desktop'),
      fontSize: vw(12, 'desktop'),
      width: span(4, 6)
    }
  },
  listEntry: {
    marginBottom: '1em',
    '&:before': {
      content: '"-"',
      position: 'absolute',
      left: 0
    }
  }
}, { name: 'Recipe' })

const Recipe = forwardRef(({ slice, page, nav }, ref) => {
  const { title, image, ingredient } = slice
  const classes = useStyles()
  const mainRef = useRef()

  useEffect(() => {
    const current = nav.current
    current.observeBrightSlice(mainRef)
    return () => { current.unobserveBrightSlice(mainRef) }
  }, [])

  return (
    <section ref={mainRef} className={classes.wrapper}>
      <figure className={classes.figure}>
        <figcaption className={classes.figcaption}>
          <h2 className={classes.title}>{title}</h2>
          <ul className={classes.list}>
            {ingredient.map((v, index) => <li className={classes.listEntry} key={index}>{v}</li>)}
          </ul>
        </figcaption>
        {image && <ResponsiveImage className={classes.image} {...image} aspect={1004 / 900} />}
      </figure>
    </section>
  )
})

export default Recipe
