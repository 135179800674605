import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { vw } from 'helpers/vw'
import theme from '../../../style/theme'
import RadioButton from './RadioButton'
import TextField from './TextField'
import Color from 'color'
import { useFormikContext, ErrorMessage } from 'formik'

const IndustryField = ({ industryField, className, ...rest }) => {
  const classes = useStyles()
  const { values, setFieldValue } = useFormikContext()
  const { industry } = values
  const {
    healthcare,
    retailer,
    other
  } = industryField

  const healthcareActive = industry === 'healthcare'
  const retailerActive = industry === 'retailer'
  const otherActive = industry === 'other'

  const onHealthcareChanged = useCallback(() => {
    setFieldValue('industry', 'healthcare')
  }, [])

  const onRetailerChanged = useCallback(() => {
    setFieldValue('industry', 'retailer')
  }, [])

  const onOtherChanged = useCallback(() => {
    setFieldValue('industry', 'other')
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.radioButtonContainer}>
        <RadioButton
          value={healthcareActive}
          onChange={onHealthcareChanged}
          label={healthcare}
          className={cn(classes.radioButton, { active: healthcareActive })}
        />
        <RadioButton
          value={retailerActive}
          onChange={onRetailerChanged}
          label={retailer}
          className={cn(classes.radioButton, { active: retailerActive })}
        />
        <div className={classes.otherContainer}>
          <RadioButton
            value={otherActive}
            onChange={onOtherChanged}
            label='Other'
            className={cn(classes.radioButton, { active: otherActive })}
          />
          <TextField name='other' label={other} className={classes.other} disabled={!otherActive} highlighted={otherActive} onFocus={onOtherChanged} />
        </div>
      </div>
      <div className={classes.errorContainer}>
        <ErrorMessage name='industry' component='span' />
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    marginBottom: vw(32),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(32, 'desktop')
    }
  },
  errorContainer: {
    width: '100%',
    margin: [vw(8), 0, 0],
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      margin: [vw(8, 'desktop'), 0, 0]
    }
  },
  radioButton: {
    fontFamily: theme.fonts.austin,
    fontSize: vw(14),
    padding: [vw(16), vw(12)],
    border: [1, 'solid', Color(theme.colors.beige).string()],
    opacity: 0.5,
    marginBottom: -1,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop'),
      padding: [vw(16, 'desktop'), vw(12, 'desktop')]
    },
    '&.active': {
      opacity: 1
    },
    '& label': {
      fontSize: vw(18),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(25, 'desktop')
      }
    }
  },
  otherContainer: {
    display: 'flex'
  },
  other: {
    margin: 0,
    overflow: 'visible',
    '& input': {
      borderLeftWidth: 0,
      height: 'calc(100% + 1px)'
    }
  }
}, { name: 'IndustryField' })

export default IndustryField
