import get from 'lodash/get'
import { REHYDRATED } from '../../actions'
import { hideLoading } from 'react-redux-loading-bar'

export default store => next => action => {
  if (action.type === REHYDRATED && get(store.getState(), ['loadingBar', 'page']) > 0) {
    // showLoading() was dispatched on the server from routesMap.js to display the loading bar pre-hydration on the
    // client. Now we can hide it.
    setTimeout(() => {
      store.dispatch(hideLoading('page'))
    }, 400)
  }
  return next(action)
}
