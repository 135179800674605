import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react'
import map from 'lodash/map'
import compact from 'lodash/compact'
import { useSelector, useDispatch } from 'react-redux'
import {
  getPageContent,
  getPageId,
  isContentBusy,
  getPageSlices
} from '../redux/selectors'
import Footer from './Footer'
import { SwitchTransition, Transition } from 'react-transition-group'
import { updateScroll } from 'redux-first-router'
import { beginRouteExitTransitionCreator, routeExitTransitionEndedCreator } from '../redux/actions'

import Hero from './Slices/Hero'
import ShopHero from './Slices/ShopHero'
import RichText from './Slices/RichText'
import ImageSlice from './Slices/ImageSlice'
import ProductCollection from './Slices/ProductCollection'
import Recipe from './Slices/Recipe'
import Quote from './Slices/Quote'
import Banner from './Slices/Banner'
import ArticleSlider from './Slices/ArticleSlider'
import ArticleList from './Slices/ArticleList'
import DiptychBright from './Slices/DiptychBright'
import DiptychDark from './Slices/DiptychDark'
import NextArticle from './Slices/NextArticle'
import CardListing from './Slices/CardListing'
import ProductHero from './Slices/ProductHero'
import ProductSlider from './Slices/ProductSlider'
import BundleSlider from './Slices/BundleSlider'
import PageContent from './Slices/PageContent'
import MemberForm from './Slices/MemberForm'
import PromptBanner from './Slices/PromptBanner'

import SmoothScroll from './SmoothScroll'
import Nav from './Nav'
import CookiesBanner from './CookiesBanner'
import NotificationBanner from './NotificationBanner'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'

import gsap from 'gsap'
import color from 'color'
import BlogRichTextSlice from './Slices/BlogRichText'
import BlogImageSlice from './Slices/BlogImageSlice'
import VideoEmbedSlice from './Slices/VideoEmbedSlice'
import MuxVideoSlice from './Slices/MuxVideoSlice'
import ProductToolbar from './Slices/ProductToolbar'
import ShopInStoreSlice from './Slices/ShopInStoreSlice'

const sliceComponentSelector = {
  hero_slice: Hero,
  shop_hero_slice: ShopHero,
  rich_text: RichText,
  image_slice: ImageSlice,
  product_listing_slice: ProductCollection,
  quote_slice: Quote,
  banner: Banner,
  article_slider_slice: ArticleSlider,
  recipe: Recipe,
  article_list_slice: ArticleList,
  diptych_bright_slice: DiptychBright,
  diptych_dark_slice: DiptychDark,
  next_article_slice: NextArticle,
  cards_listing_slice: CardListing,
  product_hero_slice: ProductHero,
  product_slider_slice: ProductSlider,
  page_content_slice: PageContent,
  bundle_slider_slice: BundleSlider,
  member_form_slice: MemberForm,
  prompt_banner: PromptBanner,
  blog_rich_text: BlogRichTextSlice,
  blog_image_slice: BlogImageSlice,
  vimeo_embed: VideoEmbedSlice,
  video: MuxVideoSlice,
  product_toolbar: ProductToolbar,
  shop_in_store_slice: ShopInStoreSlice
}

const usePageTransition = () => {
  const [transitionState, setTransitionState] = useState()
  const transitionCompleteRef = useRef()
  const busy = useSelector(isContentBusy)
  const curtainRef = useRef()
  const transitionRef = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    if (busy) {
      dispatch(beginRouteExitTransitionCreator())
      setTransitionState('begin-out')
      const tl = gsap.timeline()
      tl.fromTo(curtainRef.current, { x: '100%' }, {
        x: '0%',
        duration: 0.8,
        ease: 'quart.in',
        onComplete: () => {
          setTransitionState('end-out')
        }
      }, 0)
      tl.to(transitionRef.current, {
        x: '-25%',
        duration: 0.8,
        ease: 'quart.in'
      }, 0)
    }
  }, [busy])

  useEffect(() => {
    if (!busy && transitionState === 'end-out') {
      dispatch(routeExitTransitionEndedCreator())
      updateScroll()
      if (transitionCompleteRef.current) {
        transitionCompleteRef.current()
        transitionCompleteRef.current = null
      }
      setTransitionState('begin-in')
      const tl = gsap.timeline({
        onComplete: () => {
          setTransitionState('end-in')
        },
        delay: 0.1
      })
      tl.to(curtainRef.current, {
        x: '-100%',
        ease: 'expo.out',
        duration: 1
      }, 0)
      tl.fromTo(transitionRef.current, { x: '25%' }, {
        x: '0%',
        ease: 'expo.out',
        duration: 1,
        clearProps: 'transform'
      }, 0)
    }
  }, [busy, transitionState])

  const endTransitionCallback = useCallback((node, done) => {
    if (transitionState === 'end-out') {
      done()
    } else {
      transitionCompleteRef.current = done
    }
  }, [transitionState, busy])

  const onEnter = useCallback(node => {
    gsap.fromTo(transitionRef.current, { x: '25%' }, {
      x: '0%',
      ease: 'expo.out',
      duration: 1,
      clearProps: 'transform'
    }, 0)
  }, [])

  return { curtainRef, transitionRef, endTransitionCallback, onEnter }
}

const Page = ({ className }) => {
  const classes = useStyles()
  const slices = useSelector(getPageSlices)
  const page = useSelector(getPageContent)
  const id = useSelector(getPageId)
  const navRef = useRef()

  const sliceComponents = useMemo(() => compact(map(slices, (slice, index) => {
    const Component = sliceComponentSelector[slice.type]

    if (Component) {
      return <Component key={index} slice={slice} page={page} nav={navRef} index={index} />
    }
  })), [slices])

  const { curtainRef, transitionRef, endTransitionCallback, onEnter } = usePageTransition()

  return (
    <>
      <div ref={curtainRef} className={classes.curtain} />
      <NotificationBanner />
      <CookiesBanner />
      <SwitchTransition>
        <Transition
          key={id}
          addEndListener={endTransitionCallback}
          onEnter={onEnter}
        >
          <main className={className}>
            <Nav ref={navRef} />
            <SmoothScroll>
              <div ref={transitionRef} className={classes.transitionWrapper} id={id}>
                {sliceComponents}
              </div>
              <Footer />
            </SmoothScroll>
          </main>
        </Transition>
      </SwitchTransition>
    </>
  )
}

const useStyles = createUseStyles({
  curtain: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: color(theme.colors.primary).lighten(0.1).hex(),
    transform: 'translateX(-100%)',
    zIndex: theme.zIndex.pageLoadingBar
  },
  transitionWrapper: {
    position: 'relative'
  }
})

export default Page
