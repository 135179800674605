import React from 'react'
import { createUseStyles } from 'react-jss'
import { vw } from '../helpers/vw'
import theme from '../style/theme'
import range from 'lodash/range'
import Flickity from 'react-flickity-component'
import { span } from '../helpers/span'

const Star = () => {
  const classes = useStyles()
  return (
    <svg className={classes.star} viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9.05877 0L11.1243 5.87796L17.8086 5.87796L12.4009 9.51075L14.4664 15.3887L9.05877 11.7559L3.65111 15.3887L5.71665 9.51075L0.308987 5.87796L6.99323 5.87796L9.05877 0Z' fill='currentColor' />
    </svg>
  )
}

const Stars = ({ stars }) => {
  const classes = useStyles()
  const items = range(parseInt(stars))
  return (
    <span className={classes.stars}>
      {items.map((_, i) => <Star key={i} />)}
    </span>
  )
}

const flickityOptions = {
  prevNextButtons: false,
  pageDots: false,
  contain: true,
  cellAlign: 'left'
}

export default ({ reviews }) => {
  const classes = useStyles()
  return (
    <div className={classes.container} id='reviews'>
      <Flickity options={flickityOptions}>
        {reviews.map((review, i) => (
          <div key={i} className={classes.content}>
            <span className={classes.reviewer}>{review.reviewer}</span>
            <span className={classes.review}>{review.review}</span>
            <Stars stars={review.stars} />
          </div>
        ))}
      </Flickity>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    padding: [0, span(2)],
    margin: [0, 0, vw(60)],
    display: 'block',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      margin: [0, span(-0.5), vw(60, 'desktop')],
      padding: [0, span(4)]
    }
  },
  content: {
    margin: [0, span(2)],
    width: '80%',
    whiteSpace: 'normal',
    [theme.breakpoints.up('md')]: {
      margin: [0, span(0.5)],
      width: span(7)
    }
  },
  reviewer: {
    textTransform: 'uppercase',
    display: 'block',
    fontFamily: theme.fonts.din,
    fontSize: vw(12),
    marginBottom: vw(24),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(24, 'desktop'),
      fontSize: vw(12, 'desktop')
    }
  },
  review: {
    display: 'block',
    fontSize: vw(20),
    marginBottom: vw(24),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(24, 'desktop'),
      fontSize: vw(20, 'desktop')
    }
  },
  stars: {
    display: 'flex'
  },
  star: {
    width: vw(18),
    height: vw(16),
    [theme.breakpoints.up('md')]: {
      width: vw(18, 'desktop'),
      height: vw(16, 'desktop')
    }
  }
}, { name: 'Reviews' })
