import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import cn from 'classnames'

const InViewAnimate = (props) => {
  const [className, setClassName] = useState(null)

  const states = {
    in: 'is-in',
    out: 'is-out',
    outUp: 'is-out-up',
    outDown: 'is-out--down'
  }

  const handleChange = (inView, entry) => {
    if (inView) {
      setClassName(`${states.in}`)
    } else if (entry.boundingClientRect.y < 0) {
      setClassName(`${states.out} ${states.outUp}`)
    } else {
      setClassName(`${states.out} ${states.outDown}`)
    }

    if (props.onChange) props.onChange(inView, entry)
  }

  return (
    <InView onChange={handleChange} rootMargin={props.rootMargin} threshold={props.threshold} triggerOnce={props.triggerOnce} className={cn(props.className, className)}>
      {props.children}
    </InView>
  )
}

export default InViewAnimate
