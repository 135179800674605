import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import isEmpty from 'lodash/isEmpty'
import { vw } from '../helpers/vw'
import ResponsiveImage from './ResponsiveImage'
import BuyButton from './BuyButton'
import cn from 'classnames'

const Item = ({ product, title, copy, cart_copy: cartCopy, image, showAddToCartButton, showCartMessage, titleClassName, imageClassName }) => {
  const classes = useStyles()

  return (
    <div className={classes.item}>
      <ResponsiveImage {...image} className={cn(classes.image, imageClassName)} />
      <div className={classes.itemContent}>
        <span className={cn(titleClassName, classes.title)}>{title}</span>
        <p className={classes.copy}>{showCartMessage ? cartCopy || copy : copy}</p>
        {showAddToCartButton && (
          <BuyButton buttonClassName={classes.button} product={product} text='Add Free Sample' />
        )}
      </div>
    </div>
  )
}

export default ({ items, className, showAddToCartButton = false, showCartMessage = false, titleClassName, imageClassName }) => {
  const classes = useStyles()
  if (isEmpty(items)) return null
  return (
    <div className={cn(className, classes.container)}>
      {items.map((item, key) => (
        <Item
          key={key}
          {...item}
          showAddToCartButton={showAddToCartButton}
          showCartMessage={showCartMessage}
          titleClassName={titleClassName}
          imageClassName={imageClassName}
        />
      ))}
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    fontFamily: theme.fonts.din,
    marginTop: vw(48),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(48, 'desktop')
    }
  },
  item: {
    display: 'flex'
  },
  itemContent: {
    marginLeft: vw(20),
    [theme.breakpoints.up('md')]: {
      marginLeft: vw(20, 'desktop')
    }
  },
  title: {
    fontSize: vw(16),
    marginBottom: vw(8),
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(8, 'desktop'),
      fontSize: vw(16, 'desktop')
    }
  },
  copy: {
    fontSize: vw(14),
    lineHeight: 1.3,
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: vw(14, 'desktop')
    }
  },
  image: {
    flexShrink: 0,
    width: vw(70),
    height: vw(65),
    borderRadius: vw(10),
    [theme.breakpoints.up('md')]: {
      borderRadius: vw(10, 'desktop'),
      width: vw(70, 'desktop'),
      height: vw(65, 'desktop')
    },
    '& picture:before': {
      paddingTop: 0
    }
  },
  button: {
    padding: [vw(8), vw(48), vw(6)],
    marginTop: vw(6),
    fontSize: vw(10),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(10, 'desktop'),
      padding: [vw(8, 'desktop'), vw(48, 'desktop'), vw(6, 'desktop')],
      marginTop: vw(6, 'desktop')
    }
  }
})
