export const TOGGLE_STOCKIST_DIALOG = 'TOGGLE_STOCKIST_DIALOG'
export const OPEN_STOCKIST_DIALOG = 'OPEN_STOCKIST_DIALOG'
export const CLOSE_STOCKIST_DIALOG = 'CLOSE_STOCKIST_DIALOG'
export const STOCKISTS_PLACE_CHANGED = 'STOCKISTS_PLACE_CHANGED'

export const toggleStockistDialog = () => ({ type: TOGGLE_STOCKIST_DIALOG })
export const openStockistDialog = () => ({ type: OPEN_STOCKIST_DIALOG })
export const closeStockistDialog = () => ({ type: CLOSE_STOCKIST_DIALOG })
export const stockistPlaceChanged = (place) => ({
  type: STOCKISTS_PLACE_CHANGED,
  payload: place
})
