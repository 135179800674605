import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { getGlobalContent } from './content'
import { createSelector } from 'reselect'

export const getNotificationDuration = state => get(getGlobalContent(state), ['notifications', 'duration'])
export const hasNotifications = state => !isEmpty(get(getGlobalContent(state), ['notifications', 'notifications']))

export const isCookieAccepted = state => get(state, ['notifications', 'cookieAccepted'])

export const getNotifications = createSelector(
  isCookieAccepted,
  state => get(getGlobalContent(state), ['notifications', 'notifications']),
  (cookieAccepted, notifications) => {
    if (cookieAccepted === undefined) return null
    if (!cookieAccepted) {

    }
    return notifications
  }
)
