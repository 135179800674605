import React, { forwardRef, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import isEmpty from 'lodash/isEmpty'
import ResponsiveImage from '../ResponsiveImage'
import theme from '../../style/theme'
import { span } from '../../helpers/span'
import { vw } from '../../helpers/vw'
import Button from '../Button'
import { resolveLink } from 'helpers/resolveLink'
import RichText from '../RichText'

const useStyles = createUseStyles({
  wrapper: {
  },
  figure: {
    display: 'flex',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },
  figcaption: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: [vw(60), span(2)],
    [theme.breakpoints.up('md')]: {
      padding: [span(3)],
      width: span(13)
    }
  },
  title: {
    textTransform: 'uppercase',
    lineHeight: 1,
    fontSize: vw(40),
    marginBottom: vw(40),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(40, 'desktop'),
      marginBottom: vw(40, 'desktop'),
      width: span(5, 7)
    }
  },
  copy: {
    '& p': {
      lineHeight: 1,
      fontSize: vw(30),
      marginBottom: vw(30),
      [theme.breakpoints.up('md')]: {
        fontSize: vw(30, 'desktop'),
        marginBottom: vw(30, 'desktop')
      }
    }
  },
  image: {
    '&:before': {
      [theme.breakpoints.down('md')]: {
        paddingTop: '250%'
      }
    },
    [theme.breakpoints.up('md')]: {
      width: span(11)
    }
  },
  button: {
    flexGrow: 0,
    flexShrink: 0,
    marginTop: vw(24),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: vw(30, 'desktop')
    }
  }
}, { name: 'DiptychBright' })

const DiptychBright = forwardRef(({ slice, page, nav }, ref) => {
  const { title, image, copy, link } = slice
  const classes = useStyles()
  const mainRef = useRef()

  useEffect(() => {
    const current = nav.current
    current.observeBrightSlice(mainRef)
    return () => { current.unobserveBrightSlice(mainRef) }
  }, [])

  return (
    <section ref={mainRef} className={classes.wrapper}>
      <figure className={classes.figure}>
        <figcaption className={classes.figcaption}>
          <h2 className={classes.title}>{title}</h2>
          {copy && copy.text && <RichText content={copy.text} className={classes.copy} />}
          {!isEmpty(link) && <Button className={classes.button} link={resolveLink(link[0])} />}
        </figcaption>
        <ResponsiveImage className={classes.image} {...image} parallax={{ ratio: theme.parallax.ratio, scale: theme.parallax.scale }} />
      </figure>
    </section>
  )
})

export default DiptychBright
