import { useEffect } from 'react'

const Store = () => {}
Store.ref = null
Store.rafs = []

const useRaf = (callback, dep = []) => {
  useEffect(() => {
    const animate = time => {
      Store.rafs.forEach(f => f(time))
      Store.ref = window.requestAnimationFrame(animate)
    }

    Store.rafs.unshift(callback)
    if (!Store.ref) Store.ref = window.requestAnimationFrame(animate)

    return () => {
      Store.rafs.splice(Store.rafs.indexOf(callback), 1)

      if (!Store.rafs.length) {
        window.cancelAnimationFrame(Store.ref)
        Store.ref = null
      }
    }
  }, dep)
}

export default useRaf
