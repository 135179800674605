import React, { forwardRef, useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import get from 'lodash/get'
import cn from 'classnames'
import theme from '../../style/theme'
import ResponsiveImage from '../ResponsiveImage'
import { vw } from '../../helpers/vw'
import { span } from '../../helpers/span'

const BlogImageSlice = forwardRef(({
  className,
  slice: { image },
  nav
}, ref) => {
  const classes = useStyles()
  const mainRef = useRef()
  useEffect(() => {
    const current = nav.current
    current.observeBrightSlice(mainRef)
    return () => { current.unobserveBrightSlice(mainRef) }
  }, [])

  return image
    ? (
      <section className={cn(className, classes.wrapper)} ref={mainRef}>
        <ResponsiveImage className={cn(classes.image, { portrait: get(image, ['aspect'], 0) <= 1 })} {...image} />
      </section>
    ) : null
})

const useStyles = createUseStyles({
  wrapper: {
    padding: [0, 0, vw(30)],
    [theme.breakpoints.up('md')]: {
      padding: [0, 0, vw(80, 'desktop')]
    }
  },
  image: {
    margin: 'auto',
    width: span(22),
    '&.portrait': {
      width: span(18)
    },
    [theme.breakpoints.up('md')]: {
      width: span(20),
      '&.portrait': {
        width: span(12)
      }
    }
  }
}, { name: 'BlogImageSlice' })

export default BlogImageSlice
