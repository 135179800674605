import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import flatten from 'lodash/flatten'
import compact from 'lodash/compact'
import remove from 'lodash/remove'
import find from 'lodash/find'
import uniqBy from 'lodash/uniqBy'
import { getCartLineItems, getProductDefaultsByHandle } from '../../redux/selectors'

export default () => {
  const cartItems = useSelector(getCartLineItems)
  const productDefaults = useSelector(getProductDefaultsByHandle)
  const items = useMemo(() => {
    var compItems = compact(flatten(cartItems.map(({ product: { slug } }) => get(productDefaults, [slug, 'complimentary_items']))))
    compItems = remove(compItems, x => !find(cartItems, c => c.product.slug === x.handle))
    compItems = uniqBy(compItems, x => x.handle)
    return compItems
  }, [productDefaults, cartItems])
  return items
}
