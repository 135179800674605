import React, { forwardRef, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from '../ResponsiveImage'
import theme from 'style/theme'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'
import { getPageTitle } from 'redux/selectors/index'
import { useSelector } from 'react-redux'
import RichText from 'components/RichText'
import Link from 'components/Link'
import OvalCopy from '../OvalCopy'
import isEmpty from 'lodash/isEmpty'
import cn from 'classnames'

const useStyles = createUseStyles({
  header: {
    overflow: 'hidden',
    '&.noContent': {
      backgroundColor: theme.colors.primary,
      paddingBottom: vw(540),
      [theme.breakpoints.up('md')]: {
        paddingBottom: vw(140, 'desktop')
      }
    }
  },
  image: {
    position: 'relative',
    zIndex: 2,
    background: theme.colors.primary,
    overflow: 'visible',
    height: '100vh',
    '& picture': {
      [theme.breakpoints.down('md')]: {
        left: 0,
        top: 0,
        width: '100%',
        height: '100vh'
      }
    },
    '&:before': {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  },
  oval: {
    left: '50%',
    top: '100%',
    transform: 'translateX(-50%)',
    marginTop: vw(-160),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(-560, 'desktop')
    }
  },
  copyWrapper: {
    background: theme.colors.primary,
    color: theme.colors.white,
    padding: [vw(600), span(1), vw(60)],
    [theme.breakpoints.up('md')]: {
      padding: [vw(180, 'desktop'), span(4), vw(80, 'desktop')]
    },
    '& p': {
      lineHeight: 1.1,
      fontSize: vw(30),
      marginBottom: vw(30),
      [theme.breakpoints.up('md')]: {
        marginBottom: vw(80, 'desktop'),
        fontSize: vw(45, 'desktop')
      }
    },
    '& a': {
      color: theme.colors.secondary,
      textDecoration: 'none',
      fontStyle: 'italic'
    }
  },
  logoLabel: {
    fontFamily: theme.fonts.din,
    fontSize: vw(10),
    lineHeight: 1.2,
    display: 'flex',
    alignItems: 'center',
    margin: [0, vw(16), vw(16), 0],
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      margin: [vw(4, 'desktop'), vw(32, 'desktop'), 0, 0],
      fontSize: vw(10, 'desktop')
    }
  },
  logos: {
    display: 'flex',
    marginTop: vw(30),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center'
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginTop: vw(60, 'desktop')
    }
  },
  logoWrapper: {
    marginBottom: vw(20),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginRight: vw(15, 'desktop')
    },
    '&:last-child': {
      margin: 0
    }
  },
  logo: {
    height: vw(40),
    [theme.breakpoints.up('md')]: {
      height: vw(40, 'desktop'),
      marginRight: vw(15, 'desktop')
    },
    '& picture, & img': {
      height: '100%'
    },
    '& img': {
      position: 'relative',
      width: 'auto'
    },
    '& picture:before': {
      display: 'none'
    }
  }
}, { name: 'ShopHero' })

const ShopHero = forwardRef(({ slice, disableLazy, page, nav }, ref) => {
  const { copy, title, excerpt, image, label, logos, vector_image: vector } = slice
  const pageTitle = useSelector(getPageTitle)
  const classes = useStyles()

  const logoComponents = useMemo(() => {
    return logos ? logos.map((logo, i) => {
      const { image, url } = logo
      const Container = logo.url ? Link : ({ to, target, ...props }) => <span {...props} />
      return <Container key={i} to={url} target='_blank' className={classes.logoWrapper}><ResponsiveImage className={classes.logo} {...image} /></Container>
    }) : undefined
  }, [logos])

  return (
    <header className={cn(classes.header, { noContent: isEmpty(logos) })}>
      <ResponsiveImage className={classes.image} {...image} disableLazy={disableLazy}>
        <OvalCopy className={classes.oval} title={title || pageTitle} vector={vector} excerpt={excerpt} />
      </ResponsiveImage>
      {!isEmpty(logos) && (
        <div className={classes.copyWrapper}>
          {copy && <RichText className={classes.copy} content={copy.text} />}
          <div className={classes.logos}>
            {label && <span className={classes.logoLabel}>{label}</span>}
            {logoComponents}
          </div>
        </div>
      )}
    </header>
  )
})

export default ShopHero
