import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from './ResponsiveImage'
import cn from 'classnames'
import theme from 'style/theme'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'
import RichText from 'components/RichText'

const useStyles = createUseStyles({
  wrapper: {
    position: 'absolute',
    zIndex: 2,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    width: span(38),
    [theme.breakpoints.up('md')]: {
      width: span(10)
    }
  },
  content: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: [0, vw(130)],
    color: theme.colors.primary,
    [theme.breakpoints.up('md')]: {
      padding: [0, vw(150, 'desktop')]
    }
  },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    lineHeight: 1,
    fontSize: vw(35),
    padding: [0, span(1)],
    [theme.breakpoints.up('md')]: {
      fontSize: vw(35, 'desktop'),
      padding: [0, span(3)]
    }
  },
  excerpt: {
    lineHeight: 1,
    fontSize: vw(30),
    marginTop: vw(15),
    [theme.breakpoints.up('md')]: {
      lineHeight: 1.2,
      fontSize: vw(25, 'desktop'),
      marginTop: vw(15, 'desktop')
    }
  }
}, { name: 'OvalCopy' })

const OvalCopy = forwardRef(({ className, vector, classNames = {}, title, excerpt }, ref) => {
  const classes = useStyles()

  return (
    <div className={cn(classes.wrapper, className)} ref={ref}>
      <div className={classes.content}>
        <h1 className={classes.title}>{title}</h1>
        {excerpt && <RichText className={classes.excerpt} content={excerpt.text} />}
      </div>
      <ResponsiveImage className={classNames.vector} {...vector} />
    </div>
  )
})

export default OvalCopy
