import React, { forwardRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import map from 'lodash/map'
import theme from '../../style/theme'
import { span } from '../../helpers/span'
import { vw } from '../../helpers/vw'
import ResponsiveImage from '../ResponsiveImage'
import Flickity from 'react-flickity-component'
import Link from '../Link'
import { resolveLink } from '../../helpers/resolveLink'
import { formatPrice } from '../../helpers/format'
import detectIt from 'detect-it'
import { back, quad } from '../../style/eases'
import Button from '../Button'

const flickityOptions = {
  prevNextButtons: false,
  pageDots: false,
  contain: true,
  cellAlign: 'center'
}

const SliderItem = ({
  classes,
  showInfo,
  showBuyButton,
  item,
  onBuyClick,
  index
}) => {
  const {
    title: productTitle,
    price,
    comparePrice,
    info: productInfo,
    buyButtonText,
    link,
    image,
    disable
  } = item
  const savings = comparePrice > 0 ? comparePrice - price : null

  const handleBuyClick = useCallback(() => {
    onBuyClick(item, index)
  }, [item, onBuyClick])

  return (
    <div className={classes.product}>
      <Link link={link && resolveLink(link)} className={classes.productLink} nonLinkTag='span'>
        {image && <div className={classes.imageContainer}><ResponsiveImage {...image} className={classes.productImage} /></div>}
        {productTitle && <span className={classes.productTitle} link={resolveLink(link)}>{productTitle}</span>}
        {price && (
          <span className={classes.price}>
            {formatPrice(price)}{!!savings && <span className={classes.savings}>(Save {formatPrice(savings)})</span>}
          </span>
        )}
        {showInfo && productInfo && <div className={classes.productInfo} dangerouslySetInnerHTML={{ __html: productInfo }} />}
      </Link>
      {showBuyButton && <Button className={cn(classes.button, { disable })} onClick={handleBuyClick}>{buyButtonText || 'Add To Cart'}</Button>}
    </div>
  )
}

const Slider = forwardRef(({
  className,
  items,
  minAspect,
  title,
  showInfo = false,
  showBuyButton = false,
  onBuyClick
}, ref) => {
  const classes = useStyles({ aspect: minAspect })

  return (
    <section className={cn(className, classes.section)}>
      {title && (
        <h2 className={classes.title} dangerouslySetInnerHTML={{ __html: title }} />
      )}
      <Flickity static options={flickityOptions} className={classes.slider}>
        {map(items, (item, i) => (
          <SliderItem
            key={i}
            index={i}
            classes={classes}
            item={item}
            showInfo={showInfo}
            showBuyButton={showBuyButton}
            onBuyClick={onBuyClick}
          />
        ))}
      </Flickity>
    </section>
  )
})

const useStyles = createUseStyles({
  section: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.beige,
    textAlign: 'center',
    padding: [vw(40), span(2), vw(30)],
    margin: [-1, 0],
    [theme.breakpoints.up('md')]: {
      padding: [vw(100, 'desktop'), span(2)]
    },
    '&:first-child': {
      padding: [vw(190), span(2), vw(60)],
      [theme.breakpoints.up('md')]: {
        padding: [vw(220, 'desktop'), span(2), vw(100, 'desktop')]
      }
    }
  },
  slider: {
    display: 'flex',
    outline: 'none',
    alignItems: 'flex-end',
    '&.flickity-enabled': {
      position: 'relative'
    },
    '& .flickity-enabled:focus': {
      outline: 'none'
    },
    '& .flickity-viewport': {
      position: 'relative',
      width: '100%',
      height: '100%',
      outline: 'none'
    },
    '& .flickity-slider': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      outline: 'none',
      display: 'flex',
      alignItems: 'flex-end'
    },
    '& .flickity-enabled.is-draggable': {
      'user-select': 'none'
    },
    '& .flickity-enabled.is-draggable .flickity-viewport': {
      cursor: 'grab'
    },
    '& .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down': {
      cursor: 'grabbing'
    }
  },
  title: {
    fontFamily: theme.fonts.austin,
    fontSize: vw(45),
    lineHeight: 0.8,
    textTransform: 'uppercase',
    marginBottom: vw(32),
    display: 'block',
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(64, 'desktop'),
      fontSize: vw(145, 'desktop')
    }
  },
  product: {
    width: span(20),
    textAlign: 'center',
    color: 'currentColor',
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: '25%'
    },
    ...(detectIt.primaryInput !== 'touch' ? {
      '& $productImage:before': {
        content: '""',
        position: 'absolute',
        width: vw(476, 'desktop'),
        height: vw(476, 'desktop'),
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%) scale(0.9)',
        backgroundColor: '#381F30',
        borderRadius: '50%',
        opacity: 0,
        transition: `opacity 0.25s ${quad.out}, transform 0.25s ${back.out}`,
        zIndex: -100
      },
      '&:hover $productImage:before': {
        opacity: 1,
        transform: 'translate(-50%, -50%) scale(1)'
      },
      '& $productImage img': {
        transition: `transform 0.25s ${back.out}`
      },
      '&:hover:nth-child(odd) $productImage img': {
        transform: 'rotate(5deg) scale(1.05)'
      },
      '&:hover:nth-child(even) $productImage img': {
        transform: 'rotate(-5deg) scale(1.05)'
      }
    } : {})
  },
  productLink: {
    color: 'currentColor',
    textDecoration: 'none'
  },
  imageContainer: {
    position: 'relative',
    '&:before': {
      display: 'block',
      content: '""',
      paddingTop: ({ aspect }) => aspect ? `${(100 / aspect)}%` : undefined
    }
  },
  productImage: {
    overflow: 'visible',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(8, 'desktop')
    }
  },
  productTitle: {
    color: 'currentColor',
    fontSize: vw(25),
    lineHeight: 1,
    textTransform: 'uppercase',
    margin: [0, 'auto', vw(12)],
    display: 'block',
    textDecoration: 'none',
    maxWidth: vw(240),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(25, 'desktop'),
      margin: [0, 'auto', vw(12, 'desktop')],
      maxWidth: vw(240, 'desktop')
    }
  },
  price: {
    fontSize: vw(20),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(20, 'desktop')
    }
  },
  productInfo: {
    whiteSpace: 'pre-line',
    fontFamily: theme.fonts.din,
    fontWeight: 300,
    textAlign: 'center',
    fontSize: vw(16),
    margin: [vw(13), vw(24), 0],
    [theme.breakpoints.up('md')]: {
      fontSize: vw(16, 'desktop'),
      margin: [vw(13, 'desktop'), vw(24, 'desktop'), 0]
    }
  },
  savings: {
    textTransform: 'uppercase',
    fontFamily: theme.fonts.din,
    fontWeight: 300,
    fontSize: vw(12),
    marginLeft: vw(6),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(12, 'desktop'),
      marginLeft: vw(6, 'desktop')
    }
  },
  button: {
    marginTop: vw(24),
    [theme.breakpoints.up('md')]: {
      marginTop: vw(24, 'desktop')
    },
    '&.disable': {
      cursor: 'default',
      opacity: 0.8
    }
  }
}, { name: 'Slider' })

export default Slider
