import React, { useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import {
  isNewsletterBusy,
  isSubscribedToNewsletter,
  getNewsletterSubscribeError
} from '../redux/selectors'
import { subscribeRequest } from '../redux/actions'
import { vw } from '../helpers/vw'
import { span } from '../helpers/span'
import theme from '../style/theme'
import Button from './Button'
import RichText from './RichText'
import { expo } from 'style/eases'

function NewsletterSignup (props) {
  const {
    title,
    placeholder,
    copy,
    successMessage = 'Your Majesty thanks you',
    errorMessage = 'Please provide a valid email address',
    submitText,
    isSmall = false
  } = props
  const classes = useStyles({ small: isSmall })
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')

  const busy = useSelector(isNewsletterBusy)
  const subscribed = useSelector(isSubscribedToNewsletter)
  const error = useSelector(getNewsletterSubscribeError)

  const onChange = useCallback((e) => {
    setEmail(e.target.value)
  })

  const onSubmit = useCallback((e) => {
    dispatch(subscribeRequest({ email }))
    e.preventDefault()
  }, [email])

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      {title && <h3 className={classes.title}>{title}</h3>}
      {subscribed && <span className={classes.successMessage}>{successMessage}</span>}
      {!subscribed && (
        <div className={classes.inputWrapper}>
          <label htmlFor='email' className='srOnly'>{placeholder}</label>
          <input id='email' className={classes.input} required name='email' type='email' placeholder={placeholder} value={email} onChange={onChange} />
          <Button className={cn(classes.button, { busy })} transparent type='submit'>
            <span className={cn(classes.buttonText, { hide: busy })}>{submitText}</span>
            {busy && <div className={classes.spinner} />}
          </Button>
        </div>
      )}
      {copy && <RichText className={classes.copy} content={copy.text} />}
      {error && <div className={classes.errorMessage}>{errorMessage}</div>}
    </form>
  )
}

const useStyles = createUseStyles({
  form: {
    [theme.breakpoints.up('md')]: {
      width: ({ small }) => small ? vw(300, 'desktop') : 'auto',
      marginBottom: 0,
      paddingRight: span(1)
    }
  },
  title: {
    textTransform: 'uppercase',
    lineHeight: 1,
    fontSize: vw(45),
    marginBottom: vw(30),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      fontSize: vw(45, 'desktop'),
      marginBottom: vw(30, 'desktop')
    }
  },
  input: {
    border: [1, 'solid', theme.colors.beige],
    backgroundColor: 'transparent',
    lineHeight: 1.5,
    color: 'currentColor',
    fontFamily: theme.fonts.austin,
    width: '100%',
    padding: [vw(8), vw(14)],
    fontSize: ({ small }) => small ? vw(16) : vw(25),
    outline: 'none',
    [theme.breakpoints.up('md')]: {
      fontSize: ({ small }) => small ? vw(16, 'desktop') : vw(25, 'desktop'),
      padding: [vw(8, 'desktop'), vw(14, 'desktop')]
    },
    '&::placeholder': {
      color: 'currentColor'
    }
  },
  successMessage: {
    lineHeight: 1.5,
    color: 'currentColor',
    fontFamily: theme.fonts.austin,
    width: '100%',
    padding: [vw(8), vw(14)],
    fontSize: vw(25),
    textAlign: 'center',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      padding: [vw(8, 'desktop'), 0],
      fontSize: vw(25, 'desktop')
    }
  },
  copy: {
    display: 'block',
    marginTop: vw(18),
    padding: [0, span(2)],
    fontSize: vw(12),
    fontFamily: theme.fonts.din,
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      padding: 0,
      fontSize: vw(12, 'desktop'),
      marginTop: vw(14, 'desktop')
    },
    '& a': {
      color: 'currentColor',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  },
  inputWrapper: {
    display: 'flex'
  },
  button: {
    position: 'relative',
    borderRadius: 0,
    borderWidth: [1, 1, 1, 0],
    transition: `background 2s ${expo.out}, color 2s ${expo.out}`,
    '&:hover': {
      transitionDuration: '0.5s',
      transform: 'none',
      backgroundColor: theme.colors.white,
      color: theme.colors.primary
    },
    '&.busy': {
      '&:hover': {
        backgroundColor: 'transparent',
        color: 'currentColor'
      }
    }
  },
  buttonText: {
    'span&': {
      fontFamily: theme.fonts.austin,
      fontSize: vw(25),
      marginLeft: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: vw(25, 'desktop')
      }
    },
    '&.hide': {
      visibility: 'hidden'
    }
  },
  spinner: {
    borderRadius: '50%',
    width: '10em',
    height: '10em',
    borderTop: '1.1em solid rgba(255, 255, 255, 0.2)',
    borderRight: '1.1em solid rgba(255, 255, 255, 0.2)',
    borderBottom: '1.1em solid rgba(255, 255, 255, 0.2)',
    borderLeft: '1.1em solid #ffffff',
    transform: 'translateZ(0)',
    animation: '$spinner 1.1s infinite linear',
    fontSize: vw(2.5),
    position: 'absolute',
    top: '50%',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(2.5, 'desktop')
    }
  },
  '@keyframes spinner': {
    '0%': {
      transform: 'translate(0, -50%) rotate(0deg)'
    },
    '100%': {
      transform: 'translate(0, -50%) rotate(360deg)'
    }
  },
  errorMessage: {
    padding: [vw(8), vw(14)],
    [theme.breakpoints.up('md')]: {
      padding: [vw(8, 'desktop'), 0]
    }
  }
}, { name: 'NewsletterSignup' })

export default NewsletterSignup
