export const fontfaces = (publicPath = '/') => `
  @font-face {
      font-family: 'austin-web';
      src: url('${publicPath}fonts/austin-light-web.woff2') format('woff2'),
          url('${publicPath}fonts/austin-light-web.woff') format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'austin-web';
      src: url('${publicPath}fonts/austin-lightitalic-web.woff2') format('woff2'),
          url('${publicPath}fonts/austin-lightitalic-web.woff') format('woff');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
  }
  @font-face {
      font-family: 'austin-web';
      src: url('${publicPath}fonts/austin-roman-web.woff') format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'din-web';
      src: url('${publicPath}fonts/dinnextltpro-regular-web.woff2') format('woff2'),
          url('${publicPath}fonts/dinnextltpro-regular-web.woff') format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'din-web';
      src: url('${publicPath}fonts/dinnextltpro-medium-web.woff2') format('woff2'),
          url('${publicPath}fonts/dinnextltpro-medium-web.woff') format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
    font-family: 'din-web';
    src: url('${publicPath}fonts/dinnextltpro-light-web.woff2') format('woff2'),
        url('${publicPath}fonts/dinnextltpro-light-web.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
`

export const austin = 'austin-web, serif'
export const din = 'din-web, Arial, sans-serif'
