import qs from 'query-string'
import { getOptions, pathToAction, redirect, selectLocationState } from 'redux-first-router'
import { getCurrentRoutePath } from '../selectors'

export const ROUTE_CONTENT = 'ROUTE_CONTENT'
export const ROUTE_EMPTY = 'ROUTE_EMPTY'
export const BEGIN_ROUTE_EXIT_TRANSITION = 'BEGIN_ROUTE_EXIT_TRANSITION'
export const ROUTE_EXIT_TRANSITION_ENDED = 'ROUTE_EXIT_TRANSITION_ENDED'

export const beginRouteExitTransitionCreator = () => ({
  type: BEGIN_ROUTE_EXIT_TRANSITION
})

export const routeExitTransitionEndedCreator = () => ({
  type: ROUTE_EXIT_TRANSITION_ENDED
})

export const navigateCreator = (state, pathName) => {
  const { querySerializer } = getOptions()
  const { routesMap } = selectLocationState(state)
  return pathToAction(pathName, routesMap, querySerializer)
}

export const redirectCreator = (state, pathName) => {
  return redirect(navigateCreator(state, pathName))
}

export const queryStringChangeCreator = (state, query, meta = {}) => {
  const path = getCurrentRoutePath(state)
  const queryString = qs.stringify(query)
  const pathName = path + (queryString ? `?${queryString}` : '')
  const redirectAction = redirectCreator(state, pathName)
  return {
    ...redirectAction,
    type: ROUTE_EMPTY,
    meta: {
      ...redirectAction.meta,
      ...meta
    }
  }
}
