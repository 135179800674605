import React, { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme from 'style/theme'
import { resolveInternalLinkUrl } from 'helpers/resolveLink'
import Link from '../Link'
import ResponsiveImage from '../ResponsiveImage'
import { vw } from 'helpers/vw'
import { span } from 'helpers/span'
import { ReactComponent as Facebook } from 'images/facebook.svg'
import { ReactComponent as Twitter } from 'images/twitter.svg'
import hexToRgba from 'hex-to-rgba'
import share from '../../helpers/share'
import { format } from '../../helpers/date'

const NextArticle = ({ slice: { label, next }, page, nav }) => {
  const classes = useStyles()
  const date = format(page.post_date)
  const credits = `By ${page.author || 'Queen Garnet'}`

  const socialRef = useRef()

  useEffect(() => {
    const current = nav.current
    current.observeBrightSlice(socialRef)
    return () => { current.unobserveBrightSlice(socialRef) }
  }, [])

  const handleFacebookSharer = () => {
    share('facebook')
  }

  const handleTwitterSharer = () => {
    share('twitter')
  }

  return (
    <section>
      <div ref={socialRef} className={classes.social}>
        <div>{credits}</div>
        <div className={classes.socialLinks}>
          <span>{date}</span>
          <button onClick={handleFacebookSharer} className={classes.button} title='Facebook sharer'><Facebook /></button>
          <button onClick={handleTwitterSharer} className={classes.button} title='Twitter sharer'><Twitter /></button>
        </div>
      </div>
      {next && next.id &&
        <Link className={classes.container} to={resolveInternalLinkUrl(next)}>
          <span className={classes.header}>{label}</span>
          <span className={classes.title}>{next.title}</span>
          {next.hero && <ResponsiveImage className={classes.image} {...next.hero} aspect={360 / 460} />}
        </Link>}
    </section>
  )
}

const useStyles = createUseStyles({
  social: {
    fontFamily: theme.fonts.din,
    textTransform: 'uppercase',
    padding: [span(4), span(2)],
    fontSize: vw(14),
    color: hexToRgba(theme.colors.primary, 0.3),
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: [span(1.5), span(2)],
      fontSize: vw(14, 'desktop')
    }
  },
  socialLinks: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    },
    '& button': {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'inherit',
        marginLeft: vw(15, 'desktop')
      }
    }
  },
  container: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.beige,
    display: 'block',
    position: 'relative',
    textDecoration: 'none',
    padding: [vw(76), span(1), vw(35)],
    [theme.breakpoints.up('md')]: {
      padding: [vw(120, 'desktop'), span(1), vw(80, 'desktop')]
    }
  },
  text: {
    textAlign: 'center',
    lineHeight: 0.85,
    display: 'block',
    textTransform: 'uppercase',
    position: 'relative',
    zIndex: 1
  },
  header: {
    composes: '$text',
    fontSize: vw(32),
    marginBottom: vw(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(16, 'desktop'),
      fontSize: vw(57, 'desktop')
    }
  },
  title: {
    composes: '$text',
    fontSize: vw(50),
    [theme.breakpoints.up('md')]: {
      fontSize: vw(144, 'desktop')
    }
  },
  button: {

  },
  image: {
    width: `calc(100% - ${span(2)})`,
    margin: [vw(-24), 'auto', 0],
    [theme.breakpoints.up('md')]: {
      margin: [vw(-200, 'desktop'), 'auto', 0],
      width: vw(360, 'desktop')
    }
  }
}, { name: 'NextArticle' })

export default NextArticle
