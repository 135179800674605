import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import { vw } from '../../helpers/vw'
import theme from '../../style/theme'
import VideoPlayer from '../VideoPlayer'
import { span } from '../../helpers/span'

export default ({ slice: { video, image }, index, nav }) => {
  const classes = useStyles()
  const ref = useRef()

  useEffect(() => {
    const current = nav.current
    current.observeBrightSlice(ref)
    return () => { current.unobserveBrightSlice(ref) }
  }, [])

  return (
    <section className={cn(classes.section, { first: index < 2 })} ref={ref}>
      <div className={classes.container}>
        <VideoPlayer video={video} posterImage={image} />
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    padding: [0, 0, vw(30), 0],
    margin: [0, 'auto'],
    [theme.breakpoints.up('md')]: {
      padding: [0, 0, vw(100, 'desktop'), 0],
      margin: [0, 'auto']
    },
    '&.first': {
      paddingTop: vw(30),
      [theme.breakpoints.up('md')]: {
        paddingTop: vw(100, 'desktop')
      }
    }
  },
  container: {
    width: span(22),
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: span(20)
    }
  }
})
