export const CONTENT_TYPE = 'CONTENT'
export const ARTICLES_TYPE = 'ARTICLES'
export const GLOBAL_CONTENT_TYPE = 'GLOBAL_CONTENT'
export const REQUEST_PREVIEW_SESSION = 'REQUEST_PREVIEW_SESSION'

export const SET_ARTICLE_TAG_FILTER = 'SET_ARTICLE_TAG_FILTER'
export const LOAD_MORE_ARTICLES = 'LOAD_MORE_ARTICLES'

export const globalContentActionCreator = promise => ({
  type: GLOBAL_CONTENT_TYPE,
  payload: promise
})

export const contentActionCreator = promise => ({
  type: CONTENT_TYPE,
  meta: { scope: 'page' },
  payload: promise
})

export const requestPreviewSessionCreator = () => ({
  type: REQUEST_PREVIEW_SESSION
})

export const articlesActionCreator = (promise, sliceId, more) => ({
  type: ARTICLES_TYPE,
  meta: { sliceId, scope: sliceId, paged: true, more },
  payload: promise
})

export const setArticleTagsFilterCreator = (tag) => ({
  type: SET_ARTICLE_TAG_FILTER,
  payload: { tag }
})

export const loadMoreArticlesCreator = () => ({
  type: LOAD_MORE_ARTICLES
})
