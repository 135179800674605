import React, { useMemo, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import get from 'lodash/get'
import BlockContent from '@sanity/block-content-to-react'
import map from 'lodash/map'
import merge from 'lodash/merge'
import Link from './Link'
import { resolveLink } from '../helpers/resolveLink'
import theme from '../style/theme'
import Reviews from './Reviews'

const useStyles = createUseStyles({
  container: {
    '& *:last-child': {
      marginBottom: 0
    },
    '& a': {
      color: 'currentColor',
      fontStyle: 'italic',
      textDecoration: 'none',
      display: 'inline-block',
      position: 'relative',
      '&:after': {
        position: 'absolute',
        content: '""',
        height: 1,
        left: 1,
        bottom: 0,
        width: '100%',
        backgroundColor: 'currentColor'
      }
    }
  },
  largeBodyFont: {
    fontSize: '1.4em',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.85em'
    }
  }
}, { name: 'RichText' })

const serializers = {
  marks: {
    link: ({ mark, children }) => (
      <Link to={mark.href} target={mark.open_in_new_window ? '_blank' : undefined}>{children}</Link>
    ),
    internal_link: ({ mark, children }) => (
      <Link link={resolveLink(mark)}>{children}</Link>
    ),
    largeBodyFont: ({ mark, children }) => {
      const classes = useStyles()
      return <span className={classes.largeBodyFont}>{children}</span>
    }
  },
  types: {
    tiles: props => {
      const tiles = get(props, ['node', 'tiles'])
      if (!tiles) return null
      return (
        <ul className='tiles'>
          {tiles.map(({ title, copy }, i) => (
            <li key={i}>
              <span className='title'>{title}</span>
              <span className='copy'>{copy}</span>
            </li>
          ))}
        </ul>
      )
    },
    reviews: props => {
      const reviews = get(props, ['node', 'reviews'])
      return <Reviews reviews={reviews} />
    }
  }
}

const RichText = forwardRef(({ className, content, tag = 'div', serializers: extraSerializers, children }, ref) => {
  const classes = useStyles()
  const Component = tag
  const allSerializers = useMemo(() => {
    if (extraSerializers) {
      return merge({}, serializers, extraSerializers)
    }
    return serializers
  }, [extraSerializers])
  const blocks = useMemo(() => map(content, block => ({
    _type: block.type, // BlockContent expects `_type` prop, not `type`.
    ...block
  })))
  return (
    <Component className={cn(className, classes.container)} ref={ref}>
      <BlockContent blocks={blocks} serializers={allSerializers} />
      {children}
    </Component>
  )
})

export default RichText
