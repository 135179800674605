import get from 'lodash/get'
import map from 'lodash/map'
import omit from 'lodash/omit'
import find from 'lodash/find'
import makeFetchReducer from './makeFetchReducer'
import {
  CART_TYPE,
  DECREMENT_CART_LINE_ITEM_QUANTITY,
  INCREMENT_CART_LINE_ITEM_QUANTITY,
  REMOVE_FROM_CART,
  TOGGLE_CART_DIALOG,
  OPEN_CART_DIALOG,
  CLEAR_CART,
  ROUTE_CONTENT
} from '../actions'

function optimisticallyUpdateQuantity (state, variantId, { numberToAdd, quantity }) {
  const lineItems = get(state, ['content', 'lineItems'])
  if (!lineItems || !find(lineItems, ({ variant }) => variant.id === variantId)) {
    return state
  }
  return {
    ...state,
    content: {
      ...omit(state.content, [
        'lineItems' // ,
        // // Leave price calculations to the server - delete the incorrect totals for now
        // 'lineItemsSubtotalPrice',
        // 'subtotalPrice',
        // 'totalPrice'
      ]),
      lineItems: map(lineItems, lineItem => {
        if (lineItem.variant.id === variantId) {
          return {
            ...lineItem,
            quantity: Math.max(0, numberToAdd ? lineItem.quantity + numberToAdd : quantity)
          }
        }
        return lineItem
      })
    }
  }
}

const fetchReducer = makeFetchReducer(CART_TYPE)
const cart = (state, action) => {
  state = fetchReducer(state, action)
  switch (action.type) {
    case INCREMENT_CART_LINE_ITEM_QUANTITY:
      return optimisticallyUpdateQuantity(state, action.payload, { numberToAdd: 1 })
    case DECREMENT_CART_LINE_ITEM_QUANTITY:
      return optimisticallyUpdateQuantity(state, action.payload, { numberToAdd: -1 })
    case REMOVE_FROM_CART:
      return optimisticallyUpdateQuantity(state, action.payload, { quantity: 0 })
    case TOGGLE_CART_DIALOG:
      return {
        ...state,
        open: !state.open
      }
    case OPEN_CART_DIALOG:
      return {
        ...state,
        open: true
      }
    case CLEAR_CART:
      return {
        ...state,
        content: null
      }
    case ROUTE_CONTENT:
      return {
        ...state,
        open: false
      }
    default:
      return state
  }
}

export default cart
