import React, { useRef, useEffect, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { stockistPlaceChanged } from '../../redux/actions'
import { isStockistDialogOpen } from '../../redux/selectors'
import theme from 'style/theme'
import { vw } from 'helpers/vw'
import { ReactComponent as SearchIcon } from 'images/search.svg'
import useGoogleMapsApi from '../useGoogleMapsApi'

const locationInputPlaceholder = 'Please enter your suburb'
const placesAutoCompleteOptions = {
  // bounds: defaultBounds,
  componentRestrictions: { country: 'au' },
  // fields: ['address_components', 'geometry', 'icon', 'name'],
  // origin: center,
  // strictBounds: false,
  types: ['(cities)']
}

const LocationInput = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const inputRef = useRef()
  const autocompleteRef = useRef()
  const googleMapsApi = useGoogleMapsApi()
  const isDialogOpen = useSelector(isStockistDialogOpen)

  useEffect(() => {
    if (inputRef.current && inputRef.current.value && !isDialogOpen) {
      setTimeout(() => {
        autocompleteRef.current.set('place', null)
        inputRef.current.value = ''
      }, 500)
    }
  }, [isDialogOpen])

  useEffect(() => {
    if (googleMapsApi) {
      autocompleteRef.current = new googleMapsApi.places.Autocomplete(inputRef.current, placesAutoCompleteOptions)
      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current.getPlace()
        dispatch(stockistPlaceChanged(place))
      })
    }
  }, [googleMapsApi])

  const handleSubmit = (e) => {
    e.preventDefault()
    return false
  }

  return (
    <form className={classes.inputWrapper} autoComplete='off' onSubmit={handleSubmit}>
      <label htmlFor='location' className='srOnly'>{locationInputPlaceholder}</label>
      <SearchIcon className={classes.searchIcon} />
      <input
        name='location'
        aria-label='Search stockists by suburb or location'
        ref={inputRef}
        className={classes.input}
        placeholder={locationInputPlaceholder}
        autoComplete='off'
      />
    </form>
  )
})

const useStyles = createUseStyles({
  '@global': {
    '.pac-container': {
      backgroundColor: theme.colors.beige,
      boxShadow: 'none !important',
      border: [1, 'solid', theme.colors.primary],
      borderTop: 'none',
      borderRadius: 0,
      fontSize: 16,
      '&::after': {
        backgroundImage: 'none !important',
        height: '0 !important'
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 18
      }
    },
    '.pac-item': {
      padding: [8, 16],
      fontSize: 16,
      [theme.breakpoints.up('md')]: {
        fontSize: 18
      }
    },
    '.pac-item-query': {
      marginRight: 8,
      fontSize: 16,
      [theme.breakpoints.up('md')]: {
        fontSize: 18
      }
    }
  },
  inputWrapper: {
    display: 'flex',
    position: 'relative',
    marginBottom: vw(16),
    [theme.breakpoints.up('md')]: {
      marginBottom: vw(16, 'desktop')
    }
  },
  searchIcon: {
    height: vw(14),
    width: vw(14),
    position: 'absolute',
    left: vw(16),
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.up('md')]: {
      left: vw(16, 'desktop'),
      height: vw(14, 'desktop'),
      width: vw(14, 'desktop')
    }
  },
  input: {
    border: [1, 'solid', theme.colors.primary],
    backgroundColor: 'transparent',
    lineHeight: 1.5,
    color: 'currentColor',
    fontFamily: theme.fonts.austin,
    width: '100%',
    padding: [vw(8), vw(14), vw(8), vw(48)],
    fontSize: vw(20),
    outline: 'none',
    [theme.breakpoints.up('md')]: {
      fontSize: vw(25, 'desktop'),
      padding: [vw(8, 'desktop'), vw(14, 'desktop'), vw(8, 'desktop'), vw(48, 'desktop')]
    },
    '&::placeholder': {
      color: 'currentColor'
    }
  }
}, { name: 'LocationInput' })

export default LocationInput
